import React, { useEffect, useState } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia, Checkbox,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Input, InputAdornment, Pagination,
    Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Grid,
    Toolbar, IconButton, Switch, Skeleton, Collapse } from '@mui/material';

import DashboardElement from 'pageElements/DashboardElement';
import DashboardLayout from 'pageElements/DashboardLayout';
import DashboardBody from 'pageElements/DashboardBody';
import DashboardMenuBar from 'pageElements/DashboardMenuBar';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const ClickableStyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
        backgroundColor: theme.palette.primary.light,
    },
    "&:active": {
        backgroundColor: theme.palette.primary.dark,
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
}));

const MenuBarButton = styled(Button)(({ theme }) => ({
    color: "white",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#6c89b8"
    }
}));


export default function CoachingHoursReport() {
    const [reportData, setReportData] = useState([]);
    const [numberOfClients, setNumberOfClients] = useState(0);
    const [currentClientNumber, setCurrentClientNumber] = useState(0);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedClientContractHours, setSelectedClientContractHours] = useState(null);
    const [selectedClientLaborCategories, setSelectedClientLaborCategories] = useState(null);
    const [selectedClientParticipantsData, setSelectedClientParticipantsData] = useState(null);

    const [participantRowsPerPage, setParticipantRowsPerPage] = useState(8);
    const [participantPage, setParticipantPage] = useState(0);

    // laborCategoryName
    function lcatForId(lcatId) {
        for(let i = 0; i < selectedClientLaborCategories.length; i++) {
            if(selectedClientLaborCategories[i].laborCategoryId == lcatId)
                return selectedClientLaborCategories[i].laborCategoryName;
        }
        return("ERROR");
    }

    function selectClient(clientIndex) {
        setSelectedClient(reportData[clientIndex].client);
        setSelectedClientLaborCategories(reportData[clientIndex].laborCategories);
        setSelectedClientParticipantsData(reportData[clientIndex].participantData);
        setSelectedClientContractHours(reportData[clientIndex].contractHours);
        setCurrentClientNumber(clientIndex);
    }
    
    useEffect(() => {
        const rq = {
            generateLCATData: true,
            generateParticipantData: true,
            generateForAllClients: true,
            clientsList: []
        };
        fetch("/api/v1/account/analytics/org-coaching-hours-progress-report", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(rq)
        }).then((res) => {
            return res.json();
        }).then((CoachingHoursProgressReport) => {
            setReportData(CoachingHoursProgressReport.clientCoachingHoursData);
            setNumberOfClients(CoachingHoursProgressReport.clientCoachingHoursData.length);
            setSelectedClient(CoachingHoursProgressReport.clientCoachingHoursData[0].client);
            setSelectedClientContractHours(CoachingHoursProgressReport.clientCoachingHoursData[0].contractHours);
            setSelectedClientLaborCategories(CoachingHoursProgressReport.clientCoachingHoursData[0].laborCategories);
            setSelectedClientParticipantsData(CoachingHoursProgressReport.clientCoachingHoursData[0].participantData);
        });
    }, []);

    if(reportData.length > 0) {
        return (
            <DashboardLayout>
                <DashboardMenuBar>
                    <MenuBarButton onClick={() => {window.location.href = "/"}}>Organization Dashboard</MenuBarButton>
                    <MenuBarButton onClick={() => window.location.href="/analytics"} >Organization Analytics</MenuBarButton>
                </DashboardMenuBar>
                <DashboardBody>
                    { reportData.length == 0 && <CircularProgress /> }
                    { reportData.length > 0 && 
                        <React.Fragment>
                            <DashboardElement elevation={1}>
                                <Typography>Organization</Typography>
                            </DashboardElement>
                            <DashboardElement elevation={1}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography>Client: </Typography>
                                    <Select value={currentClientNumber} onChange={(e) => selectClient(e.target.value)}>
                                        { reportData.map((clientData, index) => {
                                            return <MenuItem value={index}>{clientData.client.clientName}</MenuItem>
                                        })}
                                    </Select>
                                </Stack>
                                <Box sx={{ width: "100%" }}>
                                    <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Contract Hours</Typography>
                                </Box>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 600 }}>
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell align="left">Labor Category</StyledTableCell>
                                                <StyledTableCell align="right">Allocated Hours</StyledTableCell>
                                                <StyledTableCell align="right">Unallocated Hours</StyledTableCell>
                                                <StyledTableCell align="right">Scheduled Hours</StyledTableCell>
                                                <StyledTableCell align="right">Used Hours</StyledTableCell>
                                                <StyledTableCell align="right">Unused Hours</StyledTableCell>
                                                <StyledTableCell align="right">Total Hours</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            { selectedClientContractHours.map((lcatData, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell align="left">{lcatForId(lcatData.lcatId)}</StyledTableCell>
                                                        <StyledTableCell align="right">{lcatData.hoursTotal - lcatData.hoursAvailable}</StyledTableCell>
                                                        <StyledTableCell align="right">{lcatData.hoursAvailable}</StyledTableCell>
                                                        <StyledTableCell align="right">{lcatData.hoursPending}</StyledTableCell>
                                                        <StyledTableCell align="right">{lcatData.hoursProgress}</StyledTableCell>
                                                        <StyledTableCell align="right">{(lcatData.hoursTotal - lcatData.hoursAvailable) - lcatData.hoursProgress - lcatData.hoursPending}</StyledTableCell>
                                                        <StyledTableCell align="right">{lcatData.hoursTotal}</StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box sx={{ width: "100%" }}>
                                    <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Participants</Typography>
                                </Box>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 600 }}>
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell align="left">Participant</StyledTableCell>
                                                <StyledTableCell align="right">Availabile Hours</StyledTableCell>
                                                <StyledTableCell align="right">Pending Hours</StyledTableCell>
                                                <StyledTableCell align="right">Total Hours</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            { selectedClientParticipantsData.slice(participantPage * participantRowsPerPage, participantPage * participantRowsPerPage + participantRowsPerPage).map((pData, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell align="left">{pData.firstName} {pData.lastName}</StyledTableCell>
                                                        <StyledTableCell align="right">{(pData.coachingHours.minutesRemaining / 60.0).toFixed(2)}</StyledTableCell>
                                                        <StyledTableCell align="right">{(pData.coachingHours.minutesPending / 60.0).toFixed(2)}</StyledTableCell>
                                                        <StyledTableCell align="right">{(pData.coachingHours.minutesTotal / 60.0).toFixed(2)}</StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination 
                                    participantRowsPerPage={[8]}
                                    component="div"
                                    count={selectedClientParticipantsData.length}
                                    rowsPerPage={participantRowsPerPage}
                                    page={participantPage}
                                    onPageChange={(event, pageNum) => setParticipantPage(pageNum)} />
                            </DashboardElement> 
                        </React.Fragment> }
                </DashboardBody>
            </DashboardLayout>
        );
    } else {
        return (
            <CircularProgress />
        );
    }
}