import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider, Checkbox,
    FormGroup, TableContainer, Table, TableRow, TableBody, TableHead, IconButton, ListItemText,
    OutlinedInput, InputAdornment, Input, Collapse } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Unstable_NumberInput as NumberInput } from '@mui/base';
import { Textarea } from '@mui/joy';
import { SessionContext } from 'Contexts';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

import DashboardElement from 'pageElements/DashboardElement';
import DashboardLayout from 'pageElements/DashboardLayout';
import DashboardBody from 'pageElements/DashboardBody';
import DashboardMenuBar from 'pageElements/DashboardMenuBar';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, list, theme) {
    return {
      fontWeight:
        list.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
}


export default function ContractSetup() {
    let { clientId } = useParams();
    let [participantGradeGroups, setParticipantGradeGroups] = useState(null);
    let [participantGradeGroupSelections, setParticipantGradeGroupSelections] = useState([]);
    let [coachCertLevels, setCoachCertLevels] = useState(null);
    let [laborCategories, setLaborCategories] = useState([]);
    let [newLCATName, setNewLCATName] = useState("");
    let [newLCATBillingRate, setNewLCATBillingRate] = useState("0.00");
    let [newLCATCoachLevels, setNewLCATCoachLevels] = useState([]);

    let [coachMatchCount, setCoachMatchCount] = useState(5);
    let [startDate, setStartDate] = useState(null);
    let [endDate, setEndDate] = useState(null);
    let [participantChoosesCoach, setParticipantChoosesCoach] = useState(true);
    let [participantChoosesGender, setParticipantChoosesGender] = useState(true);
    let [participantSelectsCoachGenderPreference, setParticipantSelectsCoachGenderPreference] = useState(true);
    let [participantSelectsCoachEthnicityPreference, setParticipantSelectsCoachEthnicityPreference] = useState(true);
    let [participantChoosesEthnicity, setParticipantChoosesEthnicity] = useState(true);
    let [contractDescription, setContractDescription] = useState("");
    let [contractCreationRq] = useState({
        status: "NOT_STARTED", 
        startDate: null, 
        endDate: null, 
        participantChoosesCoach: true,
        coachMatchCount: 5 });

    useEffect(() => {
        fetch("/api/v1/account/participant-grade-groups").then((res) => {
            return res.json();
        }).then((res) => {
            let initialParticipantGradeGroupsSelections = [];
            for(let i = 0; i < res.gradeGroups.length; i++) {
                initialParticipantGradeGroupsSelections[i] = false;
            }
            setParticipantGradeGroupSelections(initialParticipantGradeGroupsSelections);
            setParticipantGradeGroups(res.gradeGroups);
        });
        fetch("/api/v1/account/coach-certification-levels").then((res) => {
            return res.json();
        }).then((coachCertificationLevelsResponse) => {
            setCoachCertLevels(coachCertificationLevelsResponse.certificationLevels);
        });
    }, []);

    function createParticipantRegistrationConfig() {
        let registrationConfigurationConfig = {
            gradeGroups: [],
            participantSelectsGender: participantChoosesGender,
            participantChoosesEthnicity: participantChoosesGender,
            userHasCoachMatchingGenderPreference: participantSelectsCoachGenderPreference,
            userHasCoachMatchingEthnicityPreference: participantSelectsCoachEthnicityPreference
        };
        for(let i = 0; i < participantGradeGroupSelections.length; i++) { 
            if(participantGradeGroupSelections[i] == true) {
                registrationConfigurationConfig.gradeGroups.push(participantGradeGroups[i]);
            }
        }
        return registrationConfigurationConfig;
    }

    function createLaborCategoriesRequest() {
        let setLaborCategoriesRq = {laborCategories: [], laborCategoryToCoachLevelRelations: []};
        for(let lcatIndex = 0; lcatIndex < laborCategories.length; lcatIndex++) {
            let billingRate = parseInt(laborCategories[lcatIndex].billingRate.replace(".", ""));
            let laborCategory = {
                laborCategoryName: laborCategories[lcatIndex].name,
                billingRate: billingRate
            };
            setLaborCategoriesRq.laborCategories.push(laborCategory);

            let lCATCoachRelation = {
                laborCategoryName: laborCategories[lcatIndex].name,
                coachCertificationLevelIds: []
            };
            let lcatCoachRelations = laborCategories[lcatIndex].coachLevels.split(", ");
            lcatCoachRelations.forEach(coachLevel => {
                for(let certificationIndex = 0; certificationIndex < coachCertLevels.length; certificationIndex++) {
                    if(coachCertLevels[certificationIndex].name == coachLevel)
                        lCATCoachRelation.coachCertificationLevelIds.push(coachCertLevels[certificationIndex].certificationId);
                }
            });
            setLaborCategoriesRq.laborCategoryToCoachLevelRelations.push(lCATCoachRelation);
        }
        return setLaborCategoriesRq;
    }
    
    let handleCreateContract = () => {
        contractCreationRq.startDate = startDate;
        contractCreationRq.endDate = endDate;
        contractCreationRq.participantChoosesCoach = (participantChoosesCoach === true);
        contractCreationRq.contractInfo = contractDescription;
        if(participantChoosesCoach)
            contractCreationRq.coachMatchCount = parseInt(coachMatchCount);
        else
            contractCreationRq.coachMatchCount = 0;
        fetch("/api/v1/account/contract/" + clientId, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(contractCreationRq)
        }).then((res) => {
            if(res.status == 200) {
                let participantRegistrationConfig = createParticipantRegistrationConfig();
                let postRegistrationConfigPromise = fetch("/api/v1/account/participant-registration-configuration/" + clientId, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(participantRegistrationConfig)
                });

                let setLaborCategoriesRq = createLaborCategoriesRequest();
                let laborCategoriesPromise = fetch("/api/v1/account/set-labor-categories/" + clientId, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(setLaborCategoriesRq)
                }) 
                
                Promise.all([postRegistrationConfigPromise, laborCategoriesPromise]).then(() => {
                    window.location.href = "/client/" + clientId;
                });
            }
        });
    }

    function deleteLaborCategory(lcatIndex) {
        let lcats = [...laborCategories];
        lcats.splice(lcatIndex, 1);
        setLaborCategories(lcats);
    }

    function createLaborCategory() {
        newLCATName = document.getElementById("newLCATNameEntry").value;
        newLCATBillingRate = document.getElementById("newLCATBillingRateEntry").value;
        if(newLCATName == "" || newLCATBillingRate == 0.00 || newLCATCoachLevels.length == 0)
            return;
        let newLCAT = { name: newLCATName, billingRate: newLCATBillingRate, coachLevels: newLCATCoachLevels.join(", ") };
        let updatedLaborCategories = [...laborCategories];
        updatedLaborCategories.push(newLCAT);
        setLaborCategories(updatedLaborCategories);
        setNewLCATName("");
        setNewLCATBillingRate("0.00");
        setNewLCATCoachLevels([]);
    }

    function addCoachLevelToList(coachLevels) {
        setNewLCATCoachLevels(typeof coachLevels === "string" ? coachLevels.split(",") : coachLevels);
        setNewLCATName(document.getElementById("newLCATNameEntry").value);
        setNewLCATBillingRate(document.getElementById("newLCATBillingRateEntry").value);
    }

    function LaborCategories({ certificationLevels, lcats }) {
        const theme = useTheme();

        // LCAT: name, billing rate
        // LCAT-Coach Relation: labor category id, coach certification level id
        // Certification: name, shortName, rankValue
        if(certificationLevels == null) {
            return <CircularProgress />;
        } else {
            return(
                <React.Fragment>
                    <Box sx={{ width: "100%", maxHeight: "400px", display: "flex", flexDirection: "column", alignItems: "flex-start", overflow: "hidden" }}>
                        <TableContainer sx={{ overflowx: "initial" }}>
                            <Table sx={{ minWidth: "500px" }} stickyHeader size="small">
                                <TableHead>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>Labor Category</StyledTableCell>
                                    <StyledTableCell>Billing Rate (USD)</StyledTableCell>
                                    <StyledTableCell>Required Coach Certifications</StyledTableCell>
                                </TableHead>
                                <TableBody>
                                    {lcats.map((lcatData, index) => {
                                        return(
                                            <StyledTableRow key={index}>
                                                <StyledTableCell><IconButton size="small" onClick={(event) => {deleteLaborCategory(index)}}><ClearIcon /></IconButton></StyledTableCell>
                                                <StyledTableCell>{lcatData.name}</StyledTableCell>
                                                <StyledTableCell>${lcatData.billingRate}</StyledTableCell>
                                                <StyledTableCell>{lcatData.coachLevels}</StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                    <StyledTableRow key="creationRow">
                                        <StyledTableCell><IconButton size="small" onClick={(event) => {createLaborCategory()}}><CheckIcon /></IconButton></StyledTableCell>
                                        <StyledTableCell><TextField id="newLCATNameEntry" defaultValue={newLCATName} variant="standard" onChange={(event) => {newLCATName = event.target.value}}/></StyledTableCell>
                                        <StyledTableCell><Input id="newLCATBillingRateEntry" defaultValue={newLCATBillingRate} variant="standard" onChange={(event) => {newLCATBillingRate = event.target.value}} startAdornment={<InputAdornment position="start">$</InputAdornment>} /></StyledTableCell>
                                        <StyledTableCell>
                                            <FormControl sx={{ width: 300 }}>
                                                <Select 
                                                  multiple 
                                                  value={newLCATCoachLevels} 
                                                  onChange={(event) => {addCoachLevelToList(event.target.value)}}
                                                  input={<OutlinedInput label="Name" />}
                                                  menuProps={MenuProps}>
                                                    {coachCertLevels.map((certification) => {
                                                        return(
                                                            <MenuItem key={certification.name} value={certification.name} style={getStyles(certification.name, newLCATCoachLevels, theme)}>
                                                                {certification.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </React.Fragment>
            );
        }
    }

    function ParticipantGradeGroupSelector({ gradeGroups, selection }) {
        function changeCheckState(groupIndex) {
            let updatedParticipantGradeGroupSelections = [...participantGradeGroupSelections];
            updatedParticipantGradeGroupSelections[groupIndex] = !updatedParticipantGradeGroupSelections[groupIndex];
            setParticipantGradeGroupSelections(updatedParticipantGradeGroupSelections);
        }

        if(gradeGroups == null) {
            return(<CircularProgress />);
        } else {
            return (
                <React.Fragment>
                    <Typography>Which (if any) ranks or grades will your participants represent?</Typography>
                    <FormGroup row>
                        { gradeGroups.map((group, groupIndex) => {
                            return(<FormControlLabel control={<Checkbox checked={selection[groupIndex]} onChange={() => {changeCheckState(groupIndex)}}/>} label={group.groupName} />);
                        })}
                    </FormGroup>
                </React.Fragment>
            );
        }
    }

    function ContractDetails() {
        return (
            <React.Fragment>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <Stack direction="column" fullwidth justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={0.25}>
                        <Typography>What day would you like your coaching program to begin?</Typography>
                        <DatePicker value={startDate} onChange={(value) => {setStartDate(value)}} />
                    </Stack>
                    <Stack direction="column" fullwidth justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={0.25}>
                        <Typography>What day would you like your coaching program to end?</Typography>
                        <DatePicker value={endDate} onChange={(value) => {setEndDate(value)}} />
                    </Stack>
                    <Stack direction="column" fullwidth justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={0.25}>
                        <Typography>Should participants be able to select their own coach?</Typography>
                        <FormControl>
                            <RadioGroup value={participantChoosesCoach} onChange={(event) => setParticipantChoosesCoach(event.target.value)}>
                                <FormControlLabel value={true} control={<Radio />} label="Participants select their coach" />
                                <FormControlLabel value={false} control={<Radio />} label="Coaches are selected by EST2AR" />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                    <Stack direction="column" fullwidth justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={0.25}>
                        <Typography>Should participants have the option to select their gender at registration?</Typography>
                        <FormControl>
                            <RadioGroup value={participantChoosesGender} onChange={(event) => setParticipantChoosesGender(event.target.value)}>
                                <FormControlLabel value={true} control={<Radio />} label="Participants may opt to select their gender" />
                                <FormControlLabel value={false} control={<Radio />} label="Participants may not select their gender" />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                    <Stack direction="column" fullwidth justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={0.25}>
                        <Typography>Should participants have the option to select their ethnicity at registration?</Typography>
                        <FormControl>
                            <RadioGroup value={participantChoosesEthnicity} onChange={(event) => setParticipantChoosesEthnicity(event.target.value)}>
                                <FormControlLabel value={true} control={<Radio />} label="Participants may opt to select their ethnicity" />
                                <FormControlLabel value={false} control={<Radio />} label="Participants may not select their ethnicity" />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                    <Stack direction="column" fullwidth justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={0.25}>
                        <Typography>Should participants have a preference of their coach matches' ethnicities?</Typography>
                        <FormControl>
                            <RadioGroup value={participantSelectsCoachEthnicityPreference} onChange={(event) => setParticipantSelectsCoachEthnicityPreference(event.target.value)}>
                                <FormControlLabel value={true} control={<Radio />} label="Participants may select a preference" />
                                <FormControlLabel value={false} control={<Radio />} label="Participants may not select a preference" />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                    <Stack direction="column" fullwidth justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={0.25}>
                        <Typography>Should participants have a preference of their coach matches' genders?</Typography>
                        <FormControl>
                            <RadioGroup value={participantSelectsCoachGenderPreference} onChange={(event) => setParticipantSelectsCoachGenderPreference(event.target.value)}>
                                <FormControlLabel value={true} control={<Radio />} label="Participants may select a preference" />
                                <FormControlLabel value={false} control={<Radio />} label="Participants may not select a preference" />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                    <Stack direction="column" fullwidth justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={0.25}>
                        <Typography>How many coaches should each participant choose from?</Typography>
                        { /* participantChoosesCoach is "false" string if false and true boolean if true. No time to debug now, beware */ }
                        <TextField disabled={participantChoosesCoach == "false"} type="number" onChange={(event) => {setCoachMatchCount(event.target.value)}} value={coachMatchCount} />
                    </Stack>
                </LocalizationProvider>
            </React.Fragment>
        )
    }

    return (
        <div className="PageBody">
            <Stack direction="column" sx={{ width: "97.5%" }} justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                <Typography variant="h3" component="h1">Coaching Program Setup</Typography>
                <ContractDetails />
                <LaborCategories certificationLevels={coachCertLevels} lcats={laborCategories} />
                <ParticipantGradeGroupSelector gradeGroups={participantGradeGroups} selection={participantGradeGroupSelections} />
                <Box sx={{ width: "100%" }}>
                    <TextField fullWidth multiline label="Contract details" rows={5} value={contractDescription} onChange={(event) => { setContractDescription(event.target.value)}} />
                </Box>
                <Box sx={{ maxWidth: "50%", justifyContent: "center"}}>
                    <Button component="label" variant="contained" maxWidth="200px" onClick={ handleCreateContract }>Submit</Button>
                </Box>
                <Box sx={{height: "50px"}} />
            </Stack>
        </div>
    );
}