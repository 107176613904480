import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SessionContext } from 'Contexts';
import { Textarea } from '@mui/joy';
import AspectRatio from '@mui/joy/AspectRatio';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia, Checkbox,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Input, InputAdornment, Pagination,
    Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, TableFooter,
    Toolbar, IconButton, Switch } from '@mui/material';
import { DeviceLabels, Meeting, useMeetingManager, VideoTileGrid, LocalVideo, useLocalVideo,
    RosterAttendee, AudioInputControl, ControlBar, ControlBarButton,
    VideoInputBackgroundBlurControl, BackgroundBlurProvider, ContentShareControl } from 'amazon-chime-sdk-component-library-react';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';

dayjs.extend(utc);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const ClickableStyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
        backgroundColor: theme.palette.primary.light,
    },
    "&:active": {
        backgroundColor: theme.palette.primary.dark,
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ExitMeetingButtonProps = {
    icon: <ExitToAppIcon />,
    label: "Exit",
    onClick: () => {window.location.href = "/"}
}

export default function CoachingSession() {
    const sessionState = useContext(SessionContext);
    let { meetingId } = useParams();
    let [meetingData, setMeetingData] = useState(null);
    let [meetingView, setMeetingView] = useState(null);
    let [meetingStarted, setMeetingStarted] = useState(false);
    let [newNoteText, setNewNoteText] = useState("");
    let [newNoteParticipantViewable, setNewNoteParticipantViewable] = useState(false);
    let [coachNotes, setCoachNotes] = useState([]);
    const meetingManager = useMeetingManager();
    const { toggleVideo } = useLocalVideo();


    useEffect(() => {
        fetch("/api/v1/account/joinCoachingSession/" + meetingId).then((res) => {
            return res.json();
        }).then((meetingDataResponse) => {
            setMeetingData(meetingDataResponse);
            const meetingSessionConfig = new MeetingSessionConfiguration(meetingDataResponse.meeting, meetingDataResponse.attendee);
            const meetingSessionOptions = { deviceLabels: DeviceLabels.AudioAndVideo };
            meetingManager.join(meetingSessionConfig, meetingSessionOptions);
            startCoachingSessionTimeSheetPoll();
            return meetingDataResponse;
        }).then((meetingDataResponse) => {
            setMeetingView(
                <AspectRatio variant="soft" ratio="4/3" sx={{ height: "100%", width: "100%", borderRadius: 'md' }}>
                    <Box sx={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center"}}>
                        <Stack spacing={2} direction="column" justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100%" }}>
                            <Box sx={{ minHeight: "400px", height: "100%", width: "100%"}}>
                                <VideoTileGrid layout="standard"/>
                            </Box>
                            <BackgroundBlurProvider>
                                <ControlBar showLabels layout="undocked-horizontal">
                                    <AudioInputControl />
                                    <VideoInputBackgroundBlurControl />
                                    <ContentShareControl />
                                    <ControlBarButton {...ExitMeetingButtonProps} />
                                </ControlBar>
                            </BackgroundBlurProvider>
                            <RosterAttendee attendeeId={meetingDataResponse.attendee.attendeeId} />
                        </Stack>
                    </Box>
                </AspectRatio>
            );
            setMeetingStarted(true);
            meetingManager.start();

            fetch("/api/v1/account/coach-notes/" + meetingDataResponse.participantId).then((res) => {
                return res.json();
            }).then((coachNotesResponse) => {
                setCoachNotes(coachNotesResponse.notes);
            })
        });
    }, []);

    function startCoachingSessionTimeSheetPoll() {
        setInterval(() => {
            fetch("/api/v1/account/coaching-session-poll/" + meetingId, {
                method: "POST"
            });
        }, 30000);
    }

    function postNewNote() {
        let rq = {
            noteText: newNoteText,
            coachId: sessionState.accountId,
            participantId: meetingData.participantId,
            entryDate: dayjs().format(),
            visibleToParticipant: newNoteParticipantViewable
        };

        fetch("/api/v1/account/coach-note", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(rq)
        }).then((res) => {
            fetch("/api/v1/account/coach-notes/" + meetingData.participantId).then((res) => {
                return res.json();
            }).then((coachNotesResponse) => {
                setCoachNotes(coachNotesResponse.notes);
            })
        })
    }

    if(meetingStarted) {
        return (
            <Stack direction="row" spacing={ 1.0 } 
              sx={{ mt: "1%", mb: "0%", ml: "1%", mr: "1%", width: "97%", maxHeight: "85vh", height: "85vh" }} >
                <Box sx={{ width: "62.5%" }} >
                    { meetingView }
                </Box>
                <Box sx={{ width: "37.5%" }} >
                    <TableContainer sx={{ maxHeight: "75%" }}>
                        <Table stickyHeader>
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell align="center" colSpan={7}><Box sx={{ fontWeight: 'bold' }}>Notes</Box></StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell>Shared</StyledTableCell>
                                    <StyledTableCell>Note</StyledTableCell>
                                    <StyledTableCell>Created</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                { coachNotes.map((note, index) => {
                                    return (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell>{note.visibleToParticipant ? "Yes" : "No"}</StyledTableCell>
                                            <StyledTableCell>{note.noteText}</StyledTableCell>
                                            <StyledTableCell>{dayjs(note.entryDate).local().format()}</StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TableContainer sx={{ maxHeight: "25%" }}>
                        <Table>
                            <TableFooter>
                                <StyledTableRow key="new">
                                    <StyledTableCell><Checkbox checked={newNoteParticipantViewable} onClick={(event) => setNewNoteParticipantViewable(event.target.checked)}/></StyledTableCell>
                                    <StyledTableCell><Textarea placeholder="..." minRows={3} size="sm" variant="outlined" onChange={(event) => { setNewNoteText(event.target.value)}} /></StyledTableCell>
                                    <StyledTableCell><Button onClick={() => postNewNote()}>Post Note</Button></StyledTableCell>
                                </StyledTableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
            </Stack>
        );
    } else {
        return (
            <div className="PageBodyWide">
                <CircularProgress />
            </div>
        );
    }
}