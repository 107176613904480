import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia, Checkbox,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Input, InputAdornment, Pagination,
    Table, TableBody, TableContainer, TableHead, TablePagination, TableRow,
    Toolbar, IconButton, Switch, Skeleton } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import DashboardElement from 'pageElements/DashboardElement';
import DashboardLayout from 'pageElements/DashboardLayout';
import DashboardBody from 'pageElements/DashboardBody';
import DashboardMenuBar from 'pageElements/DashboardMenuBar';

const MenuBarButton = styled(Button)(({ theme }) => ({
    color: "white",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#6c89b8"
    }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));


export default function ServiceRequests() {
    const [requests, setRequests] = useState([]);

    useEffect(() => {
        fetch("/api/v1/account/open-service-reports").then((res) => {
            return res.json();
        }).then((serviceReportsResponse) => {
            setRequests(serviceReportsResponse.serviceReports);
        });
    }, []);

    return (
        <DashboardLayout>
            <DashboardMenuBar>
                <MenuBarButton onClick={() => {window.location.href = "/"}}>Dashboard</MenuBarButton>
            </DashboardMenuBar>
            <DashboardBody>
                <DashboardElement elevation={0}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack direction="column" spacing={1.0} sx={{  width: "100%", mt: "10px", mb: "10px" }}>
                            <Box sx={{ width: "100%", ml: "5px", mr: "5px" }}>
                                <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Open Service Requests</Typography>
                            </Box>
                            <TableContainer sx={{ overflowx: "initial" }} component={Paper}>
                                <Table sx={{ minWidth: "550px", height: "auto" }} stickyHeader size="small">
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell align="left">Report Id</StyledTableCell>
                                            <StyledTableCell align="right">Severity</StyledTableCell>
                                            <StyledTableCell align="right">Status</StyledTableCell>
                                            <StyledTableCell align="right">Submission Time</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        { requests.map((requestData, index) => {
                                            return(
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell align="left"><Button variant="text" onClick={() => window.location.href="/service-requests/" + requestData.reportId}>{requestData.reportId}</Button></StyledTableCell>
                                                    <StyledTableCell align="right">{requestData.severity}</StyledTableCell>
                                                    <StyledTableCell align="right">{requestData.status}</StyledTableCell>
                                                    <StyledTableCell align="right">{dayjs(requestData.submissionTime).format("M/D/YYYY hh:mm A")}</StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </LocalizationProvider>
                </DashboardElement>
            </DashboardBody>
        </DashboardLayout>
    );
}