import { Children } from 'react';
import { Box, Stack } from '@mui/material';

export default function DashboardBody({ children }) {
    return (
        <Box justifyContent="center" alignItems="center" sx={{ height: "100%", width: "85%", ml: "0%", mr: "0%", overflow: "auto" }}>
            <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={2} sx={{ mb: '0.5%' }}>
                { children }
            </Stack>
        </Box>
    );
}