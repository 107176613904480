import React, { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { SessionContext } from 'Contexts';
import { ModalClose, Textarea } from '@mui/joy';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider, Checkbox,
    FormGroup, TableContainer, Table, TableRow, TableBody, TableHead, IconButton, ListItemText,
    OutlinedInput, InputAdornment, Input, Collapse, TablePagination, Skeleton, Dialog,
    DialogContent, DialogContentText, DialogActions, DialogTitle, Tooltip, Menu } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import utc from 'dayjs/plugin/utc';
import DashboardElement from 'pageElements/DashboardElement';
import AssignCoachesButton from './AssignCoachesButton';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ResumeViewer from 'pageElements/ResumeViewer';

dayjs.extend(utc);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const ClickableStyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
        backgroundColor: theme.palette.primary.light,
    },
    "&:active": {
        backgroundColor: theme.palette.primary.dark,
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

export function OrgCoachApprovalList() {
    const sessionState = useContext(SessionContext);
    let [coaches, setCoaches] = useState(null);
    let [coachAccounts, setCoachAccounts] = useState(null);
    let [coachCertifications, setCoachCertifications] = useState(null);
    let [selectedCoach, setSelectedCoach] = useState(null);
    let [approvedCoachCertificationId, setApprovedCoachCertificationId] = useState(null);
    let [coachApprovalDialogOpen, setCoachApprovalDialogOpen] = useState(false);
    let [rowsPerPage, setRowsPerPage] = useState(5);
    let [page, setPage] = useState(0);

    useEffect(() => {
        fetch("/api/v1/account/coach-certification-levels").then((res) => {
            return res.json();
        }).then((coachCertificationLevelsResponse) => {
            setCoachCertifications(coachCertificationLevelsResponse.certificationLevels);
        });
        fetch("/api/v1/account/unapproved-coaches").then((res) => {
            if(res.status == 200)
                return res.json();
            else
                return null;
        }).then((coachListResponse) => {
            if(coachListResponse == null)
                return;
            if(coachListResponse.coaches == null || coachListResponse.coachAccounts == null)
                return;
            let coachesMap = {};
            for(let i = 0; i < coachListResponse.coaches.length; i++) {
                coachesMap[coachListResponse.coaches[i].accountId] = coachListResponse.coaches[i];
            }
            setCoaches(coachesMap);

            let coachAccountsMap = {};
            for(let i = 0; i < coachListResponse.coachAccounts.length; i++) {
                coachAccountsMap[coachListResponse.coachAccounts[i].accountId] = coachListResponse.coachAccounts[i];
            }
            setCoachAccounts(coachAccountsMap);
        });
    }, []);

    function removeCoachFromList(coachId) {
        let coachesCopy = {...coaches};
        delete coachesCopy[coachId];
        setCoaches(coachesCopy);

        let coachAccountsCopy = {...coachAccounts};
        delete coachAccountsCopy[coachId];
        setCoachAccounts(coachAccountsCopy);
    }

    function approveCoach(coachId, certificationId) {
        fetch("/api/v1/account/coach-certification/" + coachId + "/" + certificationId, {
            method: "POST"
        }).then(() => {
            fetch("/api/v1/account/approve-coach/" + coachId, {
                method: "POST"
            }).then(() => {
                setSelectedCoach(null);
                removeCoachFromList(coachId);
            });
        });
    }

    if(coaches == null || coachAccounts == null) {
        return(<CircularProgress />);
    } else if(Object.keys(coaches).length == 0) {
        return null;
    } else {
        return (
            <DashboardElement elevation={1}>
                <Dialog open={coachApprovalDialogOpen} onClose={() => setCoachApprovalDialogOpen(false)}>
                    <DialogTitle>Approve for coaching</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            { selectedCoach != null && (coachAccounts[selectedCoach].firstName + " " + coachAccounts[selectedCoach].lastName)}
                        </DialogContentText>
                        <FormControl>
                        <InputLabel>Coach Level</InputLabel>
                            <Select autofocus label="Coach Level" value={approvedCoachCertificationId} onChange={(event) => setApprovedCoachCertificationId(event.target.value)}>
                                { coachCertifications != null && coachCertifications.map((cert) => {
                                    return (
                                        <MenuItem value={cert.certificationId}>{cert.name}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {approveCoach(selectedCoach, approvedCoachCertificationId); setCoachApprovalDialogOpen(false);}}>Approve</Button>
                    </DialogActions>
                </Dialog>
                <Box sx={{ width: "100%", maxHeight: "1000px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                    <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
                        <TableContainer sx={{ overflowx: "initial" }} component={Paper}>
                            <Table sx={{ minWidth: "550px" }} stickyHeader size="small">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="center" colSpan={8}><Box sx={{ fontWeight: 'bold' }}>Unapproved Coaches</Box></StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell>{/* profile picture */}</StyledTableCell>
                                        <StyledTableCell>First Name</StyledTableCell>
                                        <StyledTableCell>Last Name</StyledTableCell>
                                        <StyledTableCell>Email</StyledTableCell>
                                        <StyledTableCell>{/* Approve Button */}</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    { Object.keys(coaches).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((coachId) => {
                                        return(
                                            <StyledTableRow>
                                                <StyledTableCell><Avatar alt={coachAccounts[coachId].firstName + " " + coachAccounts[coachId].lastName} src={"/api/v1/account/profile-picture/" + coachId} /></StyledTableCell>
                                                <StyledTableCell>{coachAccounts[coachId].firstName}</StyledTableCell>
                                                <StyledTableCell>{coachAccounts[coachId].lastName}</StyledTableCell>
                                                <StyledTableCell>{coachAccounts[coachId].accountEmail}</StyledTableCell>
                                                <StyledTableCell><Button onClick={() => {setSelectedCoach(coachId); setCoachApprovalDialogOpen(true);}}>Approve</Button></StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination 
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={Object.keys(coaches).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(event, pageNum) => setPage(pageNum)}
                            onRowsPerPageChange={(e) => {setRowsPerPage(e.target.value); setPage(0);}} />
                    </Stack>
                </Box>
            </DashboardElement>
        ); 
    }

}

export function OrgCoachList() {
    const [coachCertifications, setCoachCertifications] = useState([]);
    const [coaches, setCoaches] = useState([]);
    const [coachesView, setCoachesView] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [searchTextFilter, setSearchTextFilter] = useState('');

    useEffect(() => {
        console.log('useEffect, coaches: ', coaches);
        let view = [...coaches];
        if(searchTextFilter.length > 0)
            view = view.filter((c) => {
                return c.account.firstName.toLowerCase().includes(searchTextFilter.toLowerCase()) || c.account.lastName.toLowerCase().includes(searchTextFilter.toLowerCase()) || c.account.accountEmail.toLowerCase().includes(searchTextFilter.toLowerCase());
        });
        setCoachesView(view);
    }, [searchTextFilter, coaches]);

    function OrgCoachListItem({ coach, coachAccount, coachCertifications }) {
        const DAYS_OF_WEEK = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

        let [_coach, _setCoach] = useState(coach);
        let [_coachAccount, _setCoachAccount] = useState(coachAccount);
        let [collapsed, setCollapsed] = useState(true);
        let [resumeOpen, setResumeOpen] = useState(false);
        let [reloadResume, setReloadResume] = useState(0);
        let [coachUpdateCertDialogOpen, setCoachUpdateCertDialogOpen] = useState(false);
        let [updatedCoachCertification, setUpdatedCoachCertification] = useState(null);

        let [moreOptionsMenuAnchorEl, setMoreOptionsMenuAnchorEl] = useState(null);
        let moreOptionsMenuOpen = Boolean(moreOptionsMenuAnchorEl);

        let [editModeActive, setEditModeActive] = useState(false);
        let [editedFirstName, setEditedFirstName] = useState(coachAccount.firstName);
        let [editedLastName, setEditedLastName] = useState(coachAccount.lastName);
        let [editedEmail, setEditedEmail] = useState(coachAccount.accountEmail);
        let [editedRequestInProgress, setEditedRequestInProgress] = useState(false);
        let [editedSnackbarOpen, setEditedSnackbarOpen] = useState(false);
        let [editedSnackbarMessage, setEditedSnackbarMessage] = useState("");

        let [availability, setAvailability] = useState([]);
        let [availableDaysOfWeek, setAvailableDaysOfWeek] = useState([]);
        let [availabilityModalOpen, setAvailabilityModalOpen] = useState(false);

        useEffect(() => {
            fetch("/api/v1/account/availability/" + coachAccount.accountId).then((res) => {
                return res.json();
            }).then((coachAvailability) => {
                let _availableDaysOfWeek = [];
                for(let i = 0; i < coachAvailability.availability.length; i++) {
                    coachAvailability.availability[i].effectiveDate = dayjs(coachAvailability.availability[i].effectiveDate);
                    coachAvailability.availability[i].start = dayjs(coachAvailability.availability[i].start);
                    coachAvailability.availability[i].end = dayjs(coachAvailability.availability[i].end);
                    if(!_availableDaysOfWeek.includes(coachAvailability.availability[i].dayOfWeek))
                        _availableDaysOfWeek.push(coachAvailability.availability[i].dayOfWeek);
                }
                setAvailableDaysOfWeek(_availableDaysOfWeek);
                console.log('Coach _availableDaysOfWeek: ', _availableDaysOfWeek);
                setAvailability(coachAvailability.availability);
            });
        }, []);

        function getCertificationName(id) {
            for(let i = 0; i < coachCertifications.length; i++) {
                if(coachCertifications[i].certificationId == id)
                    return coachCertifications[i].shortName;
            }
            return "NULL";
        }

        function updateCertification() {
            fetch("/api/v1/account/coach-certification/" + _coachAccount.accountId + "/" + updatedCoachCertification, {
                method: "POST"
            }).then((res) => {
                let updatedCoach = {_coach};
                updatedCoach.highestCertificationId = updatedCoachCertification;
                _setCoach(updatedCoach);
                setCoachUpdateCertDialogOpen(false);
            });
        }

        function uploadResume(event) {
            let fileUpload = document.getElementById("resumeFileElement");
            if(fileUpload.files[0] == null)
                return;
            let formData = new FormData();
            formData.append("file", fileUpload.files[0]);
            fetch("/api/v1/account/resume/" + coachAccount.accountId, {
                method: "POST",
                cache: "no-cache",
                body: formData
            }).then((res) => {
                setReloadResume(reloadResume + 1);
                if(!coach.hasResume) {
                    let updatedCoach = {..._coach};
                    updatedCoach.hasResume = true;
                    _setCoach(updatedCoach);
                }
            });
        }

        function canSubmitEdits() {
            return (editedFirstName.length > 0 && editedLastName.length > 0 && editedEmail.length > 0) &&
                (editedFirstName != _coachAccount.firstName ||
                editedLastName != _coachAccount.lastName ||
                editedEmail != _coachAccount.accountEmail);
        }

        function submitEdits() {
            if(!canSubmitEdits())
                return;
            let rq = {
                accountId: coachAccount.accountId,
                firstName: editedFirstName,
                lastName: editedLastName,
                emailAddress: editedEmail
            };
            setEditedRequestInProgress(true);
            fetch("/api/v1/account/user-profile", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(rq)
            }).then((res) => {
                if(res.status == 200) {
                    setEditedSnackbarMessage("Coach profile updated successfully");
                    setEditModeActive(false);
                    setEditedSnackbarOpen(true);
                    setEditedRequestInProgress(false);
                    _coachAccount.firstName = editedFirstName;
                    _coachAccount.lastName = editedLastName;
                    _coachAccount.accountEmail = editedEmail;
                    _setCoachAccount({..._coachAccount});
                } else {
                    setEditedSnackbarMessage("Error while updating coach profile with code: " + res.status);
                    setEditedSnackbarOpen(true);
                    setEditedRequestInProgress(false);
                }
            });
        }

        return (
            <React.Fragment>
                <Dialog open={coachUpdateCertDialogOpen} onClose={() => setCoachUpdateCertDialogOpen(false)}>
                    <DialogTitle>Update Certification</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Update {_coachAccount.firstName}'s Certification
                        </DialogContentText>
                        <FormControl>
                            <InputLabel>Certification</InputLabel>
                            <Select autofocus label="Coach Level" value={updatedCoachCertification} onChange={(event) => setUpdatedCoachCertification(event.target.value)}>
                                { coachCertifications.map((cert) => {
                                    return (
                                        <MenuItem value={cert.certificationId}>{cert.name}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={updatedCoachCertification == null || updatedCoachCertification == _coach.highestCertificationId} varient="filled" onClick={() => updateCertification()}>Save</Button>
                        <Button variant="text" onClick={() => setCoachUpdateCertDialogOpen(false)}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                <Modal open={availabilityModalOpen} onClose={() => setAvailabilityModalOpen(false)}>
                    <ModalDialog>
                        <ModalClose />
                        <Typography>Availability</Typography>
                        <TableContainer sx={{ overflowx: "initial" }}>
                            <Table sx={{ mt: 5, minWidth: "550px" }} stickyHeader size="small">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell>Day of Week</StyledTableCell>
                                        <StyledTableCell>Effective Date</StyledTableCell>
                                        <StyledTableCell>Start</StyledTableCell>
                                        <StyledTableCell>End</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {availableDaysOfWeek.length == 0 && 
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={5}>This coach has no open availability</StyledTableCell>
                                        </StyledTableRow>
                                    }
                                    {DAYS_OF_WEEK.filter((a) => {
                                        return availableDaysOfWeek.includes(a)
                                      }).map((dayOfWeek) => {
                                        return (
                                            <React.Fragment>
                                                <StyledTableRow>
                                                    <StyledTableCell></StyledTableCell>
                                                    <StyledTableCell colSpan={4}>{dayOfWeek}</StyledTableCell>
                                                </StyledTableRow>
                                                {availability.filter((a) => { return a.dayOfWeek == dayOfWeek }).sort((a, b) => {
                                                    if(a.effectiveDate == b.effectiveDate)
                                                        return a.start < b.start;
                                                    return a.effectiveDate < b.effectiveDate;
                                                }).map((a) => {
                                                    return (
                                                        <StyledTableRow>
                                                            <StyledTableCell></StyledTableCell>
                                                            <StyledTableCell></StyledTableCell>
                                                            <StyledTableCell>{a.effectiveDate.local().format("D MMM YYYY")}</StyledTableCell>
                                                            <StyledTableCell>{a.start.local().format("h:mm A")}</StyledTableCell>
                                                            <StyledTableCell>{a.end.local().format("h:mm A")}</StyledTableCell>
                                                        </StyledTableRow>
                                                    )
                                                })}
                                            </React.Fragment>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </ModalDialog>
                </Modal>
                <ResumeViewer reload={reloadResume} lazyLoad={true} title={_coachAccount.firstName + " " + _coachAccount.lastName + "'s Bio"} accountId={_coachAccount.accountId} open={resumeOpen} onClose={() => setResumeOpen(false)} />
                <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <StyledTableCell><IconButton onClick={() => setCollapsed(!collapsed)}>{collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</IconButton></StyledTableCell>
                    <StyledTableCell><Avatar alt={_coachAccount.firstName + " " + _coachAccount.lastName} src={"/api/v1/account/profile-picture/" + _coachAccount.accountId} /></StyledTableCell>
                    { editModeActive &&
                        <React.Fragment>
                            <StyledTableCell>
                                <TextField
                                  label="First Name"
                                  defaultValue={editedFirstName}
                                  onChange={(e) => setEditedFirstName(e.target.value)}
                                  size="small"
                                  variant="standard"
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <TextField
                                  label="Last Name"
                                  defaultValue={editedLastName}
                                  onChange={(e) => setEditedLastName(e.target.value)}
                                  size="small"
                                  variant="standard"
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <TextField
                                  label="Email Address"
                                  defaultValue={editedEmail}
                                  onChange={(e) => setEditedEmail(e.target.value)}
                                  size="small"
                                  variant="standard"
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <Stack direction="row">
                                    <Button 
                                      size="small" 
                                      onClick={() => setEditModeActive(false)}>
                                        Cancel
                                    </Button>
                                    <Button 
                                      size="small" 
                                      variant="contained"
                                      disabled={!canSubmitEdits()} 
                                      loading={editedRequestInProgress}
                                      loadingPosition="start"
                                      onClick={() => submitEdits()}>
                                        Submit
                                    </Button>
                                </Stack>
                            </StyledTableCell>
                        </React.Fragment>
                    }
                    { !editModeActive &&
                        <React.Fragment>
                            <StyledTableCell>{_coachAccount.firstName}</StyledTableCell>
                            <StyledTableCell>{_coachAccount.lastName}</StyledTableCell>
                            <StyledTableCell>{_coachAccount.accountEmail}</StyledTableCell>
                            <StyledTableCell>
                                <IconButton onClick={(e) => setMoreOptionsMenuAnchorEl(e.currentTarget)}>
                                    <MoreVertIcon/>
                                </IconButton>
                                <Menu dense anchorEl={moreOptionsMenuAnchorEl} open={moreOptionsMenuOpen} onClick={(e) => setMoreOptionsMenuAnchorEl(null)} onClose={(e) => setMoreOptionsMenuAnchorEl(null)}>
                                    <MenuItem onClick={(e) => {setMoreOptionsMenuAnchorEl(null); setEditModeActive(true)}}>Edit</MenuItem>
                                    <MenuItem onClick={(e) => {setMoreOptionsMenuAnchorEl(null); setAvailabilityModalOpen(true)}}>View Availability</MenuItem>
                                </Menu>
                            </StyledTableCell>
                        </React.Fragment> 
                    }
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell colSpan={8} style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <Collapse in={!collapsed} timeout="auto" unmountOnExit>
                            <Stack direction="column" spacing={1}>
                                {/*<Typography>Active Participants: {0}</Typography>*/}
                                { _coach.highestCertificationId > 0 && <Chip>Certification: {getCertificationName(_coach.highestCertificationId)}</Chip>}
                            </Stack>
                            <Stack spacing={{ xs: 1, sm: 1 }} direction="row" useFlexGap flexWrap="wrap">
                                <Tooltip title="Coming soon"><span><Button disabled>Disable Coach</Button></span></Tooltip>
                                <Button onClick={() => setCoachUpdateCertDialogOpen(true)}>Update Certification</Button>
                                <Button disabled={!_coach.hasResume} onClick={() => setResumeOpen(true)}>View Bio</Button>
                                <Button component="label">
                                    Upload Bio
                                    <VisuallyHiddenInput type="file" id="resumeFileElement" accept=".pdf" onChange={() => uploadResume()} />
                                </Button> 
                            </Stack>
                        </Collapse>
                    </StyledTableCell>
                </StyledTableRow>
            </React.Fragment>
        );
    }

    useEffect(() => {
        fetch("/api/v1/account/org-coaches").then((res) => {
            return res.json();
        }).then((coachesRes) => {
            let coaches = [];
            for(let i = 0; i < coachesRes.coaches.length; i++) {
                coaches.push({coach: coachesRes.coaches[i], account: coachesRes.coachAccounts[i]});
            }
            setCoaches(coaches);
        });

        fetch("/api/v1/account/coach-certification-levels").then((res) => {
            return res.json();
        }).then((coachCertificationLevelsResponse) => {
            setCoachCertifications(coachCertificationLevelsResponse.certificationLevels);
        });
    }, []);

    return (
        <DashboardElement elevation={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ width: "100%", maxHeight: "3000px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                    <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
                        <Box sx={{ width: "100%" }}>
                            <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Coaches</Typography>
                        </Box>
                        <TableContainer sx={{ overflowx: "initial" }} component={Paper}>
                            <Table sx={{ minWidth: "550px" }} stickyHeader size="small">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={6}>
                                            <TextField sx={{ width: '100%' }} label="Search" type="search" variant='outlined' value={searchTextFilter} onChange={(e) => setSearchTextFilter(e.target.value)} />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell>{/* Expand Button */}</StyledTableCell>
                                        <StyledTableCell>{/* Profile Picture */}</StyledTableCell>
                                        <StyledTableCell>First Name</StyledTableCell>
                                        <StyledTableCell>Last Name</StyledTableCell>
                                        <StyledTableCell>Email</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    { coachesView.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((coach, index) => {
                                        return(<OrgCoachListItem 
                                                coach={coach.coach} 
                                                coachAccount={coach.account} 
                                                coachCertifications={coachCertifications} />);
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination 
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={coaches.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(event, pageNum) => setPage(pageNum)}
                            onRowsPerPageChange={(e) => {setRowsPerPage(e.target.value); setPage(0);}} />
                    </Stack>
                </Box>
            </LocalizationProvider>
        </DashboardElement>
    );
}

export default function ClientCoachList({ clientId }) {
    const sessionState = useContext(SessionContext);
    let [coachWorkCommitments, setCoachWorkCommitments] = useState(null);
    let [coaches, setCoaches] = useState(null);
    let [coachAccounts, setCoachAccounts] = useState(null);
    let [rowsPerPage, setRowsPerPage] = useState(5);
    let [page, setPage] = useState(0);

    function updateWorkCommitment(coachWorkCommitment) {
        for(let i = 0; i < coachWorkCommitments.length; i++) {
            if(coachWorkCommitments[i].coachId == coachWorkCommitment.coachId) {
                let updatedCWCS = [...coachWorkCommitments];
                updatedCWCS[i] = coachWorkCommitment;
                setCoachWorkCommitments(updatedCWCS);
                return;
            }
        }
    }

    useEffect(() => {
        fetch("/api/v1/account/client-coach-commitments/" + clientId).then((res) => {
            return res.json();
        }).then((coachWorkCommitmentsResponse) => {
            setCoachWorkCommitments(coachWorkCommitmentsResponse.commitments);

            let coaches = {};
            for(let i = 0; i < coachWorkCommitmentsResponse.coaches.length; i++) {
                coaches[coachWorkCommitmentsResponse.coaches[i].accountId] = coachWorkCommitmentsResponse.coaches[i];
            }
            setCoaches(coaches);

            let coachAccounts = {};
            for(let i = 0; i < coachWorkCommitmentsResponse.coachAccounts.length; i++) {
                coachAccounts[coachWorkCommitmentsResponse.coachAccounts[i].accountId] = coachWorkCommitmentsResponse.coachAccounts[i];
            }
            setCoachAccounts(coachAccounts);
        });
    }, []);

    function reloadCoaches() {
        fetch("/api/v1/account/client-coach-commitments/" + clientId).then((res) => {
            return res.json();
        }).then((coachWorkCommitmentsResponse) => {
            setCoachWorkCommitments(coachWorkCommitmentsResponse.commitments);
        });
    }

    function ClientCoachListItem({ coachWorkCommitment, updateWorkCommitment, coach, coachAccount, clientId }) {
        let [collapsed, setCollapsed] = useState(true);
        let [participantList, setParticipantList] = useState(null);

        let [updatedWorkCommitment, setUpdatedWorkCommitment] = useState(coachWorkCommitment);
        let [updateWorkCommitmentModalOpen, setUpdateWorkCommitmentModalOpen] = useState(false);
        let [updateWorkCommitmentResponseMessage, setUpdateWorkCommitmentResponseMessage] = useState("");
        let [updateWorkCommitmentResponseSnackbarOpen, setUpdateWorkCommitmentResponseSnackbarOpen] = useState(false);

        useEffect(() => {
            if(!collapsed) {
                fetch("/api/v1/account/coachees/" + coachAccount.accountId).then((res) => {
                    return res.json();
                }).then((participantDataResponse) => {
                    let filteredByClientList = [];
                    for(let i = 0; i < participantDataResponse.participantsData.length; i++)
                        if(participantDataResponse.participantsData[i].clientId == clientId)
                            filteredByClientList.push(participantDataResponse.participantsData[i]);
                    setParticipantList(filteredByClientList);
                });
            } else {
                setParticipantList(null);
            }
        }, [collapsed]);

        function canSubmitUpdateWorkCommitment() {
            if(updatedWorkCommitment.maxParticipantCommitments < coachWorkCommitment.currentParticipantCommitments)
                return false;
            else if(updatedWorkCommitment.coachPayRate < 0 || updatedWorkCommitment.coachPayRate >= 100000)
                return false;
            else if(updatedWorkCommitment.coachPayRate != coachWorkCommitment.coachPayRate ||
                updatedWorkCommitment.maxParticipantCommitments != coachWorkCommitment.maxParticipantCommitments
            )
                return true;
            else
                return false;
        }

        function submitUpdateWorkCommitment() {
            fetch("/api/v1/account/update-client-coach-commitment", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(updatedWorkCommitment)
            }).then((res) => {
                if(res.status == 200) {
                    setUpdateWorkCommitmentResponseMessage("Work commitment updated successfully");
                    setUpdateWorkCommitmentResponseSnackbarOpen(true);
                    setUpdateWorkCommitmentModalOpen(false);
                    updateWorkCommitment(updatedWorkCommitment);
                } else {
                    setUpdateWorkCommitmentModalOpen(false);
                    setUpdateWorkCommitmentResponseMessage("Error while updating work commitment: code " + res.status);
                    setUpdateWorkCommitmentResponseSnackbarOpen(true);
                }
            });
        }

        return (
            <React.Fragment>
                <Snackbar open={updateWorkCommitmentResponseSnackbarOpen} autoHideDuration={6000} onClose={() => setUpdateWorkCommitmentResponseSnackbarOpen(false)} message={updateWorkCommitmentResponseMessage} />
                <Modal open={updateWorkCommitmentModalOpen} onClose={() => setUpdateWorkCommitmentModalOpen(false)}>
                    <ModalDialog>
                        <Stack direction="column" alignItems="center" justifyContent="center" sx={{ width: "100%", mt: "10px" }} spacing={ 1 }>
                            <Typography>Current participant count: {updatedWorkCommitment.currentParticipantCommitments}</Typography>
                            <TextField
                                autoFocus
                                required
                                type="number"
                                margin="dense"
                                label="Max Participants"
                                fullWidth
                                variant="standard"
                                value={updatedWorkCommitment.maxParticipantCommitments}
                                onChange={(event) => {let uwc = {...updatedWorkCommitment}; uwc.maxParticipantCommitments = event.target.value; setUpdatedWorkCommitment(uwc)}}
                            />
                            <TextField
                                autoFocus
                                required
                                type="number"
                                margin="dense"
                                label="Pay Rate ($/Hr.)"
                                fullWidth
                                variant="standard"
                                value={(updatedWorkCommitment.coachPayRate/100.0).toFixed(2)}
                                onChange={(event) => {let uwc = {...updatedWorkCommitment}; uwc.coachPayRate = (Number(event.target.value)*100); setUpdatedWorkCommitment(uwc);}}
                            />
                            <Stack direction="row" spacing={0.25}>
                                <Button onClick={() => setUpdateWorkCommitmentModalOpen(false)}>Cancel</Button>
                                <Button disabled={!canSubmitUpdateWorkCommitment()} onClick={() => submitUpdateWorkCommitment()}>Submit</Button>
                            </Stack>
                        </Stack>
                    </ModalDialog>
                </Modal>
                <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <StyledTableCell><IconButton onClick={() => setCollapsed(!collapsed)}>{collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</IconButton></StyledTableCell>
                    <StyledTableCell><Avatar alt={coachAccount.firstName + " " + coachAccount.lastName} src={"/api/v1/account/profile-picture/" + coachWorkCommitment.coachId} /></StyledTableCell>
                    <StyledTableCell>{coachAccount.firstName}</StyledTableCell>
                    <StyledTableCell>{coachAccount.lastName}</StyledTableCell>
                    <StyledTableCell>{coachAccount.accountEmail}</StyledTableCell>
                    <StyledTableCell>{coachWorkCommitment.maxParticipantCommitments}</StyledTableCell>
                    <StyledTableCell>{coachWorkCommitment.currentParticipantCommitments}</StyledTableCell>
                    <StyledTableCell>${(coachWorkCommitment.coachPayRate / 100.00).toFixed(2)}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell colSpan={8} style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <Collapse in={!collapsed} timeout="auto" unmountOnExit>
                        { participantList == null && <CircularProgress />}
                        { (participantList != null && participantList.length) == 0 && <StyledTableCell>This coach has no participants</StyledTableCell>}
                        { (participantList != null && participantList.length) > 0 &&
                            <Box sx={{ margin: 1 }}>
                                <Table stickyHeader size="small">
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={6}>Participants</StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell>{/* Profile Picture */}</StyledTableCell>
                                            <StyledTableCell>First Name</StyledTableCell>
                                            <StyledTableCell>Last Name</StyledTableCell>
                                            <StyledTableCell>Hours Coached</StyledTableCell>
                                            <StyledTableCell>Hours Pending</StyledTableCell>
                                            <StyledTableCell>Hours Remaining</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        { participantList.map((participantData) => {
                                            return(
                                                <StyledTableRow>
                                                    <StyledTableCell><Avatar alt={participantData.firstName} src={"/api/v1/account/profile-picture/" + participantData.participant.accountId} /></StyledTableCell>
                                                    <StyledTableCell>{participantData.firstName}</StyledTableCell>
                                                    <StyledTableCell>{participantData.lastName}</StyledTableCell>
                                                    <StyledTableCell>{(participantData.coachingHours.minutesUsed / 60.0).toFixed(1)}</StyledTableCell>
                                                    <StyledTableCell>{(participantData.coachingHours.minutesPending / 60.0).toFixed(1)}</StyledTableCell>
                                                    <StyledTableCell>{(participantData.coachingHours.minutesRemaining / 60.0).toFixed(1)}</StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Box>
                            }
                            <Tooltip title="Coming soon"><span><Button disabled>Disable Coach</Button></span></Tooltip>
                            <Button onClick={() => setUpdateWorkCommitmentModalOpen(true)}>Update Work Commitment</Button>
                        </Collapse>
                    </StyledTableCell>
                </StyledTableRow>
            </React.Fragment>
        );
    }

    if(coachWorkCommitments == null || coaches == null || coachAccounts == null) {
        return (
            <DashboardElement elevation={1}>
                <Typography>Coachees</Typography>
                <Stack direction="column" spacing={0.5}>
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                </Stack>
            </DashboardElement>
        );
    } else if(coachWorkCommitments.length == 0) {
        return(
            <DashboardElement elevation={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ width: "100%", maxHeight: "5000px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                        <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
                            <Box sx={{ width: "100%" }}>
                                <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Contracted Coaches</Typography>
                            </Box>
                            <TableContainer sx={{ overflowx: "initial" }} component={Paper}>
                                <Table sx={{ minWidth: "550px" }} stickyHeader size="small">
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell>{/* collapse button */}</StyledTableCell>
                                            <StyledTableCell>{/* profile picture */}</StyledTableCell>
                                            <StyledTableCell>First Name</StyledTableCell>
                                            <StyledTableCell>Last Name</StyledTableCell>
                                            <StyledTableCell>Email</StyledTableCell>
                                            <StyledTableCell>Max Participants</StyledTableCell>
                                            <StyledTableCell>Current Participants</StyledTableCell>
                                            <StyledTableCell>Subcontractor Hourly Rate</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={8}>This client doesn't currently have any coaches.</StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack direction="row" justifyContent="space-between">
                                <Box overflow="hidden">
                                    <Stack direction="row" spacing={ 0.5 } alignItems="center" justifyContent="center">
                                        <AssignCoachesButton clientId={ clientId } />
                                    </Stack>
                                </Box>
                                <TablePagination 
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={coachWorkCommitments.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={(event, pageNum) => setPage(pageNum)}
                                    onRowsPerPageChange={(e) => {setRowsPerPage(e.target.value); setPage(0);}} />
                            </Stack>
                        </Stack>
                    </Box>
                </LocalizationProvider>
            </DashboardElement>
        );
    } else {
        return (
            <DashboardElement elevation={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ width: "100%", maxHeight: "1000px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                        <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
                            <Box sx={{ width: "100%" }}>
                                <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Contracted Coaches</Typography>
                            </Box>
                            <TableContainer sx={{ overflowx: "initial" }} component={Paper}>
                                <Table sx={{ minWidth: "550px" }} stickyHeader size="small">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>{/* collapse button */}</StyledTableCell>
                                            <StyledTableCell>{/* profile picture */}</StyledTableCell>
                                            <StyledTableCell>First Name</StyledTableCell>
                                            <StyledTableCell>Last Name</StyledTableCell>
                                            <StyledTableCell>Email</StyledTableCell>
                                            <StyledTableCell>Max Participants</StyledTableCell>
                                            <StyledTableCell>Current Participants</StyledTableCell>
                                            <StyledTableCell>Subcontractor Hourly Rate</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { coachWorkCommitments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((coachWorkCommitment, index) => {
                                            return(
                                                <ClientCoachListItem 
                                                  coachWorkCommitment={coachWorkCommitment} 
                                                  updateWorkCommitment={updateWorkCommitment}
                                                  coach={coaches[coachWorkCommitment.coachId]}
                                                  coachAccount={coachAccounts[coachWorkCommitment.coachId]}
                                                  clientId={clientId} />
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack direction="row" justifyContent="space-between">
                                <Box overflow="hidden">
                                    <Stack direction="row" spacing={ 0.5 } alignItems="center" justifyContent="center">
                                        <AssignCoachesButton clientId={ clientId } />
                                    </Stack>
                                </Box>
                                <TablePagination 
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={coachWorkCommitments.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={(event, pageNum) => setPage(pageNum)}
                                    onRowsPerPageChange={(e) => {setRowsPerPage(e.target.value); setPage(0);}} />
                            </Stack>
                        </Stack>
                    </Box>
                </LocalizationProvider>
            </DashboardElement>
        );
    }
}