import React, { useState, useContext, useEffect, Fragment, forwardRef } from 'react';
import { SessionContext } from 'Contexts';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider, Checkbox,
    FormGroup, TableContainer, Table, TableRow, TableBody, TableHead, IconButton, ListItemText,
    OutlinedInput, InputAdornment, Input, Collapse, TablePagination, Skeleton, Modal, Dialog,
    DialogContent, DialogContentText, DialogActions, DialogTitle, Tooltip } from '@mui/material';
import DashboardElement from 'pageElements/DashboardElement';

import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';



function CoachMatchReviewSubscriptionButton({ clientId }) {
  const sessionState = useContext(SessionContext);
  const [subscriptionDataLoaded, setSubscriptionDataLoaded] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [editSubscriptionDialogOpen, setEditSubscriptionDialogOpen] = useState(false);

  const [notificationFrequency, setNotificationFrequency] = useState(60);

  useEffect(() => {
    refreshSubscriptionData();
  }, []);

  function resetSubscriptionEditForm() {
    if(subscriptionData != null)
      setNotificationFrequency(subscriptionData.minimumNotificationIntervalMinutes);
  }

  function refreshSubscriptionData() {
    setSubscriptionDataLoaded(false);
    fetch("/api/v1/account/coach-match-review-request-subscription/" + clientId).then((res) => {
      if(res.status == 200)
        return res.json();
      else
        return null;
    }).then((recurringNotificationSubscription) => {
      setSubscriptionData(recurringNotificationSubscription);
      setSubscriptionDataLoaded(true);

      if(recurringNotificationSubscription != null) {
        setNotificationFrequency(recurringNotificationSubscription.minimumNotificationIntervalMinutes);
      } else {
        setNotificationFrequency(60);
      }
    });
  }

  function deleteSubscription() {
    setSubscriptionDataLoaded(false);
    fetch("/api/v1/account/delete-notification-subscription/" + subscriptionData.subscriptionId, {
      method: "DELETE"
    }).then((res) => {
      refreshSubscriptionData();
    });
  }

  function updateSubscriptionData() {
    setSubscriptionDataLoaded(false);

    let rq = {
      minimumNotificationIntervalMinutes: notificationFrequency,
      accountId: sessionState.accountId,
      nextActivationTime: null,
      notificationPublisherId: 1,
      hasActivated: false,
      clientId: clientId,
      orgId: null
    };

    fetch("/api/v1/account/update-notification-subscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(rq)
    }).then((res) => {
      refreshSubscriptionData();
    });
  }

  if(!subscriptionDataLoaded) {
    return(<CircularProgress />);
  } else {
    return (
      <React.Fragment>
        <Dialog open={editSubscriptionDialogOpen} onClose={() => {setEditSubscriptionDialogOpen(false)}}>
          <DialogTitle>Notification Subscription</DialogTitle>
          <DialogContent>
            <DialogContentText>
               Update or disable notification for pending coach match review requests
            </DialogContentText>
            <Stack direction="column" sx={{ mt: "10px", mb: "10px" }}>
              <FormControl>
                <InputLabel>Notification Frequency</InputLabel>
                <Select value={notificationFrequency} onChange={(e) => setNotificationFrequency(e.target.value)} label="Notification Frequency">
                  <MenuItem value={30}>Every 30 Minutes</MenuItem>
                  <MenuItem value={60}>Every Hour</MenuItem>
                  <MenuItem value={360}>Every 6 Hours</MenuItem>
                  <MenuItem value={720}>Every 12 Hours</MenuItem>
                  <MenuItem value={1440}>Daily</MenuItem>
                  <MenuItem value={10080}>Weekly</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {resetSubscriptionEditForm(); setEditSubscriptionDialogOpen(false);}}>Cancel</Button>
            { (subscriptionData != null) && <Button color="error" onClick={() => {deleteSubscription(); setEditSubscriptionDialogOpen(false);}}>Turn Off Notifications</Button> }
            { (subscriptionData != null) && <Button color="secondary" onClick={() => {updateSubscriptionData(); setEditSubscriptionDialogOpen(false);}}>Update</Button> }
            { (subscriptionData == null) && <Button onClick={() => {updateSubscriptionData(); setEditSubscriptionDialogOpen(false);}}>Enable Notifications</Button> }
          </DialogActions>
        </Dialog>
        <IconButton onClick={() => setEditSubscriptionDialogOpen(true)}>
          { (subscriptionData == null) && <NotificationAddIcon />}
          { (subscriptionData != null) && <NotificationsActiveIcon />}
        </IconButton>
      </React.Fragment>
    );
  } 
}

export default function CoachMatchApprovalRequestList({ clientId }) {
    let [coachMatchApprovalRequests, setCoachMatchApprovalRequests] = useState(null);
    let [rowsPerPage, setRowsPerPage] = useState(5);
    let [page, setPage] = useState(0);
  
    useEffect(() => {
      fetch("/api/v1/account/coach-match-approval-requests/" + clientId).then((res) => {
        if(res.status == 200)
          return res.json();
        else
          return null;
      }).then((coachMatchApprovalRequestsResponse) => {
        if(coachMatchApprovalRequestsResponse != null)
          setCoachMatchApprovalRequests(coachMatchApprovalRequestsResponse.requests);
      });
    }, []);

    function refreshRequestsList() {
      fetch("/api/v1/account/coach-match-approval-requests/" + clientId).then((res) => {
        if(res.status == 200)
          return res.json();
        else
          return null;
      }).then((coachMatchApprovalRequestsResponse) => {
        if(coachMatchApprovalRequestsResponse != null)
          setCoachMatchApprovalRequests(coachMatchApprovalRequestsResponse.requests);
        else
          setCoachMatchApprovalRequests([]);
      });
    }

    function openRequestInNewWindow(participantId) {
      const windowHandle = window.open("/coach-match-approval-request/" + participantId.toString() + "/" + clientId);
      const closedPoller = setInterval(() => {
        if (windowHandle.closed) {
            clearInterval(closedPoller);
            refreshRequestsList();
        }
      }, 1000);
    }
  
    if(coachMatchApprovalRequests == null) {
      return(null);
    } else {
      return (
        <DashboardElement elevation={1}>
            <Box sx={{ width: "100%", maxHeight: "1000px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
                    <Box sx={{ width: "100%" }}>
                      <Stack direction="row" spacing={0} justifyContent="space-between" sx={{ width: "100%", height: "100%"}}>
                        <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Coach Match Approval Requests</Typography>
                        <CoachMatchReviewSubscriptionButton clientId={clientId} />
                      </Stack>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 600 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Participant Email</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                              {coachMatchApprovalRequests.length > 0 && coachMatchApprovalRequests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((cmar) => {
                                return (
                                  <TableRow>
                                      <TableCell align="left">
                                      <Button variant="text" onClick={() => {openRequestInNewWindow(cmar.participantId)}}>
                                          {cmar.participantEmailAddress}
                                      </Button>
                                      </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination 
                      rowsPerPageOptions={[5]}
                      component="div"
                      count={coachMatchApprovalRequests.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={(event, pageNum) => setPage(pageNum)} />
                </Stack>
            </Box>
        </DashboardElement>
      );
    }
  }