import React, { useState, useContext, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { SessionContext } from 'Contexts';
import { Sheet, Textarea } from '@mui/joy';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import { Typography } from '@mui/joy';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider, Checkbox,
    FormGroup, TableContainer, Table, TableRow, TableBody, TableHead, IconButton, ListItemText,
    OutlinedInput, InputAdornment, Input, Collapse, TablePagination, Skeleton, Tabs, Tab } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import utc from 'dayjs/plugin/utc';
import DashboardElement from 'pageElements/DashboardElement';
import { justifyContent } from 'styled-system';

import SendIcon from '@mui/icons-material/Send';


export default function Chat({ otherUserId, widgetHeight='100%' }) {
    const sessionState = useContext(SessionContext);
    const [messages, setMessages] = useState([]);
    const [messageViewedStatuses, setMessageViewedStatuses] = useState([]);
    const [chatId, setChatId] = useState(null);
    const [otherUserProfile, setOtherUserProfile] = useState(null);
    const [searchAfterTimestamp, setSearchAfterTimestamp] = useState(null);

    function canSendNewMessage(messageText) {
        return messageText.length > 0 && chatId != null;
    }

    function sendNewMessage(messageText) {
        let newMessage = {
            messageType: 'STANDARD',
            messageText: messageText,
            chatId: chatId
        };

        fetch("/api/v1/account/chat-message", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newMessage)
        }).then((res) => {
            if(res.status == 200) {
                getNewChatMessages();
            } else {

            }
        });
    }

    useEffect(() => {
        fetch("/api/v1/account/user-profile/" + otherUserId).then((res) => {
            return res.json();
        }).then((userProfile) => {
            setOtherUserProfile(userProfile);
        });

        let rq = {
            otherAccountId: otherUserId,
            chatId: chatId,
            searchAfterTimestamp: false
        };
        fetch("/api/v1/account/chat-messages", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(rq)
        }).then((res) => {
            return res.json();
        }).then((chatMessagesResponse) => {
            setChatId(chatMessagesResponse.chatId);
            if(chatMessagesResponse.chatMessages != null)
                setMessages(chatMessagesResponse.chatMessages);
            if(chatMessagesResponse.chatMessageViewedStatuses != null)
                setMessageViewedStatuses(chatMessagesResponse.chatMessageViewedStatuses);
        });
    }, []);

    function MessagesUpdater({}) {
        const [updateMessages, setUpdateMessages] = useState(0);

        useEffect(() => {
            let timeoutId = setTimeout(() => {
                getNewChatMessages();
                setUpdateMessages(updateMessages + 1);
            }, 15000);
            return () => clearTimeout(timeoutId);
        }, [updateMessages]);
    }

    useEffect(() => {
        const viewStatusesMap = new Map();
        for(let i = 0; i < messageViewedStatuses.length; i++)
            viewStatusesMap.set(messageViewedStatuses[i].messageId, messageViewedStatuses[i]);
        for(let i = 0; i < messages.length; i++) {
            if(!viewStatusesMap.has(messages[i].messageId)) {
                acknowledgeMessageViewed(messages[i].messageId);
                let chatMessageViewedStatus = {
                    messageId: messages[i].messageId
                };
                messageViewedStatuses.push(messages[i].messageId, chatMessageViewedStatus);
            }
        }
    }, [messageViewedStatuses]);

    useEffect(() => {
        if(messages.length == 0)
            setSearchAfterTimestamp(null);
        else {
            const lastMessage = messages[messages.length - 1];
            setSearchAfterTimestamp(lastMessage.sentTimestamp);
        }
    }, [messages]);

    function acknowledgeMessageViewed(messageId) {
        fetch("/api/v1/account/message-viewed/" + messageId, {
            method: "POST"
        });
    }

    function getNewChatMessages() {
        let rq = {
            otherAccountId: otherUserId,
            chatId: chatId,
            searchAfterTimestamp: searchAfterTimestamp == null ? false : true,
            timestamp: searchAfterTimestamp
        };

        fetch("/api/v1/account/chat-messages", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(rq)
        }).then((res) => {
            return res.json();
        }).then((chatMessagesResponse) => {
            let newMessagesAdded = false;
            if(chatMessagesResponse.chatMessages != null) {
                for(const newMessage of chatMessagesResponse.chatMessages) {
                    messages.push(newMessage);
                    newMessagesAdded = true;
                }
            }
            if(newMessagesAdded) {
                if(chatMessagesResponse.chatMessageViewedStatuses != null) {
                    for(const newMessageViewedStatus of chatMessagesResponse.chatMessageViewedStatuses) {
                        messageViewedStatuses.push(newMessageViewedStatus);
                    }
                }
                setMessages([...messages]);
                setMessageViewedStatuses([...messageViewedStatuses]);
            }
        });
    }

    function ChatMessage({ message }) {
        return (
            <Stack sx={{ width: '100%'}} alignItems={message.senderId == sessionState.accountId ? 'flex-end' : 'flex-start'} justifyContent={message.senderId == sessionState.accountId ? 'flex-end' : 'flex-start'}>
                <Stack direction={message.senderId == sessionState.accountId ? 'row' : 'row-reverse'} sx={{ pl: 3, pr: 3, mt: 4, width: '70%'}} spacing={2.5} justifyContent='flex-end' alignItems={message.senderId == sessionState.accountId ? 'flex-end' : 'flex-start'}>
                    <Stack direction='column' alignItems={message.senderId == sessionState.accountId ? 'flex-end' : 'flex-start'} justifyContent={message.senderId == sessionState.accountId ? 'flex-end' : 'flex-start'}>
                        <Stack direction='row' sx={{ width: '100%' }} justifyContent={ message.senderId == sessionState.accountId ? 'flex-start' : 'flex-end' }>
                            <Typography level='body-xs'>{ message.senderId == sessionState.accountId ? 'You' : otherUserProfile == null ? '' :  otherUserProfile.firstName }</Typography>
                        </Stack>
                        <Sheet variant='outlined' sx={{ alignItems: 'center', pl: 2, pr: 2, borderRadius: '5px', borderBottomRightRadius: 0 }}>
                            <Typography level='title-md'>{message.messageText}</Typography>
                        </Sheet>
                    </Stack>
                    <Avatar 
                     size='sm' 
                     variant='solid' 
                     alt={message.senderId == sessionState.accountId ? '' : otherUserProfile == null ? '' :  otherUserProfile.firstName + ' ' + otherUserProfile.lastName} 
                     src={"/api/v1/account/profile-picture/" + message.senderId} />
                </Stack>
            </Stack>
        );
    }

    function ChatTextBox() {
        const [newMessageText, setNewMessageText] = useState('');

        function onSend() {
            sendNewMessage(newMessageText);
            setNewMessageText('');
        }

        return (
            <Sheet variant="soft" sx={{ maxHeight: '30%', width: '80%' }}>
                <Stack direction='column' alignItems='center' justifyContent='center' sx={{ maxHeight: '100%', width: '100%' }}>
                    <TextField
                        sx={{ width: '90%' }}
                        multiline
                        rows={2}
                        value={newMessageText}
                        onChange={(e) => setNewMessageText(e.target.value)}
                        placeholder="Type a message here..."
                    />
                    <Stack direction='column' alignItems='flex-end' justifyContent='center' sx={{ width: '100%' }}>
                        <Button disabled={!canSendNewMessage(newMessageText)} onClick={(e) => onSend()} endIcon={<SendIcon />}>Send</Button>
                    </Stack>
                </Stack>
            </Sheet>
        );
    }

    return (
        <Stack direction="column" sx={{ width: '100%', height: widgetHeight, mt: '5px' }} alignItems='center' justifyContent='center' spacing={0.0}>
            <MessagesUpdater />
            <Sheet variant="soft" sx={{ width: '80%', height: '5%'}}>
                <Typography>Chat with <Typography color='primary'>{otherUserProfile == null ? '...' :  otherUserProfile.firstName + ' ' + otherUserProfile.lastName}</Typography></Typography>
            </Sheet>
            <Sheet variant="plain" sx={{ width: '100%', height: '90%', maxHeight: '90%', m: 0 }}>
                <Stack direction='column' sx={{ width: '100%', height: '100%' }} alignItems='center' justifyContent='flex-start'>
                    <Sheet variant='soft' sx={{ width: '80%', height: '70%' }}>
                        <Box sx={{ width: '100%', height: '100%', overflow: 'hidden', overflowY: 'scroll' }}>
                            <Stack direction='column' justifyContent='flex-end' sx={{ pb: 1, pt: 1, width: '100%' }}>
                                {messages.map((message, index) => {
                                    return (
                                        <ChatMessage message={message} />
                                    );
                                })}
                            </Stack>
                        </Box>
                    </Sheet>
                    <ChatTextBox />
                </Stack>
            </Sheet>
        </Stack>
    );
}