import React, { useEffect, useState } from 'react';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider,
    Tooltip, IconButton } from '@mui/material';
import ResumeViewer from 'pageElements/ResumeViewer';

import DashboardElement from "pageElements/DashboardElement";

export default function CoachProfile({ coachId }) {
    const [coachData, setCoachData] = useState(null);
    const [resumeOpen, setResumeOpen] = useState(false);

    useEffect(() => {
        fetch("/api/v1/account/coach-profile/" + coachId).then((res) => {
            return res.json();
        }).then((coachProfileResponse) => {
            setCoachData(coachProfileResponse);
        });
    }, []);

    if(coachData == null)
        return null;

    return(
        <DashboardElement elevation={1}>
            <ResumeViewer lazyLoad={false} title={coachData.firstName + " " + coachData.lastName + " Bio"} accountId={coachData.coachId} open={resumeOpen} onClose={() => setResumeOpen(false)} />
            <Box sx={{ width: "100%", maxHeight: "1000px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                <Stack direction="column" spacing={1.0} sx={{  width: "100%", mt: "10px", mb: "10px"}}>
                    <Box sx={{ width: "100%" }}>
                        <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Your Coach</Typography>
                    </Box>
                    <Paper elevation={1}>
                        <Stack direction="row" spacing={1} sx={{ mt: "5px", mb: "5px" }}>
                            <Stack direction="row" sx={{ width: "33%" }} spacing={1} justifyContent="center" alignItems="center">
                                <Avatar alt={ coachData.firstName + " " + coachData.lastName } src={ "/api/v1/account/profile-picture/" + coachData.coachId } />
                                <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>{coachData.firstName} {coachData.lastName}</Typography>
                            </Stack>
                            <Stack direction="column" sx={{ width: "65%" }} spacing={1} justifyContent="center" alignItems="center">
                                <Typography variant="caption" display="block" gutterBottom>{coachData.personalStatement}</Typography>
                                {coachData.hasResume == true && <Button onClick={() => setResumeOpen(true)}>View Bio</Button>}
                            </Stack>
                        </Stack>
                    </Paper>
                </Stack>
            </Box>
        </DashboardElement>
    );
}