import { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Input, InputAdornment } from '@mui/material';
import { Button } from '@mui/joy';


export default function OptOut() {
    let [searchParams, setSearchParams] = useSearchParams();
    let [optOutSuccessful, setOptOutSuccessful] = useState(null);

    useEffect(() => {
        let regCode = searchParams.get("key");
        
        fetch("/api/v1/account/opt-out/" + regCode, {
            method: "POST"
        }).then((res) => {
            if(res.status == 200) {
                setOptOutSuccessful(true);
                return;
            } else {
                setOptOutSuccessful(true);
                return;
            }
        });
    }, []);

    if(optOutSuccessful == null) {
        return (
            <Stack direction="column" spacing={ 1.0 } sx={{ mt: "2.5%", mb: "2.5%" }}>
                <Typography>Opting-out of coaching with EST2AR</Typography>
                <CircularProgress />
            </Stack>
        );
    } else if(optOutSuccessful == true) {
        return (
            <Stack direction="column" spacing={ 1.0 } sx={{ mt: "2.5%", mb: "2.5%" }}>
                <Typography>You have successfully opted-out of coaching.</Typography>
            </Stack>
        );
    } else {
        return (
            <Stack direction="column" spacing={ 1.0 } sx={{ mt: "2.5%", mb: "2.5%" }}>
                <Typography>We've experienced an error processing your request, please try again later.</Typography>
            </Stack>
        );
    }
}