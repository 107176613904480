import { useState, useContext, useEffect, Fragment, forwardRef } from 'react';
import { SessionContext } from 'Contexts';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import Input from '@mui/joy/Input';
import DialogContent from '@mui/joy/DialogContent';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
  Chip, Stack, Paper, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
  Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Dialog, DialogActions,
  Table, TableHead, TableBody, TablePagination, TableContainer, TableRow, Button } from '@mui/material';
import ClientLink from './ClientLink';
import DashboardElement from 'pageElements/DashboardElement';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const ClickableStyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
        backgroundColor: theme.palette.primary.light,
    },
    "&:active": {
        backgroundColor: theme.palette.primary.dark,
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    }
}));


export default function ClientList() {
    const [clientList, setClientList] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    useEffect(() => {
        fetch("/api/v1/account/clients", {
            method: "GET",
            cache: "no-cache",
            mode: "cors"
        }).then((res) => {
            if(res.status == 200) {
                return res.json();
            }
            else {
                return null;
            }
        }).then((res) => {
            if(res != null) {
                setClientList(res.clients);
            }
        });
    }, []);

    function ClientListItem({ client }) {
        const [contract, setContract] = useState(null);

        useEffect(() => {
            if(client.contractId == null)
                return;
            fetch("/api/v1/account/contract/" + client.clientId).then((res) => {
                if(res.status == 200)
                    return res.json();
                else
                    return null;
            }).then((contractRes) => {
                if(contractRes != null)
                    setContract(contractRes);
            });
        }, []);

        return (
            <StyledTableRow>
                <StyledTableCell><Button variant="text" onClick={() => window.location.href=("/client/" + client.clientId)}>{client.clientName}</Button></StyledTableCell>
                <StyledTableCell>{client.programName}</StyledTableCell>
                <StyledTableCell>{contract != null && contract.contractInfo}</StyledTableCell>
            </StyledTableRow>
        );
    }

    return (
        <DashboardElement elevation={2}>
            <Box sx={{ width: "100%", maxHeight: "1000px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
                    <Box sx={{ width: "100%" }}>
                        <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Clients</Typography>
                    </Box>
                    <TableContainer sx={{ overflowx: "initial" }} component={Paper}>
                        <Table sx={{ minWidth: "550px" }} stickyHeader size="small">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>Client</StyledTableCell>
                                    <StyledTableCell>Program</StyledTableCell>
                                    <StyledTableCell>Details</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                { clientList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((client, index) => {
                                    return(<ClientListItem client={client} />);
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination 
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={clientList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, pageNum) => setPage(pageNum)}
                        onRowsPerPageChange={(e) => {setRowsPerPage(e.target.value); setPage(0);}} />
                </Stack>
            </Box>
        </DashboardElement>
    );
}