import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider, TableContainer,
    Table, TableRow, TableBody, TableHead, Modal, Tooltip } from '@mui/material';
import { SessionContext } from 'Contexts';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
}));

const ETHNICITIES = {
    AMERICAN_INDIAN_OR_ALASKA_NATIVE: "American Indian or Alaskan Native",
    ASIAN: "Asian",
    BLACK_OR_AFRICAN_AMERICAN: "Black",
    NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER: "Native Hawaiin or Pacific Islander",
    MIDDLE_EASTERN_OR_NORTH_AFRICAN: "Middle Eastern or North African",
    WHITE: "White or Caucasian"
};

const GENDERS = {
    MALE: "Male",
    FEMALE: "Female",
    NONBINARY: "Nonbinary"
};


export default function CoachMatchApprovalRequest() {
    const sessionState = useContext(SessionContext);
    let { requestId, clientId } = useParams();
    let [participantChoosesCoach, setParticipantChoosesCoach] = useState(false);
    let [userProfile, setUserProfile] = useState(null);
    let [genderPreference, setGenderPreference] = useState("");
    let [ethnicityPreference, setEthnicityPreference] = useState("");
    let [coachMatches, setCoachMatches] = useState({coaches: [], matchScores: []});
    let [selectableClientCoaches, setSelectableClientCoaches] = useState([]);
    let [allClientCoaches, setAllClientCoaches] = useState([]);
    let [highestMatchScore, setHighestMatchScore] = useState(0.0);
    let [lowestMatchScore, setlowestMatchScore] = useState(0.0);
    let [addCoachModalOpen, setAddCoachModalOpen] = useState(false);


    useEffect(() => {
        loadCoachMatchReviewRequest();
        fetch("/api/v1/account/client-coach-commitments/" + clientId).then((res) => {
            return res.json();
        }).then((coachWorkCommitmentsResponse) => {
            let clientCoachesList = [];
            for(let i = 0; i < coachWorkCommitmentsResponse.coaches.length; i++) {
                coachWorkCommitmentsResponse.coaches[i].ethnicity = ETHNICITIES[coachWorkCommitmentsResponse.coaches[i].ethnicity];
                coachWorkCommitmentsResponse.coaches[i].gender = GENDERS[coachWorkCommitmentsResponse.coaches[i].gender];
                clientCoachesList.push({
                    coach: coachWorkCommitmentsResponse.coaches[i],
                    coachAccount: coachWorkCommitmentsResponse.coachAccounts[i]
                });
            };
            setAllClientCoaches(clientCoachesList);
        });

        fetch("/api/v1/account/contract/" + clientId).then((res) => {
            return res.json();
        }).then((contractRes) => {
            setParticipantChoosesCoach(contractRes.participantChoosesCoach);
        });

        fetch("/api/v1/account/participant-coach-gender-preference/" + requestId).then((res) => {
            return res.text();
        }).then((genderPreferenceRes) => {
            setGenderPreference(genderPreferenceRes);
        });

        fetch("/api/v1/account/participant-coach-ethnicity-preference/" + requestId).then((res) => {
            return res.text();
        }).then((ethnicityPreferenceRes) => {
            setEthnicityPreference(ethnicityPreferenceRes);
        });

        fetch("/api/v1/account/user-profile/" + requestId).then((res) => {
            return res.json();
        }).then((userProfile) => {
            setUserProfile(userProfile);
        })
    }, []);

    useEffect(() => {
        if(allClientCoaches.length == 0 || coachMatches.coaches.length == 0)
            return;
        let selectableCoachesList = [];
        for(let i = 0; i < allClientCoaches.length; i++) {
            let coachInCoachMatches = false;
            for(let c = 0; c < coachMatches.coaches.length; c++) {
                if(allClientCoaches[i].coach.accountId == coachMatches.coaches[c].coachId) {
                    coachInCoachMatches = true;
                    break;
                }
            }
            if(!coachInCoachMatches) {
                selectableCoachesList.push(allClientCoaches[i]);
            }
        }
        setSelectableClientCoaches(selectableCoachesList);
    }, [allClientCoaches, coachMatches]);

    function loadCoachMatchReviewRequest() {
        fetch("/api/v1/account/coach-match-approval-request/" + requestId).then((res) => {
            return res.json();
        }).then((coachMatchResponse) => {
            for(let i = 0; i < coachMatchResponse.coaches.length; i++) {
                coachMatchResponse.coaches[i].ethnicity = ETHNICITIES[coachMatchResponse.coaches[i].ethnicity];
                coachMatchResponse.coaches[i].gender = GENDERS[coachMatchResponse.coaches[i].gender];
                coachMatchResponse.coaches[i].matchScore = coachMatchResponse.matchScores[i];
            }
            setCoachMatches(sortCoachMatches(coachMatchResponse));
            setlowestMatchScore(coachMatchResponse.lowestSetMatchScore);
            setHighestMatchScore(coachMatchResponse.highestSetMatchScore);
        });
    }

    function approve() {
        fetch("/api/v1/account/approve-coach-match-approval-request/" + requestId, {
            method: "POST"
        }).then((res) => {
            if(res.status == 200)
                window.close();
        });
    }

    function selectCoachMatch(coachId) {
        fetch("/api/v1/account/select-coach-match/" + coachId + "/" + requestId, {
            method: "POST"
        }).then((res) => {
            if(res.status == 200)
                window.close();
        });
    }

    function sortCoachMatches(coachMatches) {
        coachMatches.coaches.sort((a, b) => {
            return a.matchScore - b.matchScore;
        });
        return coachMatches;
    }

    function deleteCoachMatch(coachId) {
        fetch("/api/v1/account/coach-match/" + requestId + "/" + coachId, {
            method: "DELETE"
        }).then((res) => {
            if(res.status == 200) {
                let updatedCoachMatches = {coaches: [], matchScores: []};
                for(let i = 0; i < coachMatches.coaches.length; i++) {
                    if(coachMatches.coaches[i].coachId != coachId) {
                        updatedCoachMatches.coaches.push(coachMatches.coaches[i]);
                        updatedCoachMatches.matchScores.push(coachMatches.matchScores[i]);
                    }
                }
                setCoachMatches(sortCoachMatches(updatedCoachMatches));
            }
        });
    }

    function generateCoachMatches() {
        fetch("/api/v1/account/generate-coach-matches/" + requestId, {
            method: "POST"
        }).then((res) => {
            loadCoachMatchReviewRequest();
        });
    }

    function addCoachMatch(coach) {
        let coachMatch = {
            coachMatchId: -1,
            participantId: requestId,
            coachId: coach.coachAccount.accountId,
            matchScore: lowestMatchScore,
            lowestSetMatchScore: lowestMatchScore,
            highestSetMatchScore: highestMatchScore
        };
        fetch("/api/v1/account/coach-match", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(coachMatch)
        }).then((res) => {
            if(res.status != 200)
                return;
            loadCoachMatchReviewRequest();
        });
    }

    function normalize(score) {
        return (score / highestMatchScore).toFixed(2) * 100;
    }

    if(coachMatches.coaches.length == 0) {
        return(<CircularProgress />);
    } else {
        return (
            <div className="PageBodyWide">
                <Box sx={{ width: "95%", alignItems: "center", justifyContent: "center", mt:"1.5%" }}>
                    <Stack spacing={2} direction="column" justifyContent="center" alignItems="center">
                        <Typography variant="h2" component="h1">Coach Matches</Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="left">Email</StyledTableCell>
                                        <StyledTableCell align="left">First Name</StyledTableCell>
                                        <StyledTableCell align="left">Last Name</StyledTableCell>
                                        <StyledTableCell align="right">Ethnicity Preference</StyledTableCell>
                                        <StyledTableCell align="right">Gender Preference</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell align="left">{userProfile.emailAddress}</StyledTableCell>
                                        <StyledTableCell align="left">{userProfile.firstName}</StyledTableCell>
                                        <StyledTableCell align="left">{userProfile.lastName}</StyledTableCell>
                                        <StyledTableCell align="right">{ethnicityPreference}</StyledTableCell>
                                        <StyledTableCell align="right">{genderPreference}</StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 800}} size="small">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell>Coach Name</StyledTableCell>
                                        <StyledTableCell>Ethnicity</StyledTableCell>
                                        <StyledTableCell>Gender</StyledTableCell>
                                        <StyledTableCell>Raw Match Score</StyledTableCell>
                                        <StyledTableCell>Normalized Match Score</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {coachMatches.coaches.map((coach, index) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell><Button size="small" color="secondary" onClick={() => selectCoachMatch(coach.coachId)}>Select</Button></StyledTableCell>
                                                <StyledTableCell><Button disabled={!participantChoosesCoach || coachMatches.coaches.length==1} size="small" color="error" onClick={() => deleteCoachMatch(coach.coachId)}>Remove</Button></StyledTableCell>
                                                <StyledTableCell>{coach.firstName}  {coach.lastName}</StyledTableCell>
                                                <StyledTableCell>{coach.ethnicity == ethnicityPreference ? "*" : ""}{coach.ethnicity}</StyledTableCell>
                                                <StyledTableCell>{coach.gender == genderPreference ? "*" : ""}{coach.gender}</StyledTableCell>
                                                <StyledTableCell>{coachMatches.matchScores[index].toFixed(2)}</StyledTableCell>
                                                <StyledTableCell>{normalize(coachMatches.matchScores[index]).toFixed(2)}%</StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                    <StyledTableRow key="new">
                                        <StyledTableCell align="center" colSpan={7}>
                                            <Stack direction="row">
                                                <Button onClick={() => generateCoachMatches()}>Regenerate Coach Matches</Button>
                                                <Button onClick={() => setAddCoachModalOpen(true)}>Add Coach(es)</Button>
                                            </Stack>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Modal open={addCoachModalOpen} onClose={() => setAddCoachModalOpen(false)}>
                            <Box sx={{ width: "100%", maxHeight: "600px", display: "flex", flexDirection: "column", alignItems: "flex-start", overflow: "hidden" }}>
                                <TableContainer sx={{ overflowx: "initial" }} component={Paper}>
                                    <Table sx={{ minWidth: 800 }} size="small">
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell>First Name</StyledTableCell>
                                                <StyledTableCell>Last Name</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectableClientCoaches.map((coach, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell><Button onClick={() => addCoachMatch(coach)}>Add</Button></StyledTableCell>
                                                        <StyledTableCell>{coach.coachAccount.firstName}</StyledTableCell>
                                                        <StyledTableCell>{coach.coachAccount.lastName}</StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Modal>
                        { participantChoosesCoach && <Button onClick={() => (approve())}>Approve Coach Matches</Button> }
                        { !participantChoosesCoach && 
                            <Tooltip title="Only available when participants may choose their coach" placement="right-end">
                                <span>
                                    <Button disabled>Approve Coach Matches</Button>
                                </span>
                            </Tooltip> 
                        }
                    </Stack>
                </Box>
            </div>
        );
    }
}