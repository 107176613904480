import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Input, InputAdornment,
    Tooltip, IconButton } from '@mui/material';
import { Button, Textarea } from '@mui/joy';

import InfoIcon from '@mui/icons-material/Info';


function RegisterParticipant({ registrationData, registrationCode }) {
    let [customRegistrationDataLoadedState, setCustomRegistrationDataLoadedState] = useState("NOT_LOADED");
    let [customRegistrationQuestions, setCustomRegistrationQuestions] = useState(null);
    let [customRegistrationTextQuestions] = useState({});
    let [registrationRequestInProgress, setRegistrationRequestInProgress] = useState(false);
    let [participantChoosesGender, setParticipantChoosesGender] = useState(false);
    let [participantChoosesEthnicity, setParticipantChoosesEthnicity] = useState(false);
    let [firstName, setFirstName] = useState(registrationData.firstName);
    let [lastName, setLastName] = useState(registrationData.lastName);
    let [preferredName, setPreferredName] = useState("");
    let [emailAddress, setEmailAddress] = useState(registrationData.email);
    let [personalEmailAddress, setPersonalEmailAddress] = useState("");
    let [emailTypePreference, setEmailTypePreference] = useState("BUSINESS");
    let [clientName, setClientName] = useState(registrationData.clientName);
    let [phoneNumber, setPhoneNumber] = useState("");
    let [phoneType, setPhoneType] = useState("OFFICE");
    let [alternatePhoneNumber, setAlternatePhoneNumber] = useState("");
    let [alternatePhoneType, setAlternatePhoneType] = useState("MOBILE");
    let [phoneTypePreference, setPhoneTypePreference] = useState("BUSINESS");
    let [password, setPassword] = useState("");
    let [snackbarAlerts, setSnackbarAlerts] = useState([]);

    let [preferredNameError, setPreferredNameError] = useState(false);
    let [passwordError, setPasswordError] = useState(false);
    let [phoneNumberError, setPhoneNumberError] = useState(false);
    let [alternatePhoneNumberError, setAlternatePhoneNumberError] = useState(false);
    let [personalEmailAddressError, setPersonalEmailAddressError] = useState(false);

    let [ethnicity, setEthnicity] = useState(null);
    let [hispanicOrLatino, setHispanicOrLatino] = useState(null);
    let [gender, setGender] = useState(null);
    let [acceptedGrades, setAcceptedGrades] = useState([]);
    let [grade, setGrade] = useState(0);

    useEffect(() => {
        if(customRegistrationDataLoadedState === "NOT_LOADED") {
            setCustomRegistrationDataLoadedState("LOADING");
            const regConfigPromise = fetch("/api/v1/account/registration-configuration/" + registrationData.clientId, {
                method: "GET",
                cache: "no-cache",
                mode: "cors"
            }).then((res) => {
                if(res.status == 200) {
                    return res.json();
                } else if(res.status == 400) {
                    //setErrorMessage("Account not found.");
                } else if(res.status == 404) {
                    //setErrorMessage("Account not found.");
                } else if(res.status == 500) {
                    //setErrorMessage("An error occurred.");
                }
                return null;
            }).then((res) => {
                setAcceptedGrades(res.acceptedGrades);
                if(res.acceptedGrades.length > 0)
                    setGrade(res.acceptedGrades[0].gradeId);
                setParticipantChoosesEthnicity(res.customRegistrationConfiguration.userSelectsEthnicity);
                setParticipantChoosesGender(res.customRegistrationConfiguration.userSelectsGender);
                if(res.customRegistrationConfiguration.questionnaire != null)
                    loadCustomRegistration(res.customRegistrationConfiguration.questionnaire);
                if(res == null) 
                    return false;
                return true;
            });

            // TODO: Add a universal check function, use HTTP res code, if unauthenticated then logout 
            Promise.all([regConfigPromise]).then((successStates) => {
                if(successStates.every(promise => promise == true)) {
                    setCustomRegistrationDataLoadedState("LOADED");
                } else {
                    setCustomRegistrationDataLoadedState("FAILED");
                }
            });
        }
    }, []);

    function validInputData() {
        // First name validations
        var alerts = [];
        var validInput = true;

        let pea = false;
        if(personalEmailAddress.length < 3) {
            validInput = false;
            pea = true;
            alerts.push({severity: "error", message: "Personal email address may not be empty"});
        }
        setPersonalEmailAddressError(pea);
    
        let pne = false;
        if(preferredName.length > 20) {
          validInput = false;
          pne = true;
          alerts.push({severity:"error", message:"Preferred name must be 20 characters or less"});
        } else if(preferredName.length > 0 && !/^[A-Za-z\-]+$/.test(preferredName)) {
          validInput = false;
          pne = true;
          alerts.push({severity:"error", message:"Preferred name must contain only letters or '-'"});
        }
        setPreferredNameError(pne);
    
        let pe = false;
        if(phoneNumber == "" || phoneNumber == null) {
          validInput = false;
          pe = true;
          alerts.push({severity:"error", message:"Missing phone number"});
        } else if(phoneNumber.length < 10) {
          validInput = false;
          pe = true;
          alerts.push({severity:"error", message:"Phone number is too short"});
        } else if(!/^[0-9]+$/.test(phoneNumber)) {
          validInput = false;
          pe = true;
          alerts.push({severity:"error", message:"Phone number must only contain numbers"});
        }
        setPhoneNumberError(pe);

        let ape = false;
        if(alternatePhoneNumber == "" || alternatePhoneNumber == null) {
            validInput = false;
            ape = true;
            alerts.push({severity:"error", message:"Missing personal phone number"});
        } else if(alternatePhoneNumber.length < 10) {
            validInput = false;
            ape = true;
            alerts.push({severity:"error", message:"Personal phone number is too short"});
        } else if(!/^[0-9]+$/.test(alternatePhoneNumber)) {
            validInput = false;
            ape = true;
            alerts.push({severity:"error", message:"Personal phone number must only contain numbers"});
        }
        setAlternatePhoneNumberError(ape);
        
        let pwe = false;
        if(password == "" || password == null) {
          validInput = false;
          pwe = true;
          alerts.push({severity:"error", message:"Password cannot be empty"});
        } else if(password.length < 10) {
          validInput = false;
          pwe = true;
          alerts.push({severity:"error", message:"Password must be at least 10 characters long"});
        } else if(!/^.*[a-z].*$/.test(password) || !/^.*[A-Z].*$/.test(password) || !/^.*[0-9].*$/.test(password)) {
          validInput = false;
          pwe = true;
          alerts.push({severity:"error", message:"Password must contain at least one capital letter, one lowercase letter, one special character (!, $, @, %, &, or ^), and one number"});
        } else if(!["!", "$", "@", "%", "&", "^"].some(c => password.includes(c))) {
          validInput = false;
          pwe = true;
          alerts.push({severity:"error", message:"Password must contain at least one one special character (!, $, @, %, &, or ^), and one number"});
        } // Add a check for unallowed characters
        setPasswordError(pwe);
    
        setSnackbarAlerts(alerts);
        if(alerts.length > 0) {
          window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
           });
        }
        return validInput;
      }

    function loadCustomRegistration(surveyId) {
        fetch("/api/v1/account/survey/" + surveyId).then((res) => {
            return res.json();
        }).then((surveyQuestionsResponse) => {
            for(let i = 0; i < surveyQuestionsResponse.questions.length; i++) {
                surveyQuestionsResponse.questions[i].errors = [];
              if(surveyQuestionsResponse.questions[i].question.questionType == "MULTIPLE_CHOICE") {
                surveyQuestionsResponse.questions[i].question["currentChoiceCount"] = 0;
                for(let j = 0; j < surveyQuestionsResponse.questions[i].answers.length; j++) {
                    surveyQuestionsResponse.questions[i].answers[j]["value"] = 0;
                }
              } else if(surveyQuestionsResponse.questions[i].question.questionType == "SINGLE_CHOICE") {
                  for(let j = 0; j < surveyQuestionsResponse.questions[i].answers.length; j++) {
                    surveyQuestionsResponse.questions[i].answers[j]["value"] = 0;
                  }
              } else if(surveyQuestionsResponse.questions[i].question.questionType == "TEXT") {
                customRegistrationTextQuestions[i] = "";
              } else {
                console.log("Unhandled survey question type: ", surveyQuestionsResponse.questions[i].question.questionType);
              }
            }
            setCustomRegistrationQuestions(surveyQuestionsResponse);
        });
    }

    function multipleChoiceSelection(answerIndex, questionIndex) {
        if(customRegistrationQuestions.questions[questionIndex].answers[answerIndex].value == 0) {
            if(customRegistrationQuestions.questions[questionIndex].question.currentChoiceCount < customRegistrationQuestions.questions[questionIndex].question.maxChoiceCount) {
            let updatedCustomRegistrationQuestions = JSON.parse(JSON.stringify(customRegistrationQuestions));
            updatedCustomRegistrationQuestions.questions[questionIndex].answers[answerIndex].value = 1;
            updatedCustomRegistrationQuestions.questions[questionIndex].question.currentChoiceCount += 1;
            setCustomRegistrationQuestions(updatedCustomRegistrationQuestions);
            }
        } else {
            let updatedCustomRegistrationQuestions = JSON.parse(JSON.stringify(customRegistrationQuestions));
            updatedCustomRegistrationQuestions.questions[questionIndex].answers[answerIndex].value = 0;
            updatedCustomRegistrationQuestions.questions[questionIndex].question.currentChoiceCount -= 1;
            setCustomRegistrationQuestions(updatedCustomRegistrationQuestions);
        }
    }

    function singleChoiceSelection(answerIndex, questionIndex) {
        let updatedCustomRegistrationQuestions = JSON.parse(JSON.stringify(customRegistrationQuestions));
        for(let j = 0; j < updatedCustomRegistrationQuestions.questions[questionIndex].answers.length; j++) {
            updatedCustomRegistrationQuestions.questions[questionIndex].answers[j]["value"] = 0;
        }
        updatedCustomRegistrationQuestions.questions[questionIndex].answers[answerIndex]["value"] = 1;
        setCustomRegistrationQuestions(updatedCustomRegistrationQuestions);
    }

    function updateTextQuestionValue(questionIndex, value) {
        customRegistrationTextQuestions[questionIndex] = value;
    }

    function validateCustomSurveyResponses() {
        let updatedCustomRegistrationQuestions = {...customRegistrationQuestions};
        let hasErrors = false;
        for(let q = 0; q < updatedCustomRegistrationQuestions.questions.length; q++) {
            let question = updatedCustomRegistrationQuestions.questions[q];
            question.errors = [];
            if(question.question.questionType == "MULTIPLE_CHOICE") {
                if(question.question.currentChoiceCount < question.question.minChoiceCount) {
                    hasErrors = true;
                    updatedCustomRegistrationQuestions.questions[q].errors.push("You must select at least " + question.question.minChoiceCount + " items.");
                }
            } else if(question.question.questionType == "SINGLE_CHOICE") {
                let answerCount = 0;
                for(let a = 0; a < question.answers.length; a++)
                    if(question.answers[a].value == 1)
                        answerCount += 1;
                if(answerCount != 1) {
                    hasErrors = true;
                    updatedCustomRegistrationQuestions.questions[q].errors.push("You must select 1 item.");
                }
            } else if(question.question.questionType == "TEXT") {
                if(question.question.required == true && customRegistrationTextQuestions[q].length == 0) {
                    updatedCustomRegistrationQuestions.questions[q].errors.push("An answer is required.");
                }
            }
        }
        setCustomRegistrationQuestions(updatedCustomRegistrationQuestions);
        console.log("ValidateResponses: ", hasErrors, "; ", updatedCustomRegistrationQuestions);
        return !hasErrors;
    }

    function CustomSurvey({survey}) {
        if(survey == null) {
            return (
                <CircularProgress />
            );
        } else {
            return (
                <Box>
                    <Stack direction="column" spacing={2.0} sx={{ width: "90%" }} alignItems="center" justifyContent="center">
                        { survey.questions.map((userSurveyQuestion, questionIndex) => {
                            let questionHeader = <Typography variant="h6" component="h2">{userSurveyQuestion.question.prompt}</Typography>
                            let questionBody = null;
                            switch(userSurveyQuestion.question.questionType) {
                            case "MULTIPLE_CHOICE":
                                questionBody = 
                                    <React.Fragment>
                                        <Typography variant="caption">Select {userSurveyQuestion.question.minChoiceCount} to {userSurveyQuestion.question.maxChoiceCount} items</Typography>
                                        <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={0.5}>
                                        { userSurveyQuestion.answers.map((answer, answerIndex) => {
                                            if(answer.value == 1) {
                                                return(<Chip label={answer.answerText} onClick={() => {multipleChoiceSelection(answerIndex, questionIndex)}} color="primary" variant="filled" />);
                                            } else {
                                                return(<Chip label={answer.answerText} onClick={() => {multipleChoiceSelection(answerIndex, questionIndex)}} variant="outlined" />);
                                            }
                                        })}
                                        </Stack>
                                    </React.Fragment>
                                break;
                            case "SINGLE_CHOICE":
                                questionBody = 
                                    <FormControl>
                                        <RadioGroup row>
                                            { userSurveyQuestion.answers.map((answer, answerIndex) => {
                                                if(answer.value == 1) {
                                                    return (<FormControlLabel checked={true} value={answerIndex} control={<Radio onChange={() => {singleChoiceSelection(answerIndex, questionIndex)}} />} label={answer.answerText} />);
                                                } else {
                                                    return(<FormControlLabel checked={false} value={answerIndex} control={<Radio onChange={() => {singleChoiceSelection(answerIndex, questionIndex)}} />} label={answer.answerText} />);
                                                }
                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                break;
                            case "TEXT":
                                questionBody = 
                                    <Box sx={{ minWidth: "80%"}} >
                                        <Textarea defaultValue={customRegistrationTextQuestions[questionIndex]} minRows={3} size="lg" variant="outlined" onChange={(event) => { updateTextQuestionValue(questionIndex, event.target.value)}} />
                                    </Box>
                                break;
                            default:
                                console.log("Coaching survey type unknown: ", userSurveyQuestion.question);
                                break;
                            }
                            return (
                            <React.Fragment>
                                {questionHeader}
                                { userSurveyQuestion.errors.map((errorText) => {
                                    return (
                                        <Alert variant="filled" severity="error">
                                            {errorText}
                                        </Alert>
                                    );
                                })}
                                {questionBody}
                            </React.Fragment>
                            );
                        })}
                    </Stack>
                </Box>
            );
        }
    }

    function createPostSurveyRq() {
        let rq = {
            completedSurvey: {
                surveyId: customRegistrationQuestions.surveyId,
                completionTime: new Date().toUTCString()  
            },
            completedSurveyAnswers: []
        };

        for(let i = 0; i < customRegistrationQuestions.questions.length; i++) {
            if(customRegistrationQuestions.questions[i].question.questionType == "MULTIPLE_CHOICE") {
                for(let j = 0; j < customRegistrationQuestions.questions[i].answers.length; j++) {
                    let completedSurveyAnswer = {
                        questionId: customRegistrationQuestions.questions[i].question.questionId,
                        answerId: customRegistrationQuestions.questions[i].answers[j].answerId,
                        answerValue: customRegistrationQuestions.questions[i].answers[j].value
                    };
                    rq.completedSurveyAnswers.push(completedSurveyAnswer);
                }
            } else if(customRegistrationQuestions.questions[i].question.questionType == "SINGLE_CHOICE") {
                for(let j = 0; j < customRegistrationQuestions.questions[i].answers.length; j++) {
                    let completedSurveyAnswer = {
                        questionId: customRegistrationQuestions.questions[i].question.questionId,
                        answerId: customRegistrationQuestions.questions[i].answers[j].answerId,
                        answerValue: customRegistrationQuestions.questions[i].answers[j].value
                    };
                    rq.completedSurveyAnswers.push(completedSurveyAnswer);
                }
            } else if(customRegistrationQuestions.questions[i].question.questionType == "TEXT") {
                let completedSurveyAnswer = {
                    questionId: customRegistrationQuestions.questions[i].question.questionId,
                    answerId: -1,
                    answerTextValue: customRegistrationTextQuestions[i]
                }
                rq.completedSurveyAnswers.push(completedSurveyAnswer);
            }
        }
        return rq;
    }

    function postCustomSurveyRq() {
        let rq = createPostSurveyRq();
        return fetch("/api/v1/account/survey", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(rq)
        });
    }

    function GradeSelector({ acceptedGrades, onChange, value }) {
        if(acceptedGrades.length == 0) {
            return null;
        } else {
            return (
                <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                    <FormControl required variant="standard" sx={{ m:0, minWidth: 120, maxWidth: 200 }}>
                        <InputLabel>Rank/Grade</InputLabel>
                        <Select value={value} label="Rank/Grade" onChange={onChange}>
                            { acceptedGrades.map((grade) =>
                                <MenuItem value={grade.gradeId}>{grade.gradeName}</MenuItem>   
                            )}
                        </Select>
                    </FormControl>
                </Stack>
            );
        }
    }

    let handleRegistrationRequest = () => {
        setRegistrationRequestInProgress(true);
        if(!validInputData()) {
            setRegistrationRequestInProgress(false);
            return;
        }
        if(customRegistrationQuestions != null && !validateCustomSurveyResponses()) {
            setRegistrationRequestInProgress(false);
            return;
        }
        if(gender === "PREFER_NOT_TO_ANSWER")
            gender = null;
        if(ethnicity === "PREFER_NOT_TO_ANSWER")
            ethnicity = null;
        if(hispanicOrLatino === "PREFER_NOT_TO_ANSWER")
            hispanicOrLatino = null;

        let account = {"phoneCommunicationPreference": phoneTypePreference, "emailCommunicationPreference": emailTypePreference, "personalEmail": personalEmailAddress, "alternatePhoneNumber": alternatePhoneNumber, "alternatePhoneType": alternatePhoneType, "phoneNumber": phoneNumber, "preferredPhoneType": phoneType, "preferredName": preferredName, "timezone": "US_EAST"};
        let participant = {"gender": gender, "ethnicity": ethnicity, "hispanticOrLatino": hispanicOrLatino, "grade": grade};
        let registrationRequest = {"account": account, "participant": participant, "password": password};

        fetch("/api/v1/account/register/" + registrationCode, {
            method: "POST",
            cache: "no-cache",
            mode: "cors",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(registrationRequest)
          }).then((res) => {
            if(res.status == 200) {
                return res.json();
            } else {
                console.log("error completing registration.");
                setRegistrationRequestInProgress(false);
            }
            return null;
          }).then((res) => {
            if(res != null) {
                let sessionData = JSON.stringify(res);
                localStorage["sessionData"] = sessionData;
                if(customRegistrationQuestions != null) {
                    postCustomSurveyRq().then((res) => {
                        window.location.href = "/";
                    });
                } else {
                    window.location.href = "/";
                }
            }
          });
    }

    if(customRegistrationDataLoadedState === "LOADED") {
        return (
            <Stack direction="column" spacing={1.5} alignItems="center" justifyContent="center">
                { snackbarAlerts.map(alert => <Alert severity={ alert.severity }>{ alert.message }</Alert>) }
                <Typography variant="h4" component="h1">Register for leadership coaching with <Box component="span" fontWeight='fontWeightBold' >{ clientName }</Box></Typography>
                <Stack sx={{width: "100%"}} direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                    <TextField fullwidth disabled id="firstNameInput" label="First Name" variant="standard" defaultValue={firstName}/>
                    <TextField fullwidth disabled id="lastNameInput" label="Last Name" variant="standard" defaultValue={lastName}/>
                </Stack>
                <Stack sx={{width: "100%"}} direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                    <TextField fullwidth error={preferredNameError} id="preferredNameInput" label="Preferred Name" variant="standard" onChange={(event) => { setPreferredName(event.target.value)}} />
                </Stack>
                <Stack sx={{width: "100%"}} direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                    <TextField fullwidth disabled id="emailAddressInput" label="Work Email Address" variant="standard" defaultValue={emailAddress}/>
                </Stack>
                <Stack sx={{width: "100%"}} direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                    <TextField error={personalEmailAddressError} fullwidth id="personalEmailAddressInput" label="Personal Email Address" variant="standard" value={personalEmailAddress} onChange={(e) => setPersonalEmailAddress(e.target.value)}/>
                </Stack>
                <Stack sx={{width: "100%"}} direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                    <FormControl required variant="standard" sx={{ m:0, minWidth: 200 }}>
                        <InputLabel id="emailPreferenceLabel">Email Communication Preference</InputLabel>
                        <Select labelId="emailPreferenceLabel" id="emailPreferenceInput" value={emailTypePreference} label="Email Preference" onChange={(event) => {setEmailTypePreference(event.target.value)}}>
                            <MenuItem value={"BUSINESS"}>Work</MenuItem>
                            <MenuItem value={"PERSONAL"}>Personal</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack sx={{width: "100%"}} direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={0.1}>
                    <TextField fullwidth error={passwordError} required  id="password-input" label="Password" type="password" variant="standard" onChange={(event) => { setPassword(event.target.value)}}/>
                    <Tooltip title="Password must be 10 characters, and contain at least 1 number, special character (!, $, @, %, &, or ^), capital, and lowercase letter">
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
                <Stack sx={{width: "100%"}} direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                    <TextField error={phoneNumberError} required id="phoneNumberInput" label="Work Phone Number" type="number" variant="standard" onChange={(event) => { setPhoneNumber(event.target.value)}}/>
                    <FormControl required variant="standard" sx={{ m:0, minWidth: 200 }}>
                        <InputLabel>Work Phone Type</InputLabel>
                        <Select labelId="phoneTypeLabel" id="phoneTypeInput" value={phoneType} label="Phone Type" onChange={(event) => {setPhoneType(event.target.value)}}>
                            <MenuItem value={"MOBILE"}>Mobile</MenuItem>
                            <MenuItem value={"HOME"}>Home</MenuItem>
                            <MenuItem value={"OFFICE"}>Office</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack sx={{width: "100%"}} direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                    <TextField error={alternatePhoneNumberError} required id="alternatePhoneNumberInput" label="Personal Phone Number" type="number" variant="standard" onChange={(event) => { setAlternatePhoneNumber(event.target.value)}}/>
                    <FormControl required variant="standard" sx={{ m:0, minWidth: 200 }}>
                        <InputLabel>Personal Phone Type</InputLabel>
                        <Select labelId="alternatePhoneTypeLabel" id="alternatePhoneTypeInput" value={alternatePhoneType} label="Phone Type" onChange={(event) => {setAlternatePhoneType(event.target.value)}}>
                            <MenuItem value={"MOBILE"}>Mobile</MenuItem>
                            <MenuItem value={"HOME"}>Home</MenuItem>
                            <MenuItem value={"OFFICE"}>Office</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack sx={{width: "100%"}} direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                    <FormControl required variant="standard" sx={{ m:0, minWidth: 200 }}>
                        <InputLabel id="phonePreferenceLabel">Phone Communication Preference</InputLabel>
                        <Select labelId="phonePreferenceLabel" id="phonePreferenceInput" value={phoneTypePreference} label="Phone Preference" onChange={(event) => {setPhoneTypePreference(event.target.value)}}>
                            <MenuItem value={"BUSINESS"}>Work</MenuItem>
                            <MenuItem value={"PERSONAL"}>Personal</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <GradeSelector acceptedGrades={acceptedGrades} value={grade} onChange={(event) => { setGrade(event.target.value) }} />
                { participantChoosesGender && <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                    <FormControl>
                        <FormLabel>Gender</FormLabel>
                        <RadioGroup row id="genderSelectionRadioGroup">
                            <FormControlLabel value="MALE" control={<Radio onChange={() => {setGender("MALE")}} />} label="Male" />
                            <FormControlLabel value="FEMALE" control={<Radio onChange={() => {setGender("FEMALE")}} />} label="Female" />
                            <FormControlLabel value="NONBINARY" control={<Radio onChange={() => {setGender("NONBINARY")}} />} label="Nonbinary" />
                            <FormControlLabel value="OTHER" control={<Radio onChange={() => {setGender("OTHER")}} />} label="Other" />
                            <FormControlLabel value="PREFER_NOT_TO_ANSWER" control={<Radio onChange={() => {setGender(null)}} />} label="Prefer not to answer" />
                        </RadioGroup>
                    </FormControl>
                </Stack> }
                { participantChoosesEthnicity && <React.Fragment>
                    <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                        <FormControl>
                            <FormLabel>Ethnicity</FormLabel>
                            <RadioGroup row id="ethnicitySelectionRadioGroup">
                                <FormControlLabel value="AMERICAN_INDIAN_OR_ALASKA_NATIVE" control={<Radio onChange={() => {setEthnicity("AMERICAN_INDIAN_OR_ALASKA_NATIVE")}} />} label="American Indian or Alaskan Native" />
                                <FormControlLabel value="ASIAN" control={<Radio onChange={() => {setEthnicity("ASIAN")}} />} label="Asian" />
                                <FormControlLabel value="BLACK_OR_AFRICAN_AMERICAN" control={<Radio onChange={() => {setEthnicity("BLACK_OR_AFRICAN_AMERICAN")}} />} label="Black or African American" />
                                <FormControlLabel value="NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER" control={<Radio onChange={() => {setEthnicity("NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER")}} />} label="Native Hawaiian or Other Pacific Islander" />
                                <FormControlLabel value="MIDDLE_EASTERN_OR_NORTH_AFRICAN" control={<Radio onChange={() => {setEthnicity("MIDDLE_EASTERN_OR_NORTH_AFRICAN")}} />} label="Middle Eastern or North African" />
                                <FormControlLabel value="WHITE" control={<Radio onChange={() => {setEthnicity("WHITE")}} />} label="White" />
                                <FormControlLabel value="PREFER_NOT_TO_ANSWER" control={<Radio onChange={() => {setEthnicity("PREFER_NOT_TO_ANSWER")}} />} label="Prefer not to answer" />
                            </RadioGroup>
                        </FormControl>
                    </Stack> 
                    <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                        <FormControl>
                            <FormLabel>Hispanic or Latino</FormLabel>
                            <RadioGroup row id="hispanicOrLatinoSelectionRadioGroup">
                                <FormControlLabel value="HISPANIC_OR_LATINO" control={<Radio onChange={() => {setHispanicOrLatino("HISPANIC_OR_LATINO")}} />} label="Hispanic or Latino" />
                                <FormControlLabel value="NOT_HISPANIC_OR_LATINO" control={<Radio onChange={() => {setHispanicOrLatino("NOT_HISPANIC_OR_LATINO")}} />} label="Not Hispanic or Latino" />
                                <FormControlLabel value="PREFER_NOT_TO_ANSWER" control={<Radio onChange={() => {setHispanicOrLatino("PREFER_NOT_TO_ANSWER")}} />} label="Prefer not to asnwer" />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                </React.Fragment>}
                <CustomSurvey survey={customRegistrationQuestions} />
                <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                    <Button loading={ registrationRequestInProgress } loadingPosition="start" variant="solid" onClick={ handleRegistrationRequest }>Create Account</Button>
                </Stack>
            </Stack> 
        );
    } else {
        return (<CircularProgress />);
    }
}

function RegisterAdministrator({ registrationData, registrationCode }) {
    let [firstName, setFirstName] = useState(registrationData.firstName);
    let [lastName, setLastName] = useState(registrationData.lastName);
    let [preferredName, setPreferredName] = useState("");
    let [emailAddress, setEmailAddress] = useState(registrationData.email);
    let [clientName, setClientName] = useState(registrationData.clientName);
    let [phoneNumber, setPhoneNumber] = useState("");
    let [phoneType, setPhoneType] = useState("MOBILE");
    let [password, setPassword] = useState("");

    let [requestInProgress, setRequestInProgress] = useState(false);

    function submitRegistrationRequest() {
        let rq = {accountType: "ADMIN", password: password, account: {
            accountEmail: emailAddress, 
            firstName: firstName, 
            lastName: lastName, 
            preferredName: preferredName,
            phoneNumber: phoneNumber,
            preferredPhoneType: phoneType
        }};
        setRequestInProgress(true);
        fetch("/api/v1/account/register/" + registrationCode, {
            method: "POST",
            cache: "no-cache",
            mode: "cors",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(rq)
          }).then((res) => {
            if(res.status == 200) {
                return res.json();
            } else {
                setRequestInProgress(false);
                return null;
            }
          }).then((res) => {
            if(res != null) {
                localStorage["sessionData"] = JSON.stringify(res);
                window.location.href = "/";
            }
          });
    }

    return (
        <Stack direction="column">
            <Typography>Administrator Registration</Typography>
            <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                <TextField disabled fullwidth id="firstNameInput" label="First Name" variant="standard" defaultValue={firstName}/>
                <TextField disabled fullwidth id="lastNameInput" label="Last Name" variant="standard" defaultValue={lastName}/>
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                <TextField fullwidth id="preferredNameInput" label="Preferred Name" variant="standard" onChange={(event) => { setPreferredName(event.target.value)}} />
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                <TextField disabled fullwidth id="emailAddressInput" label="Email Address" variant="standard" defaultValue={emailAddress}/>
                <TextField required fullwidth id="password-input" label="Password" type="password" variant="standard" onChange={(event) => { setPassword(event.target.value)}}/>
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                <TextField required id="phoneNumberInput" label="Phone Number" type="number" variant="standard" onChange={(event) => { setPhoneNumber(event.target.value)}}/>
                <FormControl required variant="standard" sx={{ m:0, minWidth: 120 }}>
                <Select labelId="phoneTypeLabel" id="phoneTypeInput" value={phoneType} label="Phone Type" onChange={(event) => {setPhoneType(event.target.value)}}>
                    <MenuItem value={"MOBILE"}>Mobile</MenuItem>
                    <MenuItem value={"HOME"}>Home</MenuItem>
                    <MenuItem value={"OFFICE"}>Office</MenuItem>
                </Select>
                </FormControl>
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1} sx={{ mt: "10px" }}>
                <Button onClick={() => submitRegistrationRequest()}>Create Account</Button>
            </Stack>
        </Stack>
    );
}

export default function Register() {
    let [searchParams, setSearchParams] = useSearchParams();
    let [errorMessage, setErrorMessage] = useState("");
    let [registrationCode, setRegistrationCode] = useState(null);
    let [registrationDataLoadingState, setRegistrationDataLoadingState] = useState("NOT_LOADED");
    let [registrationData, setRegistrationData] = useState(false);

    useEffect(() => {
        if(registrationDataLoadingState == "NOT_LOADED") {
            console.log("Loading registration data");
            setRegistrationDataLoadingState("LOADING");
            let regCode = searchParams.get("key");
            setRegistrationCode(regCode);
            fetch("/api/v1/account/register/" + regCode, {
                method: "GET",
                cache: "no-cache",
                mode: "cors"
            }).then((res) => {
                if(res.status == 200) {
                    return res.json();
                } else if(res.status == 400) {
                    setErrorMessage("Account not found.");
                } else if(res.status == 404) {
                    setErrorMessage("Account not found.");
                } else if(res.status == 500) {
                    setErrorMessage("The registration link you entered is not valid.");
                }
                return null;
            }).then((res) => {
                if(res == null) {
                    setRegistrationDataLoadingState("FAILED");
                } else {
                    setRegistrationData(res);
                    setRegistrationDataLoadingState("LOADED");
                }
            });
        }
    }, [searchParams, setSearchParams,
        registrationDataLoadingState, setRegistrationDataLoadingState,
        registrationData, setRegistrationData]);

    if(registrationDataLoadingState == "NOT_LOADED" || registrationDataLoadingState == "LOADING") {
        return (<CircularProgress />);
    } else if(registrationDataLoadingState == "LOADED") {
        if(registrationData.accountType == "PARTICIPANT") {
            return (<RegisterParticipant registrationData={registrationData} registrationCode={registrationCode} />);
        } else if (registrationData.accountType == "ADMIN") {
            return (<RegisterAdministrator registrationData={registrationData} registrationCode={registrationCode} />);
        }
    } else {
        return (
            <div id="PageBody">
                <Stack direction="column" spacing={5} alignItems="center" justifyContent="center">
                    <Typography>Error: { errorMessage }</Typography>
                    <Box sx={{width: "150px"}}><Button onClick={() => window.location.href = "/"}>Go to login page</Button></Box>
                </Stack>
            </div>
        );
    }
}