import { Routes, Route } from "react-router-dom";
import AdminDashboard from "./AdminDashboard";
import Client from "./Client";
import ClientAnalytics from "./ClientAnalytics";
import ContractSetup from "./ContractSetup";
import CoachMatchApprovalRequest from "./pages/CoachMatchApprovalRequest";
import OrganizationAnalytics from "./pages/OrganizationAnalytics";
import CoachingHoursReport from "./pages/CoachingHoursReport";
import CoachHoursReport from "./pages/CoachHoursReport";
import NoShowsReport from "./pages/NoShowsReport";
import ServiceRequests from "./pages/ServiceRequests";
import ServiceRequest from "../../shared/ServiceRequest";
import RegistrationSurveyEditor from "./pages/RegistrationSurveyEditor";


export default function AdminRouter() {
  return (
    <Routes>
      <Route path="/" element={null}>
        <Route index element={<AdminDashboard />} />
        <Route path="analytics" element={<OrganizationAnalytics />} />
        <Route path="report/coaching-hours-progress-report" element={<CoachingHoursReport />} />
        <Route path="report/coach-hours-report" element={<CoachHoursReport />} />
        <Route path="report/no-show-report" element={<NoShowsReport />} />
        <Route path="account" element={<AdminDashboard />} />
        <Route path="client/:clientId" element={<Client />} />
        <Route path="client/:clientId/analytics" element={<ClientAnalytics />} />
        <Route path="client/:clientId/registration-survey" element={<RegistrationSurveyEditor />} />
        <Route path="contract-setup/:clientId" element={<ContractSetup />} />
        <Route path="coach-match-approval-request/:requestId/:clientId" element={<CoachMatchApprovalRequest />} />
        <Route path="service-requests" element={<ServiceRequests />} />
        <Route path="service-requests/:serviceRequestId" element={<ServiceRequest />} />
      </Route>
    </Routes>
  );
}