import React, { useState, useContext, Fragment, forwardRef } from 'react';
import { SessionContext } from 'Contexts';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
  Chip, Stack, Paper, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
  Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Dialog, DialogActions, 
  Button, List, ListItemButton, ListItemText, ListSubheader } from '@mui/material';
import { Divider } from '@mui/joy';
import FileViewer from 'pageElements/FileViewer';

const MenuBarButton = styled(Button)(({ theme }) => ({
    color: "white",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#6c89b8"
    }
}));

const DOCUMENTS = [
    {name: 'Coach Dashboard', key: 'Coach Dashboard Help.pdf', access: ['ADMIN', 'COACH']},
    {name: 'Scheduling A Coaching Session', key: 'Schedule a Session Help.pdf', access: ['ADMIN', 'PARTICIPANT']}
];


export default function UserDocumentationButton() {
    const sessionState = useContext(SessionContext);

    const [documentationPanelOpen, setDocumentationPanelOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    return (
        <React.Fragment>
            <Modal variant='soft' open={documentationPanelOpen} onClose={() => setDocumentationPanelOpen(false)}>
                <ModalDialog layout="fullscreen">
                    <ModalClose />
                    <Stack direction='row' sx={{ width: '100%', height: '100%'}}>
                        <Stack direction='column' sx={{ width: '30%', height: '100%'}} alignItems='center'>
                            <List sx={{ width: '100%', height: '100%'}} subheader={
                              <ListSubheader component="div" id="nested-list-subheader">
                                User Guides
                              </ListSubheader>
                            }>
                                { sessionState != null && DOCUMENTS.filter((doc) => {
                                    return doc.access.includes(sessionState.accountType)
                                }).map((doc) => {
                                    return (
                                        <ListItemButton>
                                            <ListItemText primary={doc.name} onClick={() => setSelectedFile(doc)}/>
                                        </ListItemButton>
                                    );
                                })}
                            </List>
                        </Stack>
                        <Divider orientation="vertical" />
                        <Stack direction='column' sx={{ width: '70%', height: '100%'}}>
                            {selectedFile != null && 
                                <FileViewer title={selectedFile.name} fileKey={selectedFile.key} />
                            }
                        </Stack>
                    </Stack>
                </ModalDialog>
            </Modal>
            <MenuBarButton onClick={(e) => setDocumentationPanelOpen(true)}>Help & FAQ</MenuBarButton>
        </React.Fragment>
    )
}