import React, { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { SessionContext } from 'Contexts';
import AspectRatio from '@mui/joy/AspectRatio';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider, Checkbox,
    FormGroup, TableContainer, Table, TableRow, TableBody, TableHead, IconButton, ListItemText,
    OutlinedInput, InputAdornment, Input, Collapse, Skeleton, Grid, DialogActions,
    DialogContent, Dialog, DialogTitle } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import utc from 'dayjs/plugin/utc';
import DashboardElement from 'pageElements/DashboardElement';

dayjs.extend(utc);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CoachingSessionCalendar() {
    const sessionState = useContext(SessionContext);
    const [coachingSessions, setCoachingSessions] = useState(null);
    const [cancelSessionDialogOpen, setCancelSessionDialogOpen] = useState(false);
    const [cancelSessionMeetingId, setCancelSessionMeetingId] = useState(null);
    const [cancelSessionReasonSelectValue, setCancelSessionReasonSelectValue] = useState("Work obligations");

    useEffect(() => {
        fetch("/api/v1/account/schedule").then((res) => {
          return res.json();
        }).then((coachingSessionSchedule) => {
          let coachingSessionCalendarItems = [];
          for(let i = 0; i < coachingSessionSchedule.coachingSessions.length; i++) {
            coachingSessionCalendarItems.push(coachingSessionSchedule.coachingSessions[i]);
          }
          setCoachingSessions(coachingSessionCalendarItems);
        });
    }, []);

    function reloadSchedule() {
        fetch("/api/v1/account/schedule").then((res) => {
            return res.json();
          }).then((coachingSessionSchedule) => {
            let coachingSessionCalendarItems = [];
            for(let i = 0; i < coachingSessionSchedule.coachingSessions.length; i++) {
              coachingSessionCalendarItems.push(coachingSessionSchedule.coachingSessions[i]);
            }
            setCoachingSessions(coachingSessionCalendarItems);
          });
    }

    function openCancelMeetingDialog(meetingId) {
        setCancelSessionMeetingId(meetingId);
        setCancelSessionReasonSelectValue("Work obligations");
        setCancelSessionDialogOpen(true);
    }

    function cancelMeeting() {
        let rq = {
            meetingId: cancelSessionMeetingId,
            cancellationReason: cancelSessionReasonSelectValue
        };
        fetch("/api/v1/account/schedule-cancel", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(rq)
        }).then((res) => {
            window.location.reload();
        });
    }

    function joinMeeting(meetingId) {
        window.location.href = "/coachingSession/" + meetingId;
    }

    if(coachingSessions == null) {
        return (
            <DashboardElement elevation={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ width: "100%", maxHeight: "1000px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                        <Stack direction="column" spacing={1.0} sx={{ mt: "10px", mb: "10px"}}>
                            <Typography variant="h5">Upcoming Coaching Sessions</Typography>
                            <CircularProgress />
                        </Stack>
                    </Box>
                </LocalizationProvider>
            </DashboardElement>
        );
    } else if(coachingSessions.length == 0) {
        return (
            <DashboardElement elevation={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ width: "100%", maxHeight: "1000px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                        <Stack direction="column" spacing={1.0} sx={{  width: "100%", mt: "10px", mb: "10px"}}>
                            <Box sx={{ width: "100%" }}>
                                <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Upcoming Coaching Sessions</Typography>
                            </Box>
                            <TableContainer sx={{ overflowx: "initial" }} component={Paper}>
                                <Table sx={{ minWidth: "550px", height: "auto" }} stickyHeader size="small">
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell>Date</StyledTableCell>
                                            <StyledTableCell>Time</StyledTableCell>
                                            <StyledTableCell>Length</StyledTableCell>
                                            <StyledTableCell>Coach</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={7} align="center">You have no upcoming Coaching Sessions</StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </Box>
                </LocalizationProvider>
            </DashboardElement>
        );
    } else {
        return (
            <DashboardElement elevation={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Dialog open={cancelSessionDialogOpen} onClose={() => setCancelSessionDialogOpen(false)}>
                        <DialogTitle>Cancel this coaching session?</DialogTitle>
                        <DialogContent>
                            <FormControl fullWidth>
                                <InputLabel>Cancellation reason</InputLabel>
                                <Select label="Cancellation reason" value={cancelSessionReasonSelectValue} onChange={(e) => setCancelSessionReasonSelectValue(e.target.value)}>
                                    <MenuItem value="Work obligations">Work obligations</MenuItem>
                                    <MenuItem value="Personal commitment">Personal commitment</MenuItem>
                                    <MenuItem value="I don't need this coaching session">I need this coaching session</MenuItem>
                                    <MenuItem value="Coaching is taking up too much of my time">Coaching is taking up too much of my time</MenuItem>
                                    <MenuItem value="Reason not listed">Reason not listed</MenuItem>
                                </Select>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setCancelSessionDialogOpen(false)}>Close</Button>
                            <Button disabled={cancelSessionReasonSelectValue==null} onClick={() => cancelMeeting()}>Cancel Session</Button>
                        </DialogActions>
                    </Dialog>
                    <Box sx={{ width: "100%", maxHeight: "1000px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                        <Stack direction="column" spacing={1.0} sx={{  width: "100%", mt: "10px", mb: "10px"}}>
                            <Box sx={{ width: "100%" }}>
                                <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Upcoming Coaching Sessions</Typography>
                            </Box>
                            <TableContainer sx={{ overflowx: "initial" }} component={Paper}>
                                <Table sx={{ minWidth: "550px", height: "auto" }} stickyHeader size="small">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell>Date</StyledTableCell>
                                            <StyledTableCell>Time</StyledTableCell>
                                            <StyledTableCell>Length</StyledTableCell>
                                            <StyledTableCell>Coach</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { coachingSessions.map((sessionData, index) => {
                                            return(
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell><Avatar alt={sessionData.otherAttendee} src={"/api/v1/account/profile-picture/" + sessionState.participant.coachId} /></StyledTableCell>
                                                    <StyledTableCell>{dayjs(sessionData.scheduledTime).format("D MMM YYYY")}</StyledTableCell>
                                                    <StyledTableCell>{dayjs(sessionData.scheduledTime).format("h:mm A")}</StyledTableCell>
                                                    <StyledTableCell>{sessionData.meetingLength} Min.</StyledTableCell>
                                                    <StyledTableCell>{sessionData.otherAttendee}</StyledTableCell>
                                                    <StyledTableCell><Button onClick={() => openCancelMeetingDialog(sessionData.meetingId)} color="error">Cancel</Button></StyledTableCell>
                                                    <StyledTableCell><Button onClick={() => joinMeeting(sessionData.meetingId)}>Join</Button></StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </Box>
                </LocalizationProvider>
            </DashboardElement>
        );
    }
}