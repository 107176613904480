import React, { useEffect, useState } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia, Checkbox,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Input, InputAdornment, Pagination,
    Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Grid,
    Toolbar, IconButton, Switch, Skeleton, Collapse } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import DashboardElement from 'pageElements/DashboardElement';
import DashboardLayout from 'pageElements/DashboardLayout';
import DashboardBody from 'pageElements/DashboardBody';
import DashboardMenuBar from 'pageElements/DashboardMenuBar';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const ClickableStyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
        backgroundColor: theme.palette.primary.light,
    },
    "&:active": {
        backgroundColor: theme.palette.primary.dark,
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
}));

const MenuBarButton = styled(Button)(({ theme }) => ({
    color: "white",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#6c89b8"
    }
}));


export default function NoShowsReport() {
    const [noShows, setNoShows] = useState([]);

    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [page, setPage] = useState(0);

    
    useEffect(() => {
        const rq = {
            generateForAllCoaches: true,
            coachList: []
        };
        fetch("/api/v1/account/analytics/no-shows").then((res) => {
            return res.json();
        }).then((NoShowsListResponse) => {
            let noShowList = [];
            for(let i = 0; i < NoShowsListResponse.noShows.length; i++) {
                noShowList.push({
                    noShow: NoShowsListResponse.noShows[i], 
                    account: NoShowsListResponse.accounts[i],
                    meeting: NoShowsListResponse.meetings[i]
                });
            }
            setNoShows(noShowList);
        });
    }, []);

    return (
        <DashboardLayout>
            <DashboardMenuBar>
                <MenuBarButton onClick={() => {window.location.href = "/"}}>Organization Dashboard</MenuBarButton>
                <MenuBarButton onClick={() => window.location.href="/analytics"} >Organization Analytics</MenuBarButton>
            </DashboardMenuBar>
            <DashboardBody>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    { noShows.length == 0 && <CircularProgress /> }
                    { noShows.length > 0 && <DashboardElement elevation={1}>
                        <Box sx={{ width: "100%" }}>
                            <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>No Shows</Typography>
                        </Box>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 600 }}>
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="left">Name</StyledTableCell>
                                        <StyledTableCell align="right">Time</StyledTableCell>
                                        <StyledTableCell align="right">Meeting Id</StyledTableCell>
                                        <StyledTableCell align="right">User Type</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    { noShows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((noShow, index) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell align="left">{noShow.account.firstName} {noShow.account.lastName}</StyledTableCell>
                                                <StyledTableCell align="right">{dayjs(noShow.meeting.meetingStartTime).format()}</StyledTableCell>
                                                <StyledTableCell align="right">{noShow.noShow.meetingId}</StyledTableCell>
                                                <StyledTableCell align="right">{noShow.account.accountType}</StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination 
                            rowsPerPageOptions={[8]}
                            component="div"
                            count={noShows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(event, pageNum) => setPage(pageNum)} />
                    </DashboardElement> }
                </LocalizationProvider>
            </DashboardBody>
        </DashboardLayout>
    );
}