import React, { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider, Checkbox,
    FormGroup, TableContainer, Table, TableRow, TableBody, TableHead, IconButton, ListItemText,
    OutlinedInput, InputAdornment, Input, Collapse, TablePagination, Skeleton, Modal, Dialog,
    DialogContent, DialogContentText, DialogActions, DialogTitle, Tooltip } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import DashboardElement from 'pageElements/DashboardElement';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
}));


export default function CoachingSessionTimeTracking({ clientId }) {
  const [searchTimeRangeStart, setSearchTimeRangeStart] = useState(dayjs());
  const [searchTimeRangeEnd, setSearchTimeRangeEnd] = useState(dayjs());
  const [coachingSessionTimeRecords, setCoachingSessionTimeRecords] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [coachAccountCache, setCoachAccountCache] = useState(new Map());
  const [participantAccountCache, setParticipantAccountCache] = useState(new Map());


  function getCoachingSessionTimeData() {
    let dateTimeRange = {
        begin: searchTimeRangeStart,
        end: searchTimeRangeEnd
    };

    fetch("/api/v1/account/client-completed-coaching-sessions-in-time-range/" + clientId, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(dateTimeRange)
    }).then((res) => {
        return res.json();
    }).then((coachingSessionTimeRecords) => {
        setCoachingSessionTimeRecords(coachingSessionTimeRecords.coachingSessionTimeRecords);
    });
  }

  return (
    
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DashboardElement elevation={1}>
            <Stack direction="column" spacing={ 1 } sx={{ width: "100%" }}>
                <Box sx={{ width: "100%", maxHeight: "1000px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                    <Box sx={{ width: "100%" }}>
                        <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Coaching Session Time Tracking</Typography>
                    </Box>
                    <TableContainer sx={{ overflowx: "initial" }} component={Paper}>
                        <Table sx={{ minWidth: "550px" }} stickyHeader size="small">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="right"><DateTimePicker label="Query Start Time" value={searchTimeRangeStart} onChange={(newDateTime) => setSearchTimeRangeStart(newDateTime)} /></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="left"><DateTimePicker label="Query End Time" value={searchTimeRangeEnd} onChange={(newDateTime) => setSearchTimeRangeEnd(newDateTime)} /></StyledTableCell>
                                    <StyledTableCell align="left"><Button onClick={() => getCoachingSessionTimeData()}>Search</Button></StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell>Coach</StyledTableCell>
                                    <StyledTableCell>Participant</StyledTableCell>
                                    <StyledTableCell>Coach Minutes Attended</StyledTableCell>
                                    <StyledTableCell>Participant Minutes Attended</StyledTableCell>
                                    <StyledTableCell>Concurrent Session Minutes</StyledTableCell>
                                    <StyledTableCell>Session Length (Minutes)</StyledTableCell>
                                    <StyledTableCell>Date & Time</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                { coachingSessionTimeRecords.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((coachingSessionTimeRecord) => {
                                    return(
                                        <StyledTableRow>
                                            <StyledTableCell>{coachingSessionTimeRecord.coachEmail}</StyledTableCell>
                                            <StyledTableCell>{coachingSessionTimeRecord.participantEmail}</StyledTableCell>
                                            <StyledTableCell>{coachingSessionTimeRecord.coachSessionTimeMinutes}</StyledTableCell>
                                            <StyledTableCell>{coachingSessionTimeRecord.participantSessionTimeMinutes}</StyledTableCell>
                                            <StyledTableCell>{coachingSessionTimeRecord.concurrentSessionTimeMinutes}</StyledTableCell>
                                            <StyledTableCell>{coachingSessionTimeRecord.meetingLength}</StyledTableCell>
                                            <StyledTableCell>{dayjs(coachingSessionTimeRecord.meetingStart).local().format()}</StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <TablePagination 
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={coachingSessionTimeRecords.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, pageNum) => setPage(pageNum)} />
            </Stack>
        </DashboardElement>
    </LocalizationProvider>
  );
}