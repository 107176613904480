import React, { useState, useContext, Fragment, useEffect } from 'react';
import { SessionContext } from 'Contexts';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
  Chip, Stack, Paper, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
  Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Dialog, DialogActions,
  Button, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, InputAdornment,
  IconButton, DialogContentText, DialogContent } from '@mui/material';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { ModalClose } from '@mui/joy';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const ClickableStyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
}));

const MenuBarButton = styled(Button)(({ theme }) => ({
  color: "white",
  borderRadius: "0px",
  width: "100%",
  fontWeight: 600,
  textTransform: "none",
  '&:hover': {
    backgroundColor: "#6c89b8"
  }
}));

export default function AssignCoachesButton({ clientId }) {
  let [coachCertifications, setCoachCertifications] = useState(null);
  let [coaches, setCoaches] = useState(null);
  let [coachAccounts, setCoachAccounts] = useState(null);
  let [coachAssignModalOpen, setCoachAssignModalOpen] = useState(false);
  
  let [selectedCoach, setSelectedCoach] = useState(null);
  let [coachWorkCommitmentMaxParticipants, setCoachWorkCommitmentMaxParticipants] = useState(1);
  let [coachWorkCommitmentPayRate, setCoachWorkCommitmentPayRate] = useState(0);
  let [coachWorkCommitmentModalOpen, setCoachWorkCommitmentModalOpen] = useState(false);

  useEffect(() => {
    fetch("/api/v1/account/coach-certification-levels").then((res) => {
      return res.json();
    }).then((coachCertificationLevelsResponse) => {
      console.log("coachCertificationLevelsResponse: ", coachCertificationLevelsResponse);
      let certificationsMap = {};
      for(let i = 0; i < coachCertificationLevelsResponse.certificationLevels.length; i++) {
        console.log("certificationsMap[",coachCertificationLevelsResponse.certificationLevels[i].certificationId,"]: ", coachCertificationLevelsResponse.certificationLevels[i]);
        certificationsMap[coachCertificationLevelsResponse.certificationLevels[i].certificationId] = coachCertificationLevelsResponse.certificationLevels[i];
      }
      setCoachCertifications(certificationsMap);
    });

    fetch("/api/v1/account/available-coaches/" + clientId).then((res) => {
      return res.json();
    }).then((coachListResponse) => {
      setCoaches(coachListResponse.coaches);

      let coachAccountsMap = {};
      for(let i = 0; i < coachListResponse.coachAccounts.length; i++) {
        coachAccountsMap[coachListResponse.coachAccounts[i].accountId] = coachListResponse.coachAccounts[i];
      }
      setCoachAccounts(coachAccountsMap);
    });
  }, []);

  function getCertificationNameById(certificationId) {
    let cert = coachCertifications[certificationId];
    if(cert != null)
      return cert.name;
    return "ERROR";
  }

  function assignNewCoach() {
    let rq = {
      coachWorkCommitmentId: -1,
      coachPayRate: coachWorkCommitmentPayRate * 100,
      currentParticipantCommitments: 0,
      maxParticipantCommitments: coachWorkCommitmentMaxParticipants,
      contractId: -1,
      coachId: selectedCoach.accountId
    };
    fetch("/api/v1/account/client-coach-commitment/" + clientId, {
      method: "POST",
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify(rq)
    }).then((res) => {
      window.location.reload();
    });
  }


  if(coaches == null || coachAccounts == null || coachCertifications == null) {
    return(<CircularProgress />);
  }
  else {
    return (
      <React.Fragment>
        <Dialog open={coachWorkCommitmentModalOpen} onClose={() => {setCoachWorkCommitmentModalOpen(false)}}>
          <DialogTitle>Assign Coach</DialogTitle>
          { selectedCoach != null && <DialogContent>
            <DialogContentText>
              Create work commitment for {coachAccounts[selectedCoach.accountId].firstName} {coachAccounts[selectedCoach.accountId].lastName} 
            </DialogContentText>
            <Stack direction="column">
              <TextField  
                autofocus
                label="Max Participants" 
                type="number" 
                variant="standard" 
                value={coachWorkCommitmentMaxParticipants}
                onChange={(e) => setCoachWorkCommitmentMaxParticipants(e.target.value)} />
              <TextField 
                label="Pay Rate (Dollars Per Hour)" 
                type="number" 
                variant="standard" 
                defaultValue={coachWorkCommitmentPayRate}
                onChange={(e) => setCoachWorkCommitmentPayRate(e.target.value)} 
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }} />
              </Stack>
          </DialogContent> }
          <DialogActions>
            <Button onClick={() => setCoachWorkCommitmentModalOpen(false)}>Cancel</Button>
            <Button onClick={() => {assignNewCoach(); setCoachWorkCommitmentModalOpen(false);}}>Submit</Button>
          </DialogActions>
        </Dialog>
        <Modal disableEnforceFocus={true} open={coachAssignModalOpen} onClose={() => { setCoachAssignModalOpen(false) }} >
          <ModalDialog>
            <DialogTitle>Assign New Coaches</DialogTitle>
            <ModalClose />
            <Stack spacing={2}>
              <Box sx={{ width: "100%", maxHeight: "400px", display: "flex", flexDirection: "column", alignItems: "flex-start", overflow: "hidden" }}>
                <TableContainer sx={{ overflowx: "initial" }}>
                  <Table sx={{ minWidth: 725 }} stickyHeader>
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell colSpan={5}>Available Coaches</StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell align="right">Email</StyledTableCell>
                        <StyledTableCell align="right">First Name</StyledTableCell>
                        <StyledTableCell align="right">Last Name</StyledTableCell>
                        <StyledTableCell align="right">Coach Level</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      { coaches.map((coach) => {
                          return(
                            <StyledTableRow key={coach.accountId}>
                              <StyledTableCell><Button onClick={() => {setSelectedCoach(coach); setCoachWorkCommitmentModalOpen(true)}}>Assign</Button></StyledTableCell>
                              <StyledTableCell align="right">{coachAccounts[coach.accountId].firstName}</StyledTableCell>
                              <StyledTableCell align="right">{coachAccounts[coach.accountId].lastName}</StyledTableCell>
                              <StyledTableCell align="right">{coachAccounts[coach.accountId].accountEmail}</StyledTableCell>
                              <StyledTableCell align="right">{getCertificationNameById(coach.highestCertificationId)}</StyledTableCell>
                            </StyledTableRow>
                          );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Stack>
          </ModalDialog>
        </Modal>
        <Chip label="Assign Coaches" onClick={() => { setCoachAssignModalOpen(true) }} color="primary" icon={<PersonAddIcon />} />
      </React.Fragment>
    );
  }
}