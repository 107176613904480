import { Children } from 'react';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert } from '@mui/material';

export default function DashboardElement({ elevation, children, paper=false }) {
    return (
        <Box sx={{ width: "95%", alignItems: "center", justifyContent: "center", mt:"10px",  mb: "1%" }}>
          { paper && <Paper elevation={elevation}>{ children }</Paper> }
          { !paper && children }
        </Box>
    );
}