import React, { useState, useContext, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { SessionContext } from 'Contexts';
import { Sheet, Textarea } from '@mui/joy';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import { Typography, Badge } from '@mui/joy';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider, Checkbox,
    FormGroup, TableContainer, Table, TableRow, TableBody, TableHead, IconButton, ListItemText,
    OutlinedInput, InputAdornment, Input, Collapse, TablePagination, Skeleton, Tabs, Tab } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import utc from 'dayjs/plugin/utc';
import DashboardElement from 'pageElements/DashboardElement';
import Chat from 'shared/Chat';
import { justifyContent } from 'styled-system';

dayjs.extend(utc);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const ClickableStyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
        backgroundColor: theme.palette.primary.light,
    },
    "&:active": {
        backgroundColor: theme.palette.primary.dark,
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ParticipantList() {
    const sessionState = useContext(SessionContext);
    let [participantList, setParticipantList] = useState(null);
    let [participantsPerPage, setParticipantsPerPage] = useState(5);
    let [participantPage, setParticipantPage] = useState(0);

    let [participantModalOpen, setParticipantModalOpen] = useState(false);
    let [selectedParticipantData, setSelectedParticipantData] = useState(null);
    let [selectedParticipantCoachingSessions, setSelectedParticipantCoachingSessions] = useState([]);


    function NotesTab({ participantId }) {
        let [coachNotes, setCoachNotes] = useState([]);
        let [newNoteText, setNewNoteText] = useState('');

        function postNewNote() {
            let rq = {
                noteText: newNoteText,
                coachId: sessionState.accountId,
                participantId: participantId,
                entryDate: dayjs().format(),
                visibleToParticipant: false
            };

            fetch("/api/v1/account/coach-note", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(rq)
            }).then((res) => {
                fetch("/api/v1/account/coach-notes/" + selectedParticipantData.participant.accountId).then((res) => {
                    return res.json();
                }).then((coachNotesResponse) => {
                    setCoachNotes(coachNotesResponse.notes);
                })
            })
        }

        useEffect(() => {
            fetch("/api/v1/account/coach-notes/" + participantId).then((res) => {
                return res.json();
            }).then((coachNotesResponse) => {
                setCoachNotes(coachNotesResponse.notes);
            })
        }, []);
        
        return (
            <Stack direction='column' sx={{ width: '100%', height: '100%' }}>
                <TableContainer sx={{ justifyContent: 'flex-end', overflow: "hidden", overflowY: 'scroll', maxHeight: '70%' }}>
                    <Table>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Note</StyledTableCell>
                                <StyledTableCell>Created</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            { coachNotes.map((note, index) => {
                                return (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell>{note.noteText}</StyledTableCell>
                                        <StyledTableCell>{dayjs(note.entryDate).local().format("D MMM YYYY h:mm A")}</StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer sx={{ justifyContent: 'flex-end', maxHeight: '30%' }}>
                    <Table>
                        <TableBody>
                            <StyledTableRow key="new">
                                <StyledTableCell><Textarea placeholder="..." minRows={3} size="sm" variant="outlined" onChange={(event) => { setNewNoteText(event.target.value)}} /></StyledTableCell>
                                <StyledTableCell><Button onClick={() => postNewNote()}>Post Note</Button></StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        )
    }

    function ParticipantScheduleTab({ selectedParticipantData, coachingSessionSchedule }) {
        return (
            <Stack direction="column" alignItems='flex-start' justifyContent='flex-start' spacing={1.0} sx={{ height: '100%', width: '100%' }}>
                <Stack direction="row" spacing={2.0} sx={{ mt: "10px", mb: "10px"}}>
                    <Avatar alt={selectedParticipantData.firstName} src={"/api/v1/account/profile-picture/" + selectedParticipantData.participant.accountId} />
                    <Typography variant="h5">{selectedParticipantData.firstName} {selectedParticipantData.lastName}</Typography>
                    <ScheduleSessionButton participantId={selectedParticipantData.participant.accountId} minutesRemaining={selectedParticipantData.coachingHours.minutesRemaining} />
                </Stack>
                <Divider />
                <TableContainer sx={{ overflowx: "initial", height: '100%' }}>
                    <Table sx={{ minWidth: "550px" }} stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left" colSpan={7}><Box sx={{ fontWeight: 'bold' }}>Upcoming Coaching Sessions</Box></StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell>Date</StyledTableCell>
                                <StyledTableCell>Time</StyledTableCell>
                                <StyledTableCell>Length</StyledTableCell>
                                <StyledTableCell>Participant</StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { coachingSessionSchedule.map((sessionData, index) => {
                                if(sessionData.participantId == selectedParticipantData.participant.accountId) {
                                    return(
                                        <StyledTableRow key={index}>
                                            <StyledTableCell>{dayjs(sessionData.scheduledTime).format("D MMM YYYY")}</StyledTableCell>
                                            <StyledTableCell>{dayjs(sessionData.scheduledTime).format("h:mm A")}</StyledTableCell>
                                            <StyledTableCell>{sessionData.meetingLength} Min.</StyledTableCell>
                                            <StyledTableCell>{sessionData.otherAttendee}</StyledTableCell>
                                            <StyledTableCell><Button>Cancel</Button></StyledTableCell>
                                            <StyledTableCell><Button>Join</Button></StyledTableCell>
                                        </StyledTableRow>
                                    );
                                }
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        );
    }

    useEffect(() => {
        fetch("/api/v1/account/coachees/" + sessionState.accountId).then((res) => {
            return res.json();
        }).then((participantDataResponse) => {
            for(let i = 0; i < participantDataResponse.participantsData.length; i++) {
                participantDataResponse.participantsData.unreadMessages = 0;
            }
            setParticipantList(participantDataResponse.participantsData);
        });
    }, []);

    function UpdateUnreadMessageMetadata() {
        let [updateMessagesMetadata, setUpdateMessagesMetadata] = useState(0);

        useEffect(() => {
            setUpdateMessagesMetadata(1);
        }, []);

        useEffect(() => {
            let timeoutLength = 30000;
            if(participantList != null) {
                for(let i = 0; i < participantList.length; i++) {
                    const chatMetadataRequest = {
                        otherAccountId: participantList[i].participant.accountId,
                        chatId: null
                    };
                    const updateMetadataPromises = [];
                    const updateMetadataPromise = fetch("/api/v1/account/chat-metadata", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(chatMetadataRequest)
                    }).then((res) => {
                        return res.json()
                    }).then((chatMetadataResponse) => {
                        if(participantList[i].unreadMessages == chatMetadataResponse.unreadMessageCount) {
                            return false;
                        }
                        participantList[i].unreadMessages = chatMetadataResponse.unreadMessageCount;
                        return true;
                    });
                    updateMetadataPromises.push(updateMetadataPromise);
                    Promise.all(updateMetadataPromises).then((values) => {
                        if(values.some((value) => {
                            return value == true
                        })) {
                            setParticipantList([...participantList]);
                        }
                    });
                }
            } else {
                timeoutLength = 5000;
            }
            let timeoutId = setTimeout(() => {
                setUpdateMessagesMetadata(updateMessagesMetadata + 1);
            }, timeoutLength);
            return () => clearTimeout(timeoutId);
        }, [updateMessagesMetadata]);
    }

    function loadSelectedParticipantData(participantData) {
        fetch("/api/v1/account/schedule-for-coachee/" + participantData.participant.accountId).then((res) => {
            return res.json();
        }).then((coachingSessionSchedule) => {
            setSelectedParticipantCoachingSessions(coachingSessionSchedule.coachingSessions);
        });
    }

    function openParticipantModel(participantData) {
        if(participantData == null)
            return;
        setSelectedParticipantCoachingSessions([]);
        setSelectedParticipantData(participantData);
        loadSelectedParticipantData(participantData);
        setParticipantModalOpen(true);
    }

    function SelectedParticipantModal({ selectedParticipantData, coachingSessionSchedule }) {
        let [tab, setTab] = useState(0);

        if(selectedParticipantData == null) {
            return null;
        } else {
            return (
                <Modal open={participantModalOpen} onClose={() => setParticipantModalOpen(false)} style={{modal: {marginTop: '10px', marginLeft: 'auto', marginRight: 'auto'}}}>
                    <ModalDialog>
                        <ModalClose />
                        <Box sx={{ width: "90dvw", ml: "auto", mr: "auto", height: "90dvh"}}>
                            <Stack sx={{ width: '100%', height: '100%'}} spacing={0} alignItems='flex-start' justifyContent='flex-start' direction='column'>
                                <Tabs sx={{ width: '100%'}} value={tab} onChange={(e, v) => setTab(v)}>
                                    <Tab value={0} label="Schedule" />
                                    <Tab value={1} label="Messages" />
                                    <Tab value={2} label="Notes" />
                                </Tabs>
                                { tab==0 && <ParticipantScheduleTab selectedParticipantData={selectedParticipantData} coachingSessionSchedule={coachingSessionSchedule} />}
                                { tab==1 && <Chat otherUserId={selectedParticipantData.participant.accountId} />}
                                { tab==2 && <NotesTab participantId={selectedParticipantData.participant.accountId} />}
                            </Stack>
                        </Box>
                    </ModalDialog>
                </Modal>
            );
        }
    }

    function ScheduleSessionButton({ participantId, minutesRemaining }) {
        const [scheduleSessionDialogOpen, setScheduleSessionDialogOpen] = useState(false);
        const [newEventTime, setNewEventTime] = useState(null);
        const [sessionLength, setSessionLength] = useState(30);

        function canSubmit() {
            return newEventTime != null && sessionLength <= minutesRemaining;
        }

        function submit() {
            let rq = {
                meetingName: 'Coaching session',
                participantAccountId: participantId,
                coachAccountId: sessionState.accountId,
                scheduledStartTime: newEventTime,
                meetingLength: sessionLength
            };
            fetch("/api/v1/account/schedule", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(rq)
            }).then((res) => {
                setNewEventTime(null);
                setSessionLength(30);
                setScheduleSessionDialogOpen(false);
            });
        }

        return (
            <React.Fragment>
                <Button onClick={() => setScheduleSessionDialogOpen(true)}>Schedule coaching session</Button>
                <Modal open={scheduleSessionDialogOpen} onClose={() => setScheduleSessionDialogOpen(false)} style={{modal: {marginTop: '10px', marginLeft: 'auto', marginRight: 'auto'}}}>
                    <ModalDialog>
                        <ModalClose />
                        <Box sx={{ ml: 'auto', mr: 'auto' }}>
                            <Stack sx={{ width: '100%', height: '100%'}} spacing={0.75} alignItems='center' justifyContent='flex-start' direction='column'>
                                <MobileDateTimePicker 
                                 label="Event Start"
                                 value={newEventTime}
                                 onChange={(newValue) => setNewEventTime(newValue)}
                                />
                                <Select value={sessionLength} onChange={(e) => setSessionLength(e.target.value)}>
                                    { minutesRemaining >= 30 && <MenuItem value={30}>30 Minutes</MenuItem> }
                                    { minutesRemaining >= 45 && <MenuItem value={45}>45 Minutes</MenuItem> }
                                    { minutesRemaining >= 60 && <MenuItem value={60}>60 Minutes</MenuItem> }
                                    { minutesRemaining >= 75 && <MenuItem value={75}>1 Hour 15 Minutes</MenuItem> }
                                    { minutesRemaining >= 90 && <MenuItem value={90}>1 Hour 30 Minutes</MenuItem> }
                                </Select>
                                <Button disabled={!canSubmit()} onClick={() => submit()}>Schedule session</Button>
                            </Stack>
                        </Box>
                    </ModalDialog>
                </Modal>
            </React.Fragment>
        );
    }
   
    if(participantList == null) {
        return (
            <DashboardElement elevation={1}>
                <Typography>Participants</Typography>
                <Stack direction="column" spacing={0.5}>
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                </Stack>
            </DashboardElement>
        );
    } else if(participantList.length == 0) {
        return(
            <DashboardElement elevation={1}>
                <Stack direction="column" sx={{ width:"100%", mt:"15px", mb:"15px"}}>
                    <Typography>You currently do not have any participants</Typography>
                </Stack>
            </DashboardElement>
        );
    } else {
        return (
            <DashboardElement elevation={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <UpdateUnreadMessageMetadata />
                    <SelectedParticipantModal 
                      selectedParticipantData={selectedParticipantData} 
                      coachingSessionSchedule={selectedParticipantCoachingSessions} />
                    <Box sx={{ width: "100%", maxHeight: "1000px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                        <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
                            <Box sx={{ width: "100%" }}>
                                <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Your Participants</Typography>
                            </Box>
                            <TableContainer sx={{ overflowx: "initial" }} component={Paper}>
                                <Table sx={{ minWidth: "550px" }} stickyHeader size="small">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>{/* Participant profile picture */}</StyledTableCell>
                                            <StyledTableCell>First Name</StyledTableCell>
                                            <StyledTableCell>Last Name</StyledTableCell>
                                            <StyledTableCell>Client</StyledTableCell>
                                            <StyledTableCell>Hours Coached</StyledTableCell>
                                            <StyledTableCell>Hours Pending</StyledTableCell>
                                            <StyledTableCell>Hours Remaining</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { participantList.slice(participantPage * participantsPerPage, participantPage * participantsPerPage + participantsPerPage).map((participantData, index) => {
                                            return(
                                                <ClickableStyledTableRow key={index} onClick={() => openParticipantModel(participantData)}>
                                                    <StyledTableCell>
                                                        {participantData.unreadMessages > 0 && <Badge size='sm' badgeContent={participantData.unreadMessages}><Avatar alt={participantData.firstName + " " + participantData.lastName} src={"/api/v1/account/profile-picture/" + participantData.participant.accountId} /></Badge>}
                                                        {participantData.unreadMessages == 0 && <Avatar alt={participantData.firstName + " " + participantData.lastName} src={"/api/v1/account/profile-picture/" + participantData.participant.accountId} />}
                                                    </StyledTableCell>
                                                    <StyledTableCell>{participantData.firstName}</StyledTableCell>
                                                    <StyledTableCell>{participantData.lastName}</StyledTableCell>
                                                    <StyledTableCell>{participantData.clientName}</StyledTableCell>
                                                    <StyledTableCell>{(participantData.coachingHours.minutesUsed / 60.0).toFixed(1)}</StyledTableCell>
                                                    <StyledTableCell>{(participantData.coachingHours.minutesPending / 60.0).toFixed(1)}</StyledTableCell>
                                                    <StyledTableCell>{(participantData.coachingHours.minutesRemaining / 60.0).toFixed(1)}</StyledTableCell>
                                                </ClickableStyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination 
                             rowsPerPageOptions={[5]}
                             component="div"
                             count={participantList.length}
                             rowsPerPage={participantsPerPage}
                             page={participantPage}
                             onPageChange={(event, pageNum) => setParticipantPage(pageNum)} />
                        </Stack>
                    </Box>
                </LocalizationProvider>
            </DashboardElement>
        );
    }
}