import { Children } from 'react';
import { Stack } from '@mui/material';

export default function DashboardLayout({ children }) {
    return (
        <Stack 
         direction="row" 
         justifyContent="center" 
         alignItems="flex-start" 
         spacing={0} 
         sx={{ width: "100%", height: "92vh", ml: "0px", mr: "0px"}}>
            { children }
        </Stack>
    );
}