import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
  Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia, Checkbox,
  Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Input, InputAdornment, Pagination,
  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Grid,
  Toolbar, IconButton, Switch, Skeleton } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import DashboardElement from 'pageElements/DashboardElement';
import DashboardLayout from 'pageElements/DashboardLayout';
import DashboardBody from 'pageElements/DashboardBody';
import DashboardMenuBar from 'pageElements/DashboardMenuBar';

const DisabledButton = styled(Button)(({ theme }) => ({
    '&:disabled': {
      color: "#6b6b6b",
    }
  }));

const MenuBarButton = styled(Button)(({ theme }) => ({
    color: "white",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#6c89b8"
    }
}));

function ClientContractHours({ clientId }) {
    const [dataLoaded, setDataLoaded] = useState(false);
    const [laborCategories, setLaborCategories] = useState(null);
    const [LCATHours, setLCATHours] = useState(null);

    useEffect(() => {
        let contractHoursPromise = fetch("/api/v1/account/analytics/contract-hours-with-progress/" + clientId).then((res) => {
            return res.json();
        }).then((contractHoursResponse) => {
            setLCATHours(contractHoursResponse.contractHours);
        });

        let laborCategoriesPromise = fetch("/api/v1/account/labor-categories/" + clientId).then((res) => {
            return res.json();
        }).then((laborCategoriesResponse) => {
            let lcatMap = new Map();
            for(let i = 0; i < laborCategoriesResponse.laborCategories.length; i++) {
                lcatMap.set(laborCategoriesResponse.laborCategories[i].laborCategoryId, laborCategoriesResponse.laborCategories[i]);
            }
            setLaborCategories(lcatMap);
        });

        Promise.all([contractHoursPromise, laborCategoriesPromise]).then(() => {
            setDataLoaded(true);
        });
    }, []);

    if(!dataLoaded) {
        return (
            <Grid item xs={6}>
                <Paper elevation={ 8 } sx={{ borderRadius: 4, background: "linear-gradient(339deg, rgba(199,214,39,1) 18%, rgba(146,153,29,1) 74%)" }}>
                    <Box flexGrow={1} alignItems="center" justifyContent="center">
                        <Typography variant="h5" sx={{ color: "white", fontWeight: 700 }}>Labor Category Hours</Typography>
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Gauge width={100} height={100} value={0} valueMin={0} valueMax={100} />
                    </Box>
                </Paper>
            </Grid>
        );
    } else {
        return (
            <Grid item xs={6}>
                <Paper elevation={ 8 } sx={{ borderRadius: 4, background: "linear-gradient(339deg, rgba(199,214,39,1) 18%, rgba(146,153,29,1) 74%)" }}>
                    <Box flexGrow={1} alignItems="center" justifyContent="center">
                        <Typography variant="h5" sx={{ color: "white", fontWeight: 700 }}>Hours Pool</Typography>
                        <Stack spacing={ 2 } direction="row">
                            {LCATHours.map((lcatHoursItem) => {
                                return (
                                    <Stack direction="column">
                                        <Typography sx={{ color: "white "}}>{laborCategories.get(lcatHoursItem.lcatId).laborCategoryName}</Typography>
                                        <Gauge 
                                          width={100} 
                                          height={100} 
                                          value={lcatHoursItem.hoursAvailable} 
                                          valueMin={0} 
                                          valueMax={lcatHoursItem.hoursTotal} 
                                          sx={{
                                            color: "white"
                                          }} />
                                    </Stack>
                                );
                            })}
                        </Stack>
                    </Box>
                </Paper>
            </Grid>
        );
    }

}

function ClientParticipantStates({ clientId }) {
    const [participantStatesInnerPieData, setParticipantStatesInnerPieData] = useState(null);
    const [participantStatesOuterPieData, setParticipantStatesOuterPieData] = useState(null);

    useEffect(() => {
        fetch("/api/v1/account/analytics/participant-states/" + clientId).then((res) => {
            return res.json();
        }).then((participantStatesResponse) => {
            let activeParticipantsDataSet = {
                label: "Active",
                value: participantStatesResponse.participantStates["COACHING"]
            };

            let registrationParticipantDataSet = {
                label: "Registration",
                value: 0
            }
            registrationParticipantDataSet.value += participantStatesResponse.participantStates["COACH_MATCHING"];
            registrationParticipantDataSet.value += participantStatesResponse.participantStates["WAITING_FOR_COACH_MATCHING_REVIEW"];
            registrationParticipantDataSet.value += participantStatesResponse.participantStates["COACH_SELECTION"];
            registrationParticipantDataSet.value += participantStatesResponse.participantStates["COACH_ASSIGNMENT"];
            registrationParticipantDataSet.value += participantStatesResponse.participantStates["NEEDS_REASSIGNMENT"];
            registrationParticipantDataSet.value += participantStatesResponse.participantStates["REGISTRATION"];

            let inactiveParticipantsDataSet = {
                label: "Inactive",
                value: 0
            };
            inactiveParticipantsDataSet.value += participantStatesResponse.participantStates["OPT_OUT"];
            inactiveParticipantsDataSet.value += participantStatesResponse.participantStates["INACTIVE"];
            inactiveParticipantsDataSet.value += participantStatesResponse.participantStates["DROPPED"];
            inactiveParticipantsDataSet.value += participantStatesResponse.participantStates["COMPLETED"];
            inactiveParticipantsDataSet.value += participantStatesResponse.participantStates["DELETED"];

            setParticipantStatesOuterPieData([
                { label: "Coaching", value: participantStatesResponse.participantStates["COACHING"] },
                { label: "Coach matching", value: participantStatesResponse.participantStates["COACH_MATCHING"] },
                { label: "Waiting for Coach Match Review", value: participantStatesResponse.participantStates["WAITING_FOR_COACH_MATCHING_REVIEW"] },
                { label: "Coach Selection", value: participantStatesResponse.participantStates["COACH_SELECTION"] },
                { label: "Coach Assignment", value: participantStatesResponse.participantStates["COACH_ASSIGNMENT"] },
                { label: "Needs Reassignment", value: participantStatesResponse.participantStates["NEEDS_REASSIGNMENT"] },
                { label: "Registration", value: participantStatesResponse.participantStates["REGISTRATION"] },
                { label: "Opt-Out", value: participantStatesResponse.participantStates["OPT_OUT"] },
                { label: "Inactive", value: participantStatesResponse.participantStates["INACTIVE"] },
                { label: "Dropped", value: participantStatesResponse.participantStates["DROPPED"] },
                { label: "Completed", value: participantStatesResponse.participantStates["COMPLETED"] },
                { label: "Deleted", value: participantStatesResponse.participantStates["DELETED"] }
            ]);
            setParticipantStatesInnerPieData([activeParticipantsDataSet, registrationParticipantDataSet, inactiveParticipantsDataSet]);
        });
    }, []);

    if(participantStatesInnerPieData == null || participantStatesOuterPieData == null) {
        return (
            <Grid item xs={4}>
                <Paper elevation={ 8 } sx={{ borderRadius: 4, background: "linear-gradient(339deg, rgba(51,37,204,1) 18%, rgba(0,0,136,1) 87%)" }}>
                    <Box flexGrow={1} alignItems="center" justifyContent="center">
                        <Typography variant="h5" sx={{ color: "white", fontWeight: 700 }}>Participant States</Typography>
                        <CircularProgress />
                    </Box>
                </Paper>
            </Grid>
        );
    } else {
        return (
            <Grid item xs={4}>
                <Paper elevation={ 8 } sx={{ borderRadius: 4, background: "linear-gradient(339deg, rgba(51,37,204,1) 18%, rgba(0,0,136,1) 87%)" }}>
                    <Box flexGrow={1} alignItems="center" justifyContent="center">
                        <Typography variant="h5" sx={{ color: "white", fontWeight: 700 }}>Participant States</Typography>
                        <PieChart
                            series={
                                [
                                    {
                                        innerRadius: 0,
                                        outerRadius: 80,
                                        data: participantStatesInnerPieData,
                                    },
                                    {
                                        innerRadius: 90,
                                        outerRadius: 110,
                                        data: participantStatesOuterPieData,
                                    } 
                                ]
                            } 
                            height={280}
                            slotProps={{
                                legend: { hidden: true },
                            }}
                        />
                    </Box>
                </Paper>
            </Grid>
        );
    }
}

export default function Client() {
  let { clientId } = useParams();

  return (
    <DashboardLayout>
      <DashboardMenuBar>
        <MenuBarButton onClick={() => {window.location.href = "/client/" + clientId}}>Client Dashboard</MenuBarButton>
        <Tooltip title="Coming soon"><span><DisabledButton disabled>Generate Monthly Reports</DisabledButton></span></Tooltip>
        <a href={"/api/v1/account/client-participants-registration-data/" + clientId} download>
            <MenuBarButton>Registration Data</MenuBarButton>
        </a>
      </DashboardMenuBar>
      <DashboardBody>
            <Typography variant="h2" component="h1" sx={{ fontWeight: 500 }}>Analytics</Typography>
            <Grid container sx={{ width: "100%" }} spacing={2}>
                <ClientParticipantStates clientId={ clientId } />
                <ClientContractHours clientId={ clientId } />
            </Grid>
      </DashboardBody>
    </DashboardLayout>
  );
}

