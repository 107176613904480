import React, { useState, useEffect, useContext } from 'react';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
  Chip, Stack, Paper, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
  Card, CardContent, Backdrop, CircularProgress, Snackbar, Alert, InputAdornment,
  Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Input, Button, Box, Modal, ModalDialog, DialogTitle } from '@mui/joy';
import isValidSession from "Session.js";

export default function LoginPage() {
  const [loginTab, setLoginTab] = useState('login');
  const [emailEntry, setEmailEntry] = useState('');
  const [passwordEntry, setPasswordEntry] = useState('');
  const [isAttemptingLogin, setIsAttemptingLogin] = useState(false);
  const [hasVerifiedSession, setHasVerifiedSession] = useState(false);
  const [resetPasswordPanelOpen, setResetPasswordPanelOpen] = useState(false);
  const [resetPasswordRequestInProgress, setResetPasswordRequestInProgress] = useState(false);
  const [resetPasswordEmailEntry, setResetPasswordEmailEntry] = useState("");
  const [resetPasswordResponseText, setResetPasswordResponseText] = useState("");

  function navigateToDashboardPage() {
    window.location.href = "/";
  }
  
  function displayLoginError(errMessage) {
    document.getElementById("loginErrorMessageBox").innerText = errMessage;
  }

  async function tryLogin() {
    try {
      if(isAttemptingLogin)
        return;
      setIsAttemptingLogin(true);
  
      const response = await fetch("/api/v1/account/authenticate", {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({plaintextPassword: passwordEntry, accountEmail: emailEntry})
      });
      var responseCode = response.status;
      var sessionData = await response.json();
      if (responseCode === 200) {
        localStorage["sessionData"] = JSON.stringify(sessionData);
        navigateToDashboardPage();
      } else if(responseCode === 401) {
        displayLoginError("Invalid login, your email and/or password is incorrect.");
      } else if(responseCode === 500) {
        displayLoginError("Error logging in.");
      }
    } catch(err) {
      displayLoginError("Error logging in.");
    }
    setIsAttemptingLogin(false);
  }

  let handlePasswordResetRequest = () => {
    if(resetPasswordEmailEntry == null || resetPasswordEmailEntry.length == 0)
      return;

    setResetPasswordRequestInProgress(true);
    fetch("/api/v1/account/request-reset-password", {
      method: "POST",
      headers: {
        "Content-Type": "text/plain"
      },
      body: resetPasswordEmailEntry
    }).then((res) => {
      if(res.status != 200) {
        console.log("Error while submitting password reset request: " + res.status);
        setResetPasswordResponseText("Error: Account not found");
      } else {
        document.getElementById("loginErrorMessageBox").innerText = "If the email you entered matches an existing account, an email with a password reset link will be sent to you."
        resetPasswordResponseText("Your request has been submitted. Check your email for further instructions.");
        //setResetPasswordPanelOpen(false);
      }
      setResetPasswordRequestInProgress(false);
    });
  }

  useEffect(() => {
    if(!hasVerifiedSession) {
      isValidSession().then((res) => {
        return res.json()
      }).then((sessionData) => {
        if(sessionData.hasSession) {
          navigateToDashboardPage();
        }
        setHasVerifiedSession(true);
      });
    }
  }, [isValidSession, navigateToDashboardPage,
      hasVerifiedSession, setHasVerifiedSession]);

  if(hasVerifiedSession) {
    return (
      <React.Fragment>
        <div className="AnimatedGradientLoginBackground">
          <div class="wave" />
          <div class="wave" />
          <div class="wave" />
          <div className="PageBody">
            <Modal open={resetPasswordPanelOpen} onClose={() => { setResetPasswordPanelOpen(false) }} >
              <ModalDialog>
                  <DialogTitle>Reset Password</DialogTitle>
                  <Stack spacing={2}>
                      <FormControl>
                          <FormLabel>Email Address</FormLabel>
                          <Input autofocus required onChange={(event) => { setResetPasswordEmailEntry(event.target.value) }}/>
                      </FormControl>
                      <Typography>{resetPasswordResponseText}</Typography>
                      <Button loading={resetPasswordRequestInProgress} loadingPosition="start" onClick={handlePasswordResetRequest}>Submit</Button>
                  </Stack>
              </ModalDialog>
            </Modal>
            <Box sx={{ width: "37.5%", maxWidth: "350px", minWidth: "250px", alignItems: "center", justifyContent: "center", mt:"10%", zIndex: 150}}>
              <Paper elevation={1}>
                <Stack spacing={0.75} direction="column" justifyContent="center" alignItems="center">
                  <br />
                  <img src="/banner.png" width="80%" />
                  <Typography variant="h5" component="h1">Welcome</Typography>
                  <br />
                  <TabContext value={loginTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', width:"100%" }}>
                      <TabList onChange={ (event, value) => { setLoginTab(value) }} textColor="primary" indicatorColor="primary">
                        <Tab value="login" label="Login"/>
                        <Tab value="register" label="Register"/>
                      </TabList>
                    </Box>
                    <div id="loginErrorMessageBox" />
                    <TabPanel value="login">
                      <Stack spacing={0.75} >
                        <br />
                        <Input variant="outlined" placeholder="Email Address" onChange={(event) => { setEmailEntry(event.target.value) }} />
                        <Input variant="outlined" type="password" placeholder="Password" onChange={(event) => { setPasswordEntry(event.target.value) }} />
                        <Button variant="plain" size="sm" color="primary" onClick={() => { setResetPasswordPanelOpen(true) }}>Forgot Password</Button>
                        <Typography variant="caption">By logging in, you agree to our terms of service and privacy policy.</Typography>
                        <Button fullwidth loading={isAttemptingLogin} loadingPosition="start" size="md" onClick={ tryLogin }>Log in</Button>
                      </Stack>
                    </TabPanel>
                    <TabPanel value="register">
                      <br />
                      <Button onClick={ () => { window.location.href = '/coach-registration' }}>Coach Registration</Button>
                    </TabPanel>
                  </TabContext>
                </Stack>
              </Paper>
            </Box>
          </div>
        </div>
      </React.Fragment>
    );
  }
  else {
    return (
      <div className="AnimatedGradientLoginBackground">
        <div class="wave" />
        <div class="wave" />
        <div class="wave" />
      </div>
    );
  }
}