import React, { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { SessionContext } from 'Contexts';
import { useTheme } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider, Checkbox,
    FormGroup, TableContainer, Table, TableRow, TableBody, TableHead, IconButton, ListItemText,
    OutlinedInput, InputAdornment, Input, Collapse, TablePagination, Skeleton, Dialog,
    DialogContent, DialogContentText, DialogActions, Tooltip, Switch, DialogTitle, Menu } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import utc from 'dayjs/plugin/utc';
import DashboardElement from 'pageElements/DashboardElement';
import InviteParticipantsButtons from './InviteParticipantsButtons';

import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import MoreVertIcon from '@mui/icons-material/MoreVert';

dayjs.extend(utc);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const ClickableStyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
        backgroundColor: theme.palette.primary.light,
    },
    "&:active": {
        backgroundColor: theme.palette.primary.dark,
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(stateName, participantStateFilters, theme) {
    return {
      fontWeight:
        participantStateFilters.indexOf(stateName) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

const PARTICIPANT_STATES = [
    "REGISTRATION",
    "COACH_MATCHING",
    "WAITING_FOR_COACH_MATCHING_REVIEW",
    "COACH_SELECTION",
    "COACH_ASSIGNMENT",
    "COACHING",
    "COMPLETED",
    "INACTIVE",
    "DROPPED",
    "DELETED",
    "NEEDS_REASSIGNMENT",
    "OPT_OUT"
];

export default function ParticipantList({ clientId }) {
    const sessionState = useContext(SessionContext);
    const theme = useTheme();
    let [participantDataView, setParticipantDataView] = useState([]);
    let [participantData, setParticipantData] = useState(null);
    let [participantsPerPage, setParticipantsPerPage] = useState(5);
    let [participantPage, setParticipantPage] = useState(0);
    let [participantListItemDrawersOpen, setParticipantListItemDrawersOpen] = useState([]);
    let [laborCategories, setLaborCategories] = useState(null);

    let [pagePosition] = useState({x: 0, y: 0, page: 0});

    let [batchAssignHoursDialogOpen, setBatchAssignHoursDialogOpen] = useState(false);
    let [confirmBatchAssignHoursModalOpen, setConfirmBatchAssignHoursModalOpen] = useState(false);
    let [batchCostPerHour, setBatchCostPerHour] = useState(0);
    let [batchHours, setBatchHours] = useState(0); 
    let [batchHoursParticipantIdsList, setBatchHoursParticipantIdsList] = useState([]);
    let [batchHoursProcessing, setBatchHoursProcessing] = useState(false);
    let [batchAllocateHoursToEmptyParticipants, setBatchAllocateHoursToEmptyParticipants] = useState(false);
    let [batchHoursAllocateToRegistration, setBatchHoursAllocateToRegistration] = useState(false);
    let [batchHoursAllocateToCoachMatching, setBatchHoursAllocateToCoachMatching] = useState(true);
    let [batchHoursAllocateToWaitingForCoachMatchReview, setBatchHoursAllocateToWaitingForCoachMatchReview] = useState(true);
    let [batchHoursAllocateToCoaching, setBatchHoursAllocateToCoaching] = useState(true);
    let [batchAssignSnackbarOpen, setBatchAssignSnackbarOpen] = useState(false);
    let [batchAssignSnackbarMessage, setBatchAssignSnackbarMessage] = useState("");

    let [participantStateFilters, setParticipantStateFilters] = useState([]);
    let [participantTextFilter, setParticipantTextFilter] = useState('');
    //let [participantHoursFilters, setParticipantsHoursFilters] = useState([]);
    //let [participantsStringFilters, setParticipantsStringFilters] = useState("");


    useEffect(() => {
        fetch("/api/v1/account/get-client-participants-data/" + clientId).then((res) => {
            return res.json();
        }).then((participantDataResponse) => {
            setParticipantData(participantDataResponse.participantsData);
            for(let i = 0; i < participantDataResponse.participantsData.length; i++) {
                participantListItemDrawersOpen.push(true);
            }
            setParticipantListItemDrawersOpen([...participantListItemDrawersOpen]);
        });
    
        fetch("/api/v1/account/labor-categories/" + clientId).then((res) => {
            return res.json();
        }).then((laborCategoriesResponse) => {
            if(laborCategoriesResponse.laborCategories == null || laborCategoriesResponse.laborCategories.length == 0) {
                setLaborCategories({});
                return;
            }
            let laborCategoriesMap = {};
            for(let i = 0; i < laborCategoriesResponse.laborCategories.length; i++) {
                laborCategoriesMap[laborCategoriesResponse.laborCategories[i].laborCategoryId] = laborCategoriesResponse.laborCategories[i];
            }
            console.log(laborCategoriesMap);
            setLaborCategories(laborCategoriesMap);
        });
    }, []);

    useEffect(() => {
        if(participantData == null || participantData.length == 0 || laborCategories == null)
            return;
        let costPerHour = 0;
        for(let i = 0; i < participantData.length; i++) {
            if(participantData[i].participant.state == "COACHING")
                costPerHour += laborCategories[participantData[i].participant.laborCategoryId].billingRate;
        }
        setBatchCostPerHour(costPerHour);
        setTimeout(() => {
            setParticipantPage(pagePosition.page);
            window.scrollTo(pagePosition.x, pagePosition.y);
        }, 500);
    }, [participantData, laborCategories]);

    function reloadParticipant(participantId) {
        pagePosition.x = window.scrollX;
        pagePosition.y = window.scrollY;
        pagePosition.page = participantPage;
        console.log("saving participant page: ", pagePosition.page, participantPage);
        fetch("/api/v1/account/get-participant-data/" + participantId).then((res) => {
            return res.json();
        }).then((pData) => {
            let updatedParticipantData = [...participantData];
            for(let i = 0; i < updatedParticipantData.length; i++) {
                if(updatedParticipantData[i].participant.accountId == participantId) {
                    updatedParticipantData[i] = pData;
                    break;
                } 
            }
            setParticipantData(updatedParticipantData);
        });
    }

    function reloadParticipants() {
        pagePosition.x = window.scrollX;
        pagePosition.y = window.scrollY;
        pagePosition.page = participantPage;
        console.log("saving participant page: ", pagePosition.page, participantPage);
        fetch("/api/v1/account/get-client-participants-data/" + clientId).then((res) => {
            return res.json();
        }).then((participantDataResponse) => {
            setParticipantData(participantDataResponse.participantsData);
        });
        if(participantListItemDrawersOpen.length != participantData.length) {
            for(let i = 0; i < (participantData.length - participantListItemDrawersOpen.length); i++) {
                participantListItemDrawersOpen.push(true);
            }
            setParticipantListItemDrawersOpen([...participantListItemDrawersOpen]);
        }
    }

    function disableParticipant(participantId) {
        fetch("/api/v1/account/disable-participant/" + participantId, {
            method: "POST"
        }).then((res) => {
            reloadParticipants();
        });
    }

    function ParticipantListItem({ participant, reloadParticipantCallback, drawerState, setDrawerState }) {
        let [collapsed, setCollapsed] = useState(drawerState);
        let [giveHoursDialogOpen, setGiveHoursDialogOpen] = useState(false);
        let [disableAccountDialogOpen, setDisableAccountDialogOpen] = useState(false);
        let [assignHoursValue, setAssignHoursValue] = useState(0.0);
        let [submittingParticipantHoursRequest, setSubmittingParticipantHoursRequest] = useState(false);

        let [resetToCoachMatchingMessageOpen, setResetToCoachMatchingMessageOpen] = useState(false);
        let [resetToCoachMatchingDialogOpen, setResetToCoachMatchingDialogOpen] = useState(false);
        let [resetToCoachMatchingRequestInProgress, setResetToCoachMatchingRequestInProgress] = useState(false);
        let [resetToCoachMatchingMessage, setResetToCoachMatchingMessage] = useState("");

        let [canSendInvitationEmail, setCanSendInvitationEmail] = useState(true);
        let [resentInvitationEmailMessageOpen, setResentInvitationEmailMessageOpen] = useState(false);
        let [resentInvitationEmailMessage, setResentInvitationEmailMessage] = useState("");

        let [canResetOptOut, setCanResetOptOut] = useState(true);
        let [resetOptOutMessageOpen, setResetOptOutMessageOpen] = useState(false);
        let [resetOptOutMessage, setResetOptOutMessage] = useState("");

        let [updatedCoachingHours, setUpdatedCoachingHours] = useState(participant.coachingHours);
        let [reduceCoachingHoursDialogOpen, setReduceCoachingHoursDialogOpen] = useState(false);
        let [reduceCoachingHoursSnackbarOpen, setReduceCoachingHoursSnackbarOpen] = useState(false);
        let [reduceCoachingHoursMessage, setReduceCoachingHoursMessage] = useState("");
        let [reduceCoachingHoursRequestInProgress, setReduceCoachingHoursRequestInProgress] = useState(false);

        let [moreOptionsMenuAnchorEl, setMoreOptionsMenuAnchorEl] = useState(null);
        let moreOptionsMenuOpen = Boolean(moreOptionsMenuAnchorEl);

        let [editModeActive, setEditModeActive] = useState(false);
        let [editedFirstName, setEditedFirstName] = useState(participant.firstName);
        let [editedLastName, setEditedLastName] = useState(participant.lastName);
        let [editedEmail, setEditedEmail] = useState(participant.emailAddress);
        let [editedRequestInProgress, setEditedRequestInProgress] = useState(false);
        let [editedSnackbarOpen, setEditedSnackbarOpen] = useState(false);
        let [editedSnackbarMessage, setEditedSnackbarMessage] = useState("");

        let [manualCoachingHoursDeductionDialogOpen, setManualCoachingHoursDeductionDialogOpen] = useState(false);
        let [manualCoachingHoursMinutes, setManualCoachingHoursMinutes] = useState(30);
        let [manualCoachingHoursSnackbarOpen, setManualCoachingHoursSnackbarOpen] = useState(false);
        let [manualCoachingHoursSnackbarMessage, setManualCoachingHoursSnackbarMessage] = useState("");


        function updateCollasedState(newState) {
            setCollapsed(newState);
            setDrawerState(newState);
        }

        function resendInvitationEmail() {
            setCanSendInvitationEmail(false);
            setTimeout(() => {setCanSendInvitationEmail(true)}, 10000);
            fetch("/api/v1/account/resend-registration-invite/" + participant.participant.accountId, {
                method: "POST"
            }).then((res) => {
                if(res.status == 200) {
                    setResentInvitationEmailMessage("Invitation email resent");
                } else {
                    setResentInvitationEmailMessage("Error while resending invitation email");
                }
                setResentInvitationEmailMessageOpen(true);
            });
        }

        function resetOptOut() {
            setCanResetOptOut(false);
            setTimeout(() => {setCanResetOptOut(true)}, 10000);
            fetch("/api/v1/account/reset-opt-out/" + participant.participant.accountId, {
                method: "POST"
            }).then((res) => {
                if(res.status == 200) {
                    setResetOptOutMessage("Participant reset and registration email sent succesfully.");
                } else {
                    setResetOptOutMessage("Error while resetting participant.");
                }
                setResetOptOutMessageOpen(true);
                reloadParticipantCallback();
            });
        }

        function assignCoachingHours(participant, minutes) {
            setSubmittingParticipantHoursRequest(true);

            let rq = {
                participantId: participant.participant.accountId,
                minutesTotal: 0,
                minutesRemaining: minutes,
                minutesPending: 0,
                minutesUsed: 0
            };

            fetch("/api/v1/account/contract-hours/" + clientId).then((res) => {
                return res.json();
              }).then((contractHoursResponse) => {
                for(let i = 0; i < contractHoursResponse.contractHours.length; i++) {
                    if(contractHoursResponse.contractHours[i].lcatId == participant.participant.laborCategoryId) {
                        if(contractHoursResponse.contractHours[i].hoursAvailable < (minutes / 60)) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                }
              }).then((canAddHours) => {
                if(canAddHours) {
                    fetch("/api/v1/account/add-coaching-hours/" + participant.participant.accountId, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(rq)
                    }).then((res) => {
                        setGiveHoursDialogOpen(false);
                        setAssignHoursValue(0);
                        reloadParticipants();
                        setSubmittingParticipantHoursRequest(false);
                    });
                } else {
                    console.log("Not enough time remaining in labor category hours pool");
                    setSubmittingParticipantHoursRequest(false);
                }
              });
        }

        function resetToCoachMatching() {
            setResetToCoachMatchingRequestInProgress(true);
            fetch("/api/v1/account/reset-participant-to-coach-matching/" + participant.participant.accountId, {
                method: "POST"
            }).then((res) => {
                if(res.status == 200) {
                    setResetToCoachMatchingMessage("Participant successfuly reset to coach matching");
                    setResetToCoachMatchingMessageOpen(true);
                    setResetToCoachMatchingDialogOpen(false);
                } else {
                    setResetToCoachMatchingMessage("Error while resetting participant");
                    setResetToCoachMatchingMessageOpen(true);
                }
                setResetToCoachMatchingRequestInProgress(false);
                reloadParticipantCallback();
            });
        }

        function canSubmitReduceAvailableHours() {
            return (updatedCoachingHours.minutesRemaining >= 0) && (updatedCoachingHours.minutesRemaining < participant.coachingHours.minutesRemaining);
        }

        function reduceAvailableHours() {
            if(updatedCoachingHours == null)
                return;
            setReduceCoachingHoursRequestInProgress(true);
            fetch("/api/v1/account/reduce-coaching-hours", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(updatedCoachingHours)
            }).then((res) => {
                if(res.status == 200) {
                    setReduceCoachingHoursDialogOpen(false);
                    setReduceCoachingHoursMessage("Coaching hours reduced successfully");
                    setReduceCoachingHoursSnackbarOpen(true);
                } else {

                    setReduceCoachingHoursMessage("Error while reducing coaching hours with code: " + res.status);
                    setReduceCoachingHoursSnackbarOpen(true);
                }
                setReduceCoachingHoursRequestInProgress(false);
                reloadParticipantCallback();
            })
        }

        function canSubmitEdits() {
            return (editedFirstName.length > 0 && editedLastName.length > 0 && editedEmail.length > 0) &&
                (editedFirstName != participant.firstName ||
                editedLastName != participant.lastName ||
                editedEmail != participant.emailAddress);
        }

        function submitEdits() {
            if(!canSubmitEdits())
                return;
            let rq = {
                accountId: participant.participant.accountId,
                firstName: editedFirstName,
                lastName: editedLastName,
                emailAddress: editedEmail
            };
            setEditedRequestInProgress(true);
            fetch("/api/v1/account/user-profile", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(rq)
            }).then((res) => {
                if(res.status == 200) {
                    setEditedSnackbarMessage("Participant profile updated successfully");
                    setEditModeActive(false);
                    setEditedSnackbarOpen(true);
                    setEditedRequestInProgress(false);
                    reloadParticipantCallback();
                } else {
                    setEditedSnackbarMessage("Error while updating participant profile with code: " + res.status);
                    setEditedSnackbarOpen(true);
                    setEditedRequestInProgress(false);
                }
            });
        }

        function submitLogManualCoachingHours() {
            const rq = {
                participantId: participant.participant.accountId,
                lengthMinutes: manualCoachingHoursMinutes
            };

            fetch("/api/v1/account/log-manual-coaching-hours", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(rq)
            }).then((res) => {
                if(res.status == 200) {
                    setManualCoachingHoursSnackbarMessage("Coaching session logged. Refresh the page to see the correct hours displayed");
                } else {
                    setManualCoachingHoursSnackbarMessage("Error while logging coaching session with error code: " + res.status);
                }
                setManualCoachingHoursSnackbarOpen(true);
            })
        }

        return (
            <React.Fragment>
                { (participant.participant.laborCategoryId != null && participant.participant.laborCategoryId != 0) &&
                    <Dialog open={giveHoursDialogOpen} onClose={() => setGiveHoursDialogOpen(false)}>
                        <DialogTitle>Assign Coaching Hours</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Cost: ${((assignHoursValue * laborCategories[participant.participant.laborCategoryId]["billingRate"]) / 100.0).toFixed(2)}
                            </DialogContentText>
                            <TextField
                              autoFocus
                              required
                              type="number"
                              margin="dense"
                              label="Number of hours"
                              fullWidth
                              variant="standard"
                              value={assignHoursValue}
                              onChange={(event) => setAssignHoursValue(event.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setGiveHoursDialogOpen(false)}>Cancel</Button>
                            <Button disabled={assignHoursValue < 1} loading={submittingParticipantHoursRequest} onClick={() => assignCoachingHours(participant, Math.round(assignHoursValue * 60))}>Submit</Button>
                        </DialogActions>
                    </Dialog>
                }  
                <Snackbar open={resetToCoachMatchingMessageOpen} autoHideDuration={6000} onClose={() => setResetToCoachMatchingMessageOpen(false)} message={resetToCoachMatchingMessage} />
                <Snackbar open={resentInvitationEmailMessageOpen} autoHideDuration={6000} onClose={() => setResentInvitationEmailMessageOpen(false)} message={resentInvitationEmailMessage} />
                <Snackbar open={resetOptOutMessageOpen} autoHideDuration={6000} onClose={() => setResetOptOutMessageOpen(false)} message={resetOptOutMessage} />
                <Snackbar open={reduceCoachingHoursSnackbarOpen} autoHideDuration={6000} onClose={() => setReduceCoachingHoursSnackbarOpen(false)} message={reduceCoachingHoursMessage} />
                <Snackbar open={editedSnackbarOpen} autoHideDuration={6000} onClose={() => setEditedSnackbarOpen(false)} message={editedSnackbarMessage} />
                <Snackbar open={manualCoachingHoursSnackbarOpen} autoHideDuration={6000} onClose={() => setManualCoachingHoursSnackbarOpen(false)} message={manualCoachingHoursSnackbarMessage} />
                { updatedCoachingHours != null && <Dialog open={reduceCoachingHoursDialogOpen} onClose={() => setReduceCoachingHoursDialogOpen(false)}>
                    <DialogTitle>Reduce Available Coaching Hours</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            required
                            type="number"
                            margin="dense"
                            label="Hours Available"
                            fullWidth
                            variant="standard"
                            value={updatedCoachingHours.minutesRemaining / 60}
                            onChange={(event) => {let uch = {...updatedCoachingHours}; uch.minutesRemaining = Number(event.target.value)*60; setUpdatedCoachingHours(uch);}}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setReduceCoachingHoursDialogOpen(false)}>Cancel</Button>
                        <Button disabled={!canSubmitReduceAvailableHours()} loadingPosition="start" loading={reduceCoachingHoursRequestInProgress} onClick={() => reduceAvailableHours()}>Confirm</Button>
                    </DialogActions>
                </Dialog> }
                <Dialog open={resetToCoachMatchingDialogOpen} onClose={() => setResetToCoachMatchingDialogOpen(false)}>
                    <DialogTitle>Reset To Coach Matching</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Warning: This will remove the participant's coach (if they have one), reset the participant to coach matching review, and re-open their coaching match review request.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setResetToCoachMatchingDialogOpen(false)}>Cancel</Button>
                        <Button loading={resetToCoachMatchingRequestInProgress} loadingPosition="start" onClick={() => resetToCoachMatching()}>Confirm</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={disableAccountDialogOpen} onClose={() => setDisableAccountDialogOpen(false)}>
                    <DialogTitle>Disable Account</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to disable ths account ({participant.emailAddress})?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDisableAccountDialogOpen(false)}>Cancel</Button>
                        <Button onClick={() => {disableParticipant(participant.participant.accountId); setDisableAccountDialogOpen(false)}}>Confirm</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={manualCoachingHoursDeductionDialogOpen} onClose={() => setManualCoachingHoursDeductionDialogOpen(false)}>
                    <DialogTitle>Manual Coaching Hours Deduction</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            This will deduct the entered hours from the participants available hours and register it as a completed coaching session for the coach.
                        </DialogContentText>
                        <Select value={manualCoachingHoursMinutes} onChange={(e) => setManualCoachingHoursMinutes(e.target.value)}>
                            <MenuItem value={30}>30 Minutes</MenuItem>
                            <MenuItem value={45}>45 Minutes</MenuItem>
                            <MenuItem value={60}>1 Hour</MenuItem>
                            <MenuItem value={75}>1 Hour 15 Minutes</MenuItem>
                            <MenuItem value={90}>1 Hour 30 Minutes</MenuItem>
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setManualCoachingHoursDeductionDialogOpen(false)}>Cancel</Button>
                        <Button onClick={() => submitLogManualCoachingHours()}>Submit</Button>
                    </DialogActions>
                </Dialog>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <StyledTableCell><IconButton onClick={() => updateCollasedState(!collapsed)}>{collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</IconButton></StyledTableCell>
                    <StyledTableCell><Avatar alt={participant.firstName + " " + participant.lastName} src={"/api/v1/account/profile-picture/" + participant.participant.accountId} /></StyledTableCell>
                    { editModeActive && 
                        <React.Fragment> 
                            <StyledTableCell>
                                <TextField
                                  label="First Name"
                                  defaultValue={editedFirstName}
                                  onChange={(e) => setEditedFirstName(e.target.value)}
                                  size="small"
                                  variant="standard"
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <TextField
                                  label="Last Name"
                                  defaultValue={editedLastName}
                                  onChange={(e) => setEditedLastName(e.target.value)}
                                  size="small"
                                  variant="standard"
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <TextField
                                  label="Email Address"
                                  defaultValue={editedEmail}
                                  onChange={(e) => setEditedEmail(e.target.value)}
                                  size="small"
                                  variant="standard"
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <Stack direction="row">
                                    <Button 
                                      size="small" 
                                      onClick={() => setEditModeActive(false)}>
                                        Cancel
                                    </Button>
                                    <Button 
                                      size="small" 
                                      variant="contained"
                                      disabled={!canSubmitEdits()} 
                                      loading={editedRequestInProgress}
                                      loadingPosition="start"
                                      onClick={() => submitEdits()}>
                                        Submit
                                    </Button>
                                </Stack>
                            </StyledTableCell>
                        </React.Fragment> }
                    { !editModeActive && 
                        <React.Fragment> 
                            <StyledTableCell>{participant.firstName}</StyledTableCell>
                            <StyledTableCell>{participant.lastName}</StyledTableCell>
                            <StyledTableCell>{participant.emailAddress}</StyledTableCell>
                            <StyledTableCell>{participant.coachingHours != null ? (participant.coachingHours.minutesRemaining / 60.0).toFixed(1) : 0.0}/{participant.coachingHours != null ? (participant.coachingHours.minutesTotal / 60.0).toFixed(1) : 0.0}</StyledTableCell>
                        </React.Fragment> }
                    <StyledTableCell>
                        <IconButton onClick={(e) => setMoreOptionsMenuAnchorEl(e.currentTarget)}>
                            <MoreVertIcon/>
                        </IconButton>
                        <Menu dense anchorEl={moreOptionsMenuAnchorEl} open={moreOptionsMenuOpen} onClick={(e) => setMoreOptionsMenuAnchorEl(null)} onClose={(e) => setMoreOptionsMenuAnchorEl(null)}>
                            <MenuItem onClick={(e) => {setMoreOptionsMenuAnchorEl(null); setEditModeActive(true)}}>Edit</MenuItem>
                            { (participant.participant.state == 'COACHING' || participant.participant.state == 'COACH_MATCHING' || participant.participant.state == 'COACH_MATCH_REVIEW') && <MenuItem onClick={(e) => {setMoreOptionsMenuAnchorEl(null); setGiveHoursDialogOpen(true);}}>Allocate Hours</MenuItem>}
                            { updatedCoachingHours!= null && <MenuItem onClick={(e) => {setMoreOptionsMenuAnchorEl(null); setReduceCoachingHoursDialogOpen(true);}}>Reduce Hours Allocation</MenuItem>}
                            { (participant.participant.state != 'REGISTRATION' && participant.participant.state != 'DELETED' && participant.participant.state != 'OPT_OUT') && <MenuItem onClick={(e) => {setMoreOptionsMenuAnchorEl(null); setResetToCoachMatchingDialogOpen(true);}}>Reset to Coach Matching</MenuItem>}
                            { participant.participant.state == "REGISTRATION" && <MenuItem disabled={!canSendInvitationEmail} onClick={(e) => {setMoreOptionsMenuAnchorEl(null); resendInvitationEmail();}}>Resend Invitation</MenuItem>}
                            <MenuItem onClick={(e) => {setMoreOptionsMenuAnchorEl(null); setDisableAccountDialogOpen(true);}}>Disable Account</MenuItem>
                            { (participant.participant.state == 'OPT_OUT') && <MenuItem onClick={(e) => {setMoreOptionsMenuAnchorEl(null); resetOptOut();}}>Re-enable Account</MenuItem> }
                            { (participant.participant.state == 'COACHING') && <MenuItem onClick={(e) => setManualCoachingHoursDeductionDialogOpen(true)}>Manual Session Entry</MenuItem> }
                        </Menu>
                    </StyledTableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={7} style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <Collapse in={!collapsed} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Table stickyHeader size="small">
                                    <TableHead>
                                        <StyledTableCell>State</StyledTableCell>
                                        <StyledTableCell>Labor Category</StyledTableCell>
                                        <StyledTableCell>Coaching Hours Used</StyledTableCell>
                                        <StyledTableCell>Coaching Hours Pending</StyledTableCell>
                                        <StyledTableCell>Coaching Hours Available</StyledTableCell>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell>{participant.participant.state}</StyledTableCell>
                                            <StyledTableCell>{(participant.participant.laborCategoryId != null && participant.participant.laborCategoryId != 0) ? laborCategories[participant.participant.laborCategoryId]["laborCategoryName"] : "N/A"}</StyledTableCell>
                                            <StyledTableCell>{participant.coachingHours != null ? (participant.coachingHours.minutesUsed / 60.0).toFixed(1) : 0.0}</StyledTableCell>
                                            <StyledTableCell>{participant.coachingHours != null ? (participant.coachingHours.minutesPending / 60.0).toFixed(1) : 0.0}</StyledTableCell>
                                            <StyledTableCell>{participant.coachingHours != null ? (participant.coachingHours.minutesRemaining / 60.0).toFixed(1) : 0.0}</StyledTableCell>        
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    function cancelBatchAssignHours() {
        setBatchHoursProcessing(false);
        setBatchHoursParticipantIdsList([]);
        setBatchHoursAllocateToRegistration(false);
        setBatchHoursAllocateToCoachMatching(false);
        setBatchHoursAllocateToWaitingForCoachMatchReview(false);
        setBatchHoursAllocateToCoaching(false);
    }

    function applyFiltersForBatchAssignHoursTargets() {
        let stateFilters = [];
        if(batchHoursAllocateToRegistration)
            stateFilters.push("REGISTRATION");
        if(batchHoursAllocateToCoachMatching)
            stateFilters.push("COACH_MATCHING");
        if(batchHoursAllocateToWaitingForCoachMatchReview)
            stateFilters.push("WAITING_FOR_COACH_MATCHING_REVIEW");
        if(batchHoursAllocateToCoaching)
            stateFilters.push("COACHING");

        let participantIds = [];
        for(let i = 0; i < participantData.length; i++) {
            if(stateFilters.includes(participantData[i].participant.state)) {
                if(batchAllocateHoursToEmptyParticipants) {
                    if(participantData[i].coachingHours == null)
                        participantIds.push(participantData[i].participant.accountId);
                    else if(participantData[i].coachingHours.minutesRemaining == 0)
                        participantIds.push(participantData[i].participant.accountId);
                } else {
                    participantIds.push(participantData[i].participant.accountId);
                }
            }
        }
        setBatchHoursParticipantIdsList(participantIds);
    }

    function sendBatchAssignHoursRequest() {
        let rq = {
            participantIds: batchHoursParticipantIdsList,
            hours: batchHours,
            clientId: clientId
        };
        setBatchHoursProcessing(true);
        fetch("/api/v1/account/batch-add-coaching-hours", {
            method: "POST", 
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(rq)
        }).then((res) => {
            if(res.status == 200) {
                setBatchAssignSnackbarMessage("Batch assign hours submitted successfully");
                reloadParticipants();
                cancelBatchAssignHours();
                setConfirmBatchAssignHoursModalOpen(false);
            } else {
                setBatchAssignSnackbarMessage("Error while processing batch assign hours with response code: " + res.status);
            }
            setBatchAssignSnackbarOpen(true);
            setBatchHoursProcessing(false);
        });
    }

    useEffect(() => {
        if(participantData == null)
            return;
        /*let view = [];
        for(let i = 0; i < participantData.length; i++) {
            if(participantStateFilters.length == 0)
                view.push(participantData[i]);
            else if(participantStateFilters.indexOf(participantData[i].participant.state) > -1)
                view.push(participantData[i]);
        }*/
        let view = [...participantData];
        if(participantStateFilters.length > 0)
            view = view.filter((p) => {
                return participantStateFilters.indexOf(p.participant.state) > -1;
            });
        if(participantTextFilter.length > 0)
            view = view.filter((p) => {
                return p.emailAddress.toLowerCase().includes(participantTextFilter.toLowerCase()) || p.firstName.toLowerCase().includes(participantTextFilter.toLowerCase()) || p.lastName.toLowerCase().includes(participantTextFilter.toLowerCase());
            });
        setParticipantPage(0);
        setParticipantDataView(view);
    }, [participantStateFilters, participantTextFilter, participantData]);

    function updateParticipantStateFilters(event) {
        const {
            target: { value },
        } = event;
        setParticipantStateFilters(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
   
    if(participantData == null || laborCategories == null) {
        return (
            <DashboardElement elevation={1}>
                <Stack direction="column" spacing={0.5}>
                    <Box sx={{ width: "100%" }}>
                        <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Participants</Typography>
                    </Box>
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                </Stack>
            </DashboardElement>
        );
    } else if(participantData.length == 0) {
        return(
            <DashboardElement elevation={1}>
                <Stack direction="column" sx={{ width:"100%", mt:"15px", mb:"15px"}}>
                    <Typography>This client currently does not have any participants.</Typography>
                    <Stack direction="row" spacing={ 0.5 } alignItems="center" justifyContent="center">
                        <InviteParticipantsButtons clientId={ clientId } />
                    </Stack>
                </Stack>
            </DashboardElement>
        );
    } else {
        return (
            <DashboardElement elevation={1}>
                <Snackbar open={batchAssignSnackbarOpen} autoHideDuration={6000} onClose={() => setBatchAssignSnackbarOpen(false)} message={batchAssignSnackbarMessage} />
                <Modal open={confirmBatchAssignHoursModalOpen} onClose={() => { setConfirmBatchAssignHoursModalOpen(false) }}>
                    <ModalDialog>
                        <Stack direction="column" alignItems="center" justifyContent="center" sx={{ width: "100%", mt: "10px" }} spacing={ 1 }>
                            <Box sx={{ width: "100%", maxHeight: "500px", display: "flex", flexDirection: "column", alignItems: "flex-end", overflow: "hidden", overflowY: "scroll" }}>
                                <TableContainer sx={{ overflowx: "initial" }}>
                                    <Table sx={{ minWidth: 725 }} stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Email</TableCell>
                                                <TableCell align="right">First Name</TableCell>
                                                <TableCell align="right">Last Name</TableCell>
                                                <TableCell align="right">Current Avail. Hrs.</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {batchHoursParticipantIdsList.length == 0 &&
                                                <TableRow>
                                                    <StyledTableCell colspan={4}>No participants matched your chosen filters</StyledTableCell>
                                                </TableRow>
                                            }
                                            {participantData.filter((participant) => {
                                                return batchHoursParticipantIdsList.includes(participant.participant.accountId);
                                            }).map((participant) => {
                                                return (
                                                    <TableRow>
                                                        <StyledTableCell>{participant.emailAddress}</StyledTableCell>
                                                        <StyledTableCell>{participant.firstName}</StyledTableCell>
                                                        <StyledTableCell>{participant.lastName}</StyledTableCell>
                                                        <StyledTableCell>{participant.coachingHours != null ? (participant.coachingHours.minutesRemaining / 60.0).toFixed(1) : 0.0}</StyledTableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Stack direction="row" spacing={0.25}>
                                <Button onClick={() => {setConfirmBatchAssignHoursModalOpen(false); setBatchAssignHoursDialogOpen(true);}}>Back</Button>
                                <Button disabled={batchHoursProcessing} onClick={() => {cancelBatchAssignHours(); setConfirmBatchAssignHoursModalOpen(false);}}>Cancel</Button>
                                <Button disabled={batchHoursParticipantIdsList.length == 0 || batchHoursProcessing} loading={batchHoursProcessing} onClick={() => sendBatchAssignHoursRequest()}>Submit</Button>
                            </Stack>
                        </Stack>
                    </ModalDialog>
                </Modal>
                <Dialog open={batchAssignHoursDialogOpen} onClose={() => setBatchAssignHoursDialogOpen(false)}>
                    <DialogTitle>Batch allocate coaching hours to all Participants</DialogTitle>
                    <DialogContent>
                        {/*<DialogContentText>
                            Cost: ${((batchCostPerHour * batchHours) / 100.0).toFixed(2)}
                        </DialogContentText>*/}
                        <FormControlLabel control={
                          <Switch checked={batchAllocateHoursToEmptyParticipants} onChange={(e) => setBatchAllocateHoursToEmptyParticipants(e.target.checked)} />
                          } label="Only Allocate Hours to Participants With 0 Available Hours" />
                        <FormControl component="fieldset" variant="standard">
                            <FormLabel component="legend">Assign Hours to Participants With States:</FormLabel>
                            <FormGroup>
                                <FormControlLabel 
                                  control={<Switch checked={batchHoursAllocateToRegistration} onChange={(e) => setBatchHoursAllocateToRegistration(e.target.checked)}/>}
                                  label="Registration"/>
                                <FormControlLabel 
                                  control={<Switch checked={batchHoursAllocateToCoachMatching} onChange={(e) => setBatchHoursAllocateToCoachMatching(e.target.checked)} />}
                                  label="Coach Matching"/>
                                <FormControlLabel 
                                  control={<Switch checked={batchHoursAllocateToWaitingForCoachMatchReview} onChange={(e) => setBatchHoursAllocateToWaitingForCoachMatchReview(e.target.checked)}/>} 
                                  label="Waiting for Coach Match Review"/>
                                <FormControlLabel 
                                  control={<Switch checked={batchHoursAllocateToCoaching} onChange={(e) => setBatchHoursAllocateToCoaching(e.target.checked)} />} 
                                  label="Coaching"/>
                            </FormGroup>
                        </FormControl>
                        <TextField
                            autoFocus
                            required
                            type="number"
                            margin="dense"
                            label="Number of hours"
                            fullWidth
                            variant="standard"
                            value={batchHours}
                            onChange={(event) => setBatchHours(event.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {cancelBatchAssignHours(); setConfirmBatchAssignHoursModalOpen(false);}}>Cancel</Button>
                        <Button disabled={batchHours < 1} onClick={() => {applyFiltersForBatchAssignHoursTargets(); setBatchAssignHoursDialogOpen(false); setConfirmBatchAssignHoursModalOpen(true);}}>Continue</Button>
                    </DialogActions>
                </Dialog>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ width: "100%", maxHeight: "1000px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                        <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
                            <Box sx={{ width: "100%" }}>
                                <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Participants</Typography>
                            </Box>
                            <TableContainer sx={{ overflowx: "initial" }} component={Paper}>
                                <Table sx={{ minWidth: "550px" }} stickyHeader size="small">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell colspan={2}>
                                                <FormControl sx={{ m: 1, width: '300px', maxWidth: '300px' }}>
                                                    <InputLabel>Participant State Filters</InputLabel>
                                                    <Select 
                                                      multiple
                                                      value={participantStateFilters}
                                                      onChange={(event) => updateParticipantStateFilters(event)}
                                                      input={<OutlinedInput label="Participant State Filters" />}
                                                      MenuProps={MenuProps}>
                                                        {PARTICIPANT_STATES.map((stateName) => {
                                                            return (
                                                                <MenuItem key={stateName} value={stateName} style={getStyles(stateName, participantStateFilters, theme)}>
                                                                    {stateName}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </StyledTableCell>
                                            <StyledTableCell colspan={5}>
                                                <TextField sx={{ width: '100%' }} label="Search" type="search" variant='outlined' value={participantTextFilter} onChange={(e) => setParticipantTextFilter(e.target.value)} />
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell>{/* collapse button */}</StyledTableCell>
                                            <StyledTableCell>{/* Participant profile picture */}</StyledTableCell>
                                            <StyledTableCell>First Name</StyledTableCell>
                                            <StyledTableCell>Last Name</StyledTableCell>
                                            <StyledTableCell>Email</StyledTableCell>
                                            <StyledTableCell>Coaching Hours</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { participantDataView.slice(participantPage * participantsPerPage, participantPage * participantsPerPage + participantsPerPage).map((participant, index) => {
                                            return(
                                                <ParticipantListItem 
                                                  participant={participant} 
                                                  key={index} 
                                                  reloadParticipantCallback={() => reloadParticipant(participant.participant.accountId)} 
                                                  drawerState={participantListItemDrawersOpen[index]}
                                                  setDrawerState={(state) => {/*participantListItemDrawersOpen[index] = state*/}} />
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack direction="row" spacing={ 2 } justifyContent="space-between">
                                <Box overflow="hidden" alignItems="center" justifyContent="center">
                                    <Stack direction="row" sx={{ overflowY: "auto", overflowX: "auto" }} spacing={ 0.5 } alignItems="center" justifyContent="center">
                                        <InviteParticipantsButtons clientId={ clientId } />
                                        <Chip label="Batch Assign Hours" onClick={ () => setBatchAssignHoursDialogOpen(true) } color="primary" icon={<MoreTimeIcon />} />
                                    </Stack>
                                </Box>
                                <TablePagination 
                                    rowsPerPageOptions={[5, 10 , 25]}
                                    component="div"
                                    count={participantDataView.length}
                                    rowsPerPage={participantsPerPage}
                                    page={participantPage}
                                    onPageChange={(event, pageNum) => setParticipantPage(pageNum)}
                                    onRowsPerPageChange={(e) => {setParticipantsPerPage(e.target.value); setParticipantPage(0);}} />
                            </Stack>
                        </Stack>
                    </Box>
                </LocalizationProvider>
            </DashboardElement>
        );
    }
}