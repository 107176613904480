import { useState, useEffect, useContext } from 'react';
import { SessionContext } from 'Contexts';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
  Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
  Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Skeleton, Tooltip, 
  ThemeProvider, IconButton } from '@mui/material';
  
import EditIcon from '@mui/icons-material/Edit';

export default function ParticipantProfileBanner() {
    const sessionState = useContext(SessionContext);

    useEffect(() => {
    }, []);

    return(
        <Box sx={{ width: "100%", height: "22vh", bgcolor: "#0A4B90", borderBottom: 1, borderColor: "#7b92ab" }}>
            <Stack spacing={ 2 } direction="row" justifyContent="flex-start" alignItems="center" sx={{ height: "98%", width: "100%", ml: "2%" }}>
                <Avatar alt={sessionState.name} src={"/api/v1/account/profile-picture/" + sessionState.accountId} sx={{ height: "19vh", width: "19vh" }} />
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={ 5 } sx={{ width: "100%", height: "100%" }}>
                    <Stack direction="column" alignItems="flex-start" sx={{ width: "45%", height: "100%" }}>
                        <Stack direction="row">
                            <Typography variant="h3" component="h1" sx={{ color: "white" }}>{sessionState.name}</Typography>
                        </Stack>
                        <Typography sx={{ color: "white" }}>Participant</Typography>
                        <Typography sx={{ color: "white" }}>{sessionState.clientName}</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
}