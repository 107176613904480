import React, { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { SessionContext } from 'Contexts';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider, Checkbox,
    FormGroup, TableContainer, Table, TableRow, TableBody, TableHead, IconButton, ListItemText,
    OutlinedInput, InputAdornment, Input, Collapse, Skeleton, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import utc from 'dayjs/plugin/utc';
import DashboardElement from 'pageElements/DashboardElement';

import EventIcon from '@mui/icons-material/Event';

dayjs.extend(utc);


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MenuBarButton = styled(Button)(({ theme }) => ({
    color: "white",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#6c89b8"
    }
}));


export default function ScheduleCoachingSessionButton() {
    const sessionState = useContext(SessionContext);
    let [scheduleNewSessionModalIsOpen, setScheduleNewSessionModalIsOpen] = useState(false);
    let [newCoachingSessionDate, setNewCoachingSessionDate] = useState(dayjs());
    let [selectedSession, setSelectedSession] = useState(null);
    let [acceptSessionTimeDialogOpen, setAcceptSessionTimeDialogOpen] = useState(false);
    let [coachingHours, setCoachingHours] = useState(null);
    let [availabileTimeSlotsByDate, setAvailabileTimeSlotsByDate] = useState(new Map());
    let [sessionLength, setSessionLength] = useState(30);

    useEffect(() => {
        refreshCoachingHours();
        getAllAvailableSessionSlots();
    }, []);

    useEffect(() => {
        setAvailabileTimeSlotsByDate(new Map());
        getAllAvailableSessionSlots();
    }, [sessionLength]);

    function refreshCoachingHours() {
        fetch("/api/v1/account/hours/" + sessionState.participant.accountId).then((res) => {
            return res.json();
        }).then((coachingHoursResponse) => {
            setCoachingHours(coachingHoursResponse);
        });
    }

    function getAllAvailableSessionSlots() {
        let rq = {
            date: newCoachingSessionDate.utc().format(),
            slotLengthMinutes: sessionLength,
            span: -1,
            coachId: sessionState.participant.coachId
        };
        fetch("/api/v1/account/coach-availability-span", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(rq)
        }).then((res) => {
            return res.json();
        }).then((coachAvailability) => {
            let availabilityByDate = new Map();

            for(let i = 0; i < coachAvailability.availableSlots.length; i++) {
                let slotStartTimeLocal = dayjs(coachAvailability.availableSlots[i].startTime).local();
                let key = slotStartTimeLocal.format('YYYY-MM-DD');
                if(!availabilityByDate.has(key)) {
                    availabilityByDate.set(key, []);
                } 
                availabilityByDate.get(key).push(coachAvailability.availableSlots[i]);
            }

            setAvailabileTimeSlotsByDate(availabilityByDate);
        });
    }

    function createCoachingSession() {
        let rq = {
            meetingName: "Coaching session: " + dayjs(selectedSession.startTime).local().format(),
            participantAccountId: sessionState.accountId,
            coachAccountId: sessionState.participant.coachId,
            scheduledStartTime: dayjs(selectedSession.startTime).utc().format(),
            meetingLength: selectedSession.slotLength
        }
        fetch("/api/v1/account/schedule", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(rq)
        }).then((res) => {
            if(res.status == 200)
                window.location.reload();
            else {
                // Show error message
            }
        });
    }

    function coachHasDateAvailability(date) {
        return !availabileTimeSlotsByDate.has(date.format('YYYY-MM-DD'));
    }

    if(coachingHours == null){
        return (
            <Skeleton />
        );
    } else {
        return (
            <React.Fragment>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Dialog open={acceptSessionTimeDialogOpen} onClose={() => setAcceptSessionTimeDialogOpen(false)}>
                        <DialogTitle>Schedule this coaching session?</DialogTitle>
                        <DialogActions>
                            <Button color="error" onClick={() => setAcceptSessionTimeDialogOpen(false)}>No</Button>
                            <Button onClick={() => createCoachingSession()}>Yes</Button>
                        </DialogActions>
                    </Dialog>
                    <Modal open={scheduleNewSessionModalIsOpen} onClose={() => { setScheduleNewSessionModalIsOpen(false) }} >
                        <ModalDialog>
                            <ModalClose />
                            <DialogTitle>Schedule a Coaching Session</DialogTitle>
                            { (coachingHours.minutesRemaining < 30) &&
                                <Stack direction="column">
                                    <Typography>Coaching time available: {coachingHours.minutesRemaining} min.</Typography>
                                    <Typography>You currently do not have enough time availabile to schedule a coaching session.</Typography>
                                </Stack>
                            }
                            { (coachingHours.minutesRemaining >= 30) &&
                                <Grid container spacing={2}>
                                    <Grid item sx={11}>
                                        <Typography>You have {coachingHours.minutesRemaining} min. of coaching time remaining</Typography>
                                    </Grid>
                                    <Grid item sx={6}>
                                        <Avatar alt="coach" src={"/api/v1/account/profile-picture/" + sessionState.participant.coachId} />
                                    </Grid>
                                    <Grid item sx={5}>
                                        <FormControl variant="standard">
                                            <InputLabel>Session Length</InputLabel>
                                            <Select value={sessionLength} onChange={(event) => setSessionLength(event.target.value)}>
                                                <MenuItem value={30}>30 Minutes</MenuItem>
                                                { coachingHours.minutesRemaining >= 45 && <MenuItem value={45}>45 Minutes</MenuItem> }
                                                { coachingHours.minutesRemaining >= 60 && <MenuItem value={60}>1 Hour</MenuItem> }
                                                { coachingHours.minutesRemaining >= 75 && <MenuItem value={75}>1 Hour 15 Minutes</MenuItem> }
                                                { coachingHours.minutesRemaining >= 90 && <MenuItem value={90}>1 Hour 30 Minutes</MenuItem> }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography>Date</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>Availability</Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <DateCalendar 
                                            minDate={dayjs()} 
                                            maxDate={dayjs().add(365, 'day')}
                                            shouldDisableDate={coachHasDateAvailability}
                                            value={newCoachingSessionDate} 
                                            onChange={(newValue) => {setNewCoachingSessionDate(newValue)}} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box sx={{ height: "40vh", overflow: "auto"}}>
                                            <Stack spacing={ 2.0 } direction="column">
                                                {
                                                    availabileTimeSlotsByDate.has(newCoachingSessionDate.format('YYYY-MM-DD')) &&
                                                    availabileTimeSlotsByDate.get(newCoachingSessionDate.format('YYYY-MM-DD')).map((slot, index) => {
                                                        let datetime = dayjs(slot.startTime).local();
                                                        let hour = datetime.hour();
                                                        if(hour > 12)
                                                            hour -= 12;
                                                        hour = hour.toString();
                                                        let minute = datetime.minute().toString();
                                                        if(minute.length == 1)
                                                            minute = '0' + minute;
                                                        return (
                                                            <Button
                                                                onClick={() => {setSelectedSession(slot); setAcceptSessionTimeDialogOpen(true);}}>
                                                                {hour}:{minute} {datetime.hour() >= 12 ? 'PM' : 'AM'}
                                                            </Button>
                                                        );
                                                })}
                                                { availabileTimeSlotsByDate.keys().toArray().length == 0 && 
                                                    <React.Fragment>
                                                        <Skeleton variant="text" sx={{ fontSize: '2.5rem' }} />
                                                        <Skeleton variant="text" sx={{ fontSize: '2.5rem' }} />
                                                        <Skeleton variant="text" sx={{ fontSize: '2.5rem' }} />
                                                    </React.Fragment>
                                                }
                                                {
                                                    !availabileTimeSlotsByDate.has(newCoachingSessionDate.format('YYYY-MM-DD')) &&
                                                        <Typography>No Availability</Typography>
                                                }
                                            </Stack>
                                        </Box>
                                    </Grid>
                                </Grid> 
                            }
                        </ModalDialog>
                    </Modal>
                    <MenuBarButton startIcon={<EventIcon />} onClick={() => setScheduleNewSessionModalIsOpen(true)}>Schedule Coaching Session</MenuBarButton>
                </LocalizationProvider>
            </React.Fragment>
        );
    }
}