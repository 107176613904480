import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import { SessionContext } from 'Contexts';
import dayjs from 'dayjs';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia, Checkbox,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Input, InputAdornment, Pagination,
    Table, TableBody, TableContainer, TableHead, TablePagination, TableRow,
    Toolbar, IconButton, Switch, Skeleton, FilledInput, Dialog, DialogContent, DialogContentText, 
    DialogActions, DialogTitle } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';

import DashboardElement from 'pageElements/DashboardElement';
import DashboardLayout from 'pageElements/DashboardLayout';
import DashboardBody from 'pageElements/DashboardBody';
import DashboardMenuBar from 'pageElements/DashboardMenuBar';

import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const MenuBarButton = styled(Button)(({ theme }) => ({
    color: "white",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#6c89b8"
    }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));


export default function ServiceRequest() {
    const sessionState = useContext(SessionContext);
    let { serviceRequestId } = useParams();

    const [report, setReport] = useState(null);
    const [comments, setComments] = useState([]);

    const [newCommentText, setNewCommentText] = useState("");

    const [submitterProfile, setSubmitterProfile] = useState(null);

    const [updateStatusDialogOpen, setUpdateStatusDialogOpen] = useState(false);
    const [statusUpdateText, setStatusUpdateText] = useState("");
    const [updateOpenStateDialogOpen, setUpdateOpenStateDialogOpen] = useState(false);
    const [openStateUpdateValue, setOpenStateUpdateValue] = useState("");

    useEffect(() => {
        if(sessionState.accountType == "ADMIN" && report != null) {
            fetch("/api/v1/account/user-profile/" + report.submitter).then((res) => {
                return res.json();
            }).then((userProfile) => {
                setSubmitterProfile(userProfile);
            })
        }
    }, [report]);

    useEffect(() => {
        loadReport();

        fetch("/api/v1/account/service-report-comments/" + serviceRequestId).then((res) => {
            return res.json();
        }).then((reportCommentsRes) => {
            setComments(reportCommentsRes.comments);
        });
    }, []);

    function loadReport() {
        fetch("/api/v1/account/service-report/" + serviceRequestId).then((res) => {
            return res.json();
        }).then((reportRes) => {
            setReport(reportRes);
        });
    }

    function postNewComment() {
        if(newCommentText == "")
            return;

        let rq = {
            comment: newCommentText,
            reportId: serviceRequestId
        };

        fetch("/api/v1/account/service-report-comment", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(rq)
        }).then((res => {
            reloadComments();
            setNewCommentText("");
        }));
    }

    function reloadComments() {
        fetch("/api/v1/account/service-report-comments/" + serviceRequestId).then((res) => {
            return res.json();
        }).then((reportCommentsRes) => {
            setComments(reportCommentsRes.comments);
        });
    }

    function updateStatus() {
        fetch("/api/v1/account/service-report-status/" + serviceRequestId + "/" + statusUpdateText, {
            method: "POST"
        }).then((res) => {
            loadReport();
            setUpdateStatusDialogOpen(false);
        });
    }

    function updateOpenState() {
        fetch("/api/v1/account/service-report-open-state/" + serviceRequestId + "/" + openStateUpdateValue, {
            method: "POST"
        }).then((res) => {
            loadReport();
            setUpdateOpenStateDialogOpen(false);
        });
    }

    return (
        <DashboardLayout>
            <DashboardMenuBar>
                <MenuBarButton onClick={() => {window.location.href = "/"}}>Dashboard</MenuBarButton>
            </DashboardMenuBar>
            <DashboardBody>
                <DashboardElement elevation={0}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        { report != null && <React.Fragment>
                            <Dialog open={updateStatusDialogOpen} onClose={() => setUpdateStatusDialogOpen(false)}>
                                <DialogTitle>Status Update</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        The user who submitted this ticket will be notified of this status update. 
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        required
                                        label="New Status"
                                        fullWidth
                                        variant="standard"
                                        value={statusUpdateText}
                                        onChange={(event) => setStatusUpdateText(event.target.value)}
                                        />
                                </DialogContent>
                                <DialogActions>
                                    <Button disabled={statusUpdateText=="" || statusUpdateText==report.status} onClick={() => {updateStatus()}}>Submit</Button>
                                </DialogActions>
                            </Dialog> 
                            <Dialog open={updateOpenStateDialogOpen} onClose={() => setUpdateOpenStateDialogOpen(false)}>
                                <DialogTitle>Open State Update</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        The user who submitted this ticket will be notified of this open state change. 
                                    </DialogContentText>
                                    <Select
                                    value={openStateUpdateValue}
                                    label="New Open State"
                                    onChange={(e) => setOpenStateUpdateValue(e.target.value)}>
                                        <MenuItem value="closed">Closed</MenuItem>
                                        <MenuItem value="open">Open</MenuItem>
                                    </Select>
                                </DialogContent>
                                <DialogActions>
                                    <Button disabled={openStateUpdateValue=="" || openStateUpdateValue==report.open} onClick={() => {updateOpenState()}}>Submit</Button>
                                </DialogActions>
                            </Dialog>
                        </React.Fragment> }
                        <Stack direction="column" spacing={1.0} sx={{  width: "100%", mt: "10px", mb: "10px" }}>
                            { report != null && <TableContainer sx={{ verflowx: "initial" }} component={Paper}>
                                <Table>
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell align="left">Report Id</StyledTableCell>
                                            <StyledTableCell align="right">Impact Severity</StyledTableCell>
                                            <StyledTableCell align="right">Open State{sessionState.accountType == "ADMIN" && <IconButton onClick={() => setUpdateOpenStateDialogOpen(true)}><EditIcon /></IconButton>}</StyledTableCell>
                                            <StyledTableCell align="right">Status{sessionState.accountType == "ADMIN" && <IconButton onClick={() => setUpdateStatusDialogOpen(true)}><EditIcon /></IconButton>}</StyledTableCell>
                                            <StyledTableCell align="right">Submission Time</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell align="left">{report.reportId}</StyledTableCell>
                                            <StyledTableCell align="right">{report.severity}</StyledTableCell>
                                            <StyledTableCell align="right">{report.open ? "Open" : "Closed"}</StyledTableCell>
                                            <StyledTableCell align="right">{report.status}</StyledTableCell>
                                            <StyledTableCell align="right">{dayjs(report.submissionTime).format("M/D/YYYY hh:mm A")}</StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer> }
                            { submitterProfile != null && 
                                <TableContainer sx={{ verflowx: "initial"}} component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell align="left">AccountId</StyledTableCell>
                                                <StyledTableCell align="left">Email</StyledTableCell>
                                                <StyledTableCell align="left">First Name</StyledTableCell>
                                                <StyledTableCell align="left">Last Name</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            <StyledTableRow>
                                                <StyledTableCell align="left">{submitterProfile.accountId}</StyledTableCell>
                                                <StyledTableCell align="left">{submitterProfile.emailAddress}</StyledTableCell>
                                                <StyledTableCell align="left">{submitterProfile.firstName}</StyledTableCell>
                                                <StyledTableCell align="left">{submitterProfile.lastName}</StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                            <Box sx={{ width: "100%", ml: "5px", mr: "5px" }}>
                                <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Description</Typography>
                            </Box>
                            { report != null && <Typography>{report.description}</Typography> }
                            <Box sx={{ width: "100%", ml: "5px", mr: "5px" }}>
                                <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Comments</Typography>
                            </Box>
                            <TableContainer sx={{ overflowx: "initial" }} component={Paper}>
                                <Table sx={{ minWidth: "550px", height: "auto" }} stickyHeader size="small">
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell align="left"></StyledTableCell>
                                            <StyledTableCell align="left">Timestamp</StyledTableCell>
                                            <StyledTableCell align="left">Comment</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        { comments.map((comment, index) => {
                                            return(
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell align="left"><Avatar src={"/api/v1/account/profile-picture/" + comment.commenter} />{comment.commenterName}</StyledTableCell>
                                                    <StyledTableCell align="left">{dayjs(comment.timestamp).format("M/D/YYYY hh:mm A")}</StyledTableCell>
                                                    <StyledTableCell align="left">{comment.comment}</StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack direction="row" alignItems="center">
                                <Typography>Post New Comment: </Typography>
                                <FormControl sx={{ m: 1, width: '100%' }} variant="filled">
                                    <FilledInput
                                        id="NewCommentTextField"
                                        type="text"
                                        onChange={(e) => setNewCommentText(e.target.value)}
                                        value={newCommentText}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => postNewComment()}
                                            edge="end">
                                                <SendIcon />
                                            </IconButton>
                                        </InputAdornment>
                                        } />
                                </FormControl>
                            </Stack>
                        </Stack>
                    </LocalizationProvider>
                </DashboardElement>
            </DashboardBody>
        </DashboardLayout>
    );
}