import { useState, useEffect } from 'react';
import { Stack, Typography} from '@mui/material';


export default function FileViewer({ title, fileKey }) {
    const [file, setFile] = useState(null);
    const [fileURI, setFileURI] = useState('');

    function loadFile() {
        fetch("/api/v1/account/user-documentation/" + fileKey).then((res) => {
            return res.arrayBuffer();
        }).then((fileRes) => {
            let fileBlob = new Blob([fileRes], {type: 'application/pdf'});
            setFile(fileBlob);
            setFileURI(URL.createObjectURL(fileBlob) + "#toolbar=0");
        });
    }

    useEffect(() => {
        loadFile();
    }, [title, fileKey]);


    return (
        <Stack direction="column" sx={{ width: "100%", height: "100%" }}>
            <Typography>{title}</Typography>
            <iframe title="file" src={fileURI} style={{ width: "100%", height: "95%" }}/>
        </Stack>
    );
}