import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
  Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia, Checkbox,
  Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Input, InputAdornment, Pagination,
  Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Grid,
  Toolbar, IconButton, Switch, Skeleton, Collapse } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import DashboardElement from 'pageElements/DashboardElement';
import DashboardLayout from 'pageElements/DashboardLayout';
import DashboardBody from 'pageElements/DashboardBody';
import DashboardMenuBar from 'pageElements/DashboardMenuBar';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    }
}));

const DisabledButton = styled(Button)(({ theme }) => ({
    '&:disabled': {
      color: "#6b6b6b",
    }
}));

const MenuBarButton = styled(Button)(({ theme }) => ({
    color: "white",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#6c89b8"
    }
}));


function ClientParticipantStatesListItem({ clientParticipantData }) {
    let [collapsed, setCollapsed] = useState(true);
    let [participantsStateData, setParticipantsStateData] = useState([]);
    let [participantCount, setParticipantCount] = useState(0);
    let [clientName, setClientName] = useState("");

    useEffect(() => {
        fetch("/api/v1/account/client/" + clientParticipantData.clientId).then((res) => {
            return res.json();
        }).then((client) => {
            setClientName(client.clientName);
        });

        let statesData = [];
        let pCount = 0;
        for(const key in clientParticipantData.participantStates) {
            if(clientParticipantData.participantStates.hasOwnProperty(key)) {
                statesData.push({stateName: key, participantCount: clientParticipantData.participantStates[key]});
                pCount += clientParticipantData.participantStates[key];
            }
        }
        setParticipantCount(pCount);
        setParticipantsStateData(statesData);
    }, []);

    return (
        <React.Fragment>
            <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell><IconButton onClick={() => setCollapsed(!collapsed)}>{collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</IconButton></StyledTableCell>
                <StyledTableCell>{clientName}</StyledTableCell>
                <StyledTableCell>{participantCount}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell colSpan={8} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={!collapsed} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table stickyHeader size="small">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell>State</StyledTableCell>
                                        <StyledTableCell>Participant Count</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    { participantsStateData.map((stateData) => {
                                        return(
                                            <StyledTableRow>
                                                <StyledTableCell>{stateData.stateName}</StyledTableCell>
                                                <StyledTableCell>{stateData.participantCount}</StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </StyledTableCell>
            </StyledTableRow>
        </React.Fragment>
    );
}

function ParticipantStates() {
    const [orgParticipantData, setOrgParticipantData] = useState(null);
    const [clientsParticipantData, setClientsParticipantsData] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [page, setPage] = useState(0);

    useEffect(() => {
        fetch("/api/v1/account/analytics/org-participant-states").then((res) => {
            return res.json();
        }).then((organizationParticipantStates) => {
            setOrgParticipantData(organizationParticipantStates.orgParticipantStates);

            let clientsData = [];
            for(const key in organizationParticipantStates.clientParticipantStates) {
                if(organizationParticipantStates.clientParticipantStates.hasOwnProperty(key)) {
                    clientsData.push({clientId: key, participantStates: organizationParticipantStates.clientParticipantStates[key].participantStates});
                }
            }
            setClientsParticipantsData(clientsData);
        });
    }, []);

    if(orgParticipantData == null || clientsParticipantData == null)
        return(<CircularProgress />);
    
    return (
        <React.Fragment>
            <DashboardElement elevation={1}>
                <Box sx={{ width: "100%", maxHeight: "1000px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                    <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
                        <Box sx={{ width: "100%" }}>
                            <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Participant States by Client</Typography>
                        </Box>
                        <TableContainer sx={{ overflowx: "initial" }} component={Paper}>
                            <Table sx={{ minWidth: "550px" }} stickyHeader size="small">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell>Client</StyledTableCell>
                                        <StyledTableCell>Participants</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    { clientsParticipantData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((clientParticipantStatesData) => {
                                        return (<ClientParticipantStatesListItem clientParticipantData={clientParticipantStatesData} />)
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination 
                            rowsPerPageOptions={[8]}
                            component="div"
                            count={clientsParticipantData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(event, pageNum) => setPage(pageNum)} />
                    </Stack>
                </Box>
            </DashboardElement>
        </React.Fragment>
    );
}

export default function OrganizationAnalytics() {

  return (
    <DashboardLayout>
      <DashboardMenuBar>
        <MenuBarButton onClick={() => {window.location.href = "/"}}>Organization Dashboard</MenuBarButton>
        <MenuBarButton onClick={() => {window.location.href = "/report/coaching-hours-progress-report"}}>Client Hours Report</MenuBarButton>
        <MenuBarButton onClick={() => {window.location.href = "/report/coach-hours-report"}}>Coach Hours Report</MenuBarButton>
        <MenuBarButton onClick={() => {window.location.href = "/report/no-show-report"}}>No Show Report</MenuBarButton>
        <Tooltip title="Coming soon"><span><DisabledButton disabled>Generate Reports</DisabledButton></span></Tooltip>
      </DashboardMenuBar>
      <DashboardBody>
            <Typography variant="h2" component="h1" sx={{ fontWeight: 500 }}>Analytics</Typography>
            <ParticipantStates />
      </DashboardBody>
    </DashboardLayout>
  );
}

