import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Stack, Paper, RadioGroup, Radio, styled, Backdrop, CircularProgress, 
    Snackbar, Alert, Divider, TableContainer, Table, TableRow, TableBody, TableHead } from '@mui/material';
import Avatar from '@mui/joy/Avatar';
import Chip from '@mui/joy/Chip';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import Typography from '@mui/joy/Typography';
import { SessionContext } from 'Contexts';
import ResumeViewer from 'pageElements/ResumeViewer';

export default function CoachSelection() {
    const sessionState = useContext(SessionContext);
    let [coachMatches, setCoachMatches] = useState(null);
    let [highestMatchScore, setHighestMatchScore] = useState(0.0);
    let [lowestMatchScore, setlowestMatchScore] = useState(0.0);

    useEffect(() => {
        fetch("/api/v1/account/coach-matches").then((res) => {
            return res.json();
        }).then((coachMatchResponse) => {
            setCoachMatches(coachMatchResponse);
            setlowestMatchScore(coachMatchResponse.lowestSetMatchScore);
            setHighestMatchScore(coachMatchResponse.highestSetMatchScore);
        })
    }, []);

    function normalizeScore(score) {
        //return (((score - lowestMatchScore) / (highestMatchScore - lowestMatchScore)).toFixed(2) * 100);
        return (score / highestMatchScore).toFixed(2) * 100;
    }

    function selectCoach(coachId) {
        fetch("/api/v1/account/select-coach/" + coachId, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            }
        }).then((res) => {
            if(res.status == 200)
                window.location.reload();
            else
                return; // Display/redirect to error here
        });
    }

    function CoachCard(coachData) {
        let [resumeOpen, setResumeOpen] = useState(false);
        let coach = coachData.coach;
        console.log("coach card: ", coachData);

        return (
            <Card sx={{ display: "flex", alignItems: "left", justifyContent: "left", width: "350px" }}>
                { coach.hasResume && <ResumeViewer lazyLoad={false} title={coach.firstName + " " + coach.lastName + " Bio"} accountId={coach.coachId} open={resumeOpen} onClose={() => setResumeOpen(false)} /> }
                <CardContent sx={{ alignItems: "center", textAlign: "center" }}>
                    <Avatar alt={ coach.firstName + " " + coach.lastName } src={ "/api/v1/account/profile-picture/" + coach.coachId } sx={{ '--Avatar-size': '9rem' }} />
                    <Typography level="h2">{coach.firstName} {coach.lastName}</Typography>
                    <Typography color="primary" level="title-md" sx={{ maxWidth: "50ch" }}>{coach.personalStatement}</Typography>
                    <Box sx={{ display: "flex" }}>
                        { coach.yearsOfExperience > 0 && <Chip size="sm" variant="soft" color="primary" sx={{ border: "3px solid", borderColor: "background.surface" }}>
                            {coach.yearsOfExperience} Years of Experience
                        </Chip> }
                    </Box>
                    { /* <Stack direction="row" alignItems="center" justifyContent="center" spacing={1.0}>
                        <Typography level="body-md">Match Score: </Typography>
                        <Typography color="success" level="h3">{coach.matchScore}%</Typography>
                    </Stack> */ }
                    <CardActions buttonFlex="1">
                        <ButtonGroup variant="outlined" sx={{ bgcolor: "background.surface" }}>
                            <Button disabled={!coach.hasResume} onClick={() => setResumeOpen(true)}>View Bio</Button>
                            <Button onClick={() => selectCoach(coach.coachId)}>Choose Coach</Button>
                        </ButtonGroup>
                    </CardActions>
                </CardContent>
            </Card>
        );
    }

    if(coachMatches == null) {
        return(<CircularProgress />);
    } else {
        return (
            <div className="PageBodyWide">
                <Box sx={{ width: "100%", alignItems: "center", justifyContent: "center", mt:"1.5%" }}>
                    <Stack direction="row">
                        <Box sx={{ width: "22%", alignItems: "center", justifyContent: "center", mt:"1.5%" }}>
                            <Stack spacing={2} direction="column" justifyContent="center" alignItems="center">
                                <Typography level="h1">Here are your coach matches</Typography>
                                <Typography>Choose your coach</Typography>
                            </Stack>
                        </Box>
                        <Box sx={{ width: "72%", alignItems: "center", justifyContent: "center", mt:"1.5%" }}>
                            <Stack spacing={2} direction="column" justifyContent="center" alignItems="center">
                                <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={0.5}>
                                    {coachMatches.coaches.map((coach, index) => {
                                        coach.matchScore = normalizeScore(coachMatches.matchScores[index]);
                                        return(<CoachCard coach={coach} />);
                                    })}
                                </Stack>
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
            </div>
        );
    }
}