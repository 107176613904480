import React, { useEffect, useState, useContext } from 'react';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider } from '@mui/material';
import { Textarea } from '@mui/joy';
import { SessionContext } from 'Contexts';

export default function CoachMatching() {
    const sessionState = useContext(SessionContext);
    const [coachMatchingQuestions, setCoachMatchingQuestions] = useState(null);
    const [userSelectsGenderPreference, setUserSelectsGenderPreference] = useState(false);
    const [userSelectsEthnicityPreference, setUserSelectsEthnicityPreference] = useState(false);
    const [coachMatchingTextAnswers] = useState({});

    useEffect(() => {
        fetch("/api/v1/account/registration-configuration/" + sessionState.participant.clientParent).then((res) => {
            return res.json();
        }).then((clientCustomRegistrationConfiguration) => {
            console.log("clientCustomRegistrationConfiguration: ", clientCustomRegistrationConfiguration);
            setUserSelectsGenderPreference(clientCustomRegistrationConfiguration.customRegistrationConfiguration.userHasCoachMatchingGenderPreference);
            setUserSelectsEthnicityPreference(clientCustomRegistrationConfiguration.customRegistrationConfiguration.userHasCoachMatchingEthnicityPreference);
        });

        fetch("/api/v1/account/participant-survey").then((res) => {
          return res.json();
        }).then((coachMatchingQuestionsResponse) => {
          for(let i = 0; i < coachMatchingQuestionsResponse.questions.length; i++) {
            coachMatchingQuestionsResponse.questions[i].errors = [];
            if(coachMatchingQuestionsResponse.questions[i].question.questionType == "MULTIPLE_CHOICE") {
              coachMatchingQuestionsResponse.questions[i].question["currentChoiceCount"] = 0;
              for(let j = 0; j < coachMatchingQuestionsResponse.questions[i].answers.length; j++) {
                coachMatchingQuestionsResponse.questions[i].answers[j]["value"] = 0;
              }
            } else if(coachMatchingQuestionsResponse.questions[i].question.questionType == "SINGLE_CHOICE") {
                for(let j = 0; j < coachMatchingQuestionsResponse.questions[i].answers.length; j++) {
                    coachMatchingQuestionsResponse.questions[i].answers[j]["value"] = 0;
                }
            } else if(coachMatchingQuestionsResponse.questions[i].question.questionType == "TEXT") {
                coachMatchingTextAnswers[i] = "";
            } else {
              console.log("Unhandled survey question type: ", coachMatchingQuestionsResponse.questions[i].question.questionType);
            }
          }
          setCoachMatchingQuestions(coachMatchingQuestionsResponse);
        });
      }, []);

    function createCoachMatchingSurveyRq() {
        let rq = {
            completedSurvey: {
                surveyId: coachMatchingQuestions.surveyId,
                completionTime: new Date().toUTCString()  
            },
          completedSurveyAnswers: []
        };

        for(let i = 0; i < coachMatchingQuestions.questions.length; i++) {
            if(coachMatchingQuestions.questions[i].question.questionType == "MULTIPLE_CHOICE") {
                for(let j = 0; j < coachMatchingQuestions.questions[i].answers.length; j++) {
                    let completedSurveyAnswer = {
                        questionId: coachMatchingQuestions.questions[i].question.questionId,
                        answerId: coachMatchingQuestions.questions[i].answers[j].answerId,
                        answerValue: coachMatchingQuestions.questions[i].answers[j].value
                    };
                    rq.completedSurveyAnswers.push(completedSurveyAnswer);
                }
            } else if(coachMatchingQuestions.questions[i].question.questionType == "SINGLE_CHOICE") {
                for(let j = 0; j < coachMatchingQuestions.questions[i].answers.length; j++) {
                    let completedSurveyAnswer = {
                        questionId: coachMatchingQuestions.questions[i].question.questionId,
                        answerId: coachMatchingQuestions.questions[i].answers[j].answerId,
                        answerValue: coachMatchingQuestions.questions[i].answers[j].value
                    };
                    rq.completedSurveyAnswers.push(completedSurveyAnswer);
                }
            } else if(coachMatchingQuestions.questions[i].question.questionType == "TEXT") {
                let completedSurveyAnswer = {
                    questionId: coachMatchingQuestions.questions[i].question.questionId,
                    answerId: coachMatchingQuestions.questions[i].answers[0].answerId,
                    answerTextValue: coachMatchingTextAnswers[i]
                }
                rq.completedSurveyAnswers.push(completedSurveyAnswer);
            }
        }
        return rq;
    }

    function submitSurvey() {
        if(!validateResponses()) {
            window.scrollTo(0, 0);
            return;
        }
        let rq = createCoachMatchingSurveyRq();
        fetch("/api/v1/account/participant-survey", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(rq)
        }).then((res) => {
            if(res.status == 200)
                window.location.reload();
            else
                return; // Display/redirect to error here
        });
    }

    function multipleChoiceSelection(answerIndex, questionIndex) {
        if(coachMatchingQuestions.questions[questionIndex].answers[answerIndex].value == 0) {
            if(coachMatchingQuestions.questions[questionIndex].question.currentChoiceCount < coachMatchingQuestions.questions[questionIndex].question.maxChoiceCount) {
            let updatedCoachMatchingQuestions = JSON.parse(JSON.stringify(coachMatchingQuestions));
            updatedCoachMatchingQuestions.questions[questionIndex].answers[answerIndex].value = 1;
            updatedCoachMatchingQuestions.questions[questionIndex].question.currentChoiceCount += 1;
            setCoachMatchingQuestions(updatedCoachMatchingQuestions);
            }
        } else {
            let updatedCoachMatchingQuestions = JSON.parse(JSON.stringify(coachMatchingQuestions));
            updatedCoachMatchingQuestions.questions[questionIndex].answers[answerIndex].value = 0;
            updatedCoachMatchingQuestions.questions[questionIndex].question.currentChoiceCount -= 1;
            setCoachMatchingQuestions(updatedCoachMatchingQuestions);
        }
    }

    function singleChoiceSelection(answerIndex, questionIndex) {
        let updatedCoachMatchingQuestions = JSON.parse(JSON.stringify(coachMatchingQuestions));
        for(let j = 0; j < updatedCoachMatchingQuestions.questions[questionIndex].answers.length; j++) {
            updatedCoachMatchingQuestions.questions[questionIndex].answers[j]["value"] = 0;
        }
        updatedCoachMatchingQuestions.questions[questionIndex].answers[answerIndex]["value"] = 1;
        setCoachMatchingQuestions(updatedCoachMatchingQuestions);
    }

    function updateTextQuestionValue(questionIndex, value) {
        coachMatchingTextAnswers[questionIndex] = value;
    }

    function validateResponses() {
        let updatedCoachMatchingQuestions = {...coachMatchingQuestions};
        let hasErrors = false;
        for(let q = 0; q < updatedCoachMatchingQuestions.questions.length; q++) {
            let question = updatedCoachMatchingQuestions.questions[q];
            question.errors = [];
            if(question.question.questionType == "MULTIPLE_CHOICE") {
                if(question.question.currentChoiceCount < question.question.minChoiceCount) {
                    hasErrors = true;
                    updatedCoachMatchingQuestions.questions[q].errors.push("You must select at least " + question.question.minChoiceCount + " items.");
                }
            } else if(question.question.questionType == "SINGLE_CHOICE") {
                let answerCount = 0;
                if(question.question.questionId == 3 && userSelectsEthnicityPreference == false) {
                    continue;
                } else if(question.question.questionId == 2 && userSelectsGenderPreference == false) {
                    continue;
                }
                for(let a = 0; a < question.answers.length; a++)
                    if(question.answers[a].value == 1)
                        answerCount += 1;
                if(answerCount != 1) {
                    hasErrors = true;
                    updatedCoachMatchingQuestions.questions[q].errors.push("You must select 1 item.");
                }
            } else if(question.question.questionType == "TEXT") {
                if(coachMatchingTextAnswers[q].length > 500) {
                    hasErrors = true;
                    updatedCoachMatchingQuestions.questions[q].errors.push("Written answers must be 500 characters or less.");
                }
            }
        }
        setCoachMatchingQuestions(updatedCoachMatchingQuestions);
        console.log("ValidateResponses: ", hasErrors, "; ", updatedCoachMatchingQuestions);
        return !hasErrors;
    }

    function ParticipantSurvey({ participantSurvey, genderPreference, ethnicityPreference}) {
        let [textAnswerLengths, setTextAnswerLengths] = useState({});
        let [textAnswerIndices, setTextAnswerIndicies] = useState([]);

        useEffect(() => {
            if(participantSurvey == null)
                return;
            for(let i = 0; i < participantSurvey.questions.length; i++) {
                if(participantSurvey.questions[i].question.questionType == "TEXT") {
                    textAnswerLengths[i] = coachMatchingTextAnswers[i].length;
                    textAnswerIndices.push(i);
                }
            }
            setTextAnswerLengths({...textAnswerLengths});
            let textUpdateTimer = setInterval(() => {
                let utal = {textAnswerLengths};
                for(let i = 0; i < textAnswerIndices.length; i++) {
                    utal[textAnswerIndices[i]] = coachMatchingTextAnswers[textAnswerIndices[i]].length;
                }
                setTextAnswerLengths(utal);
            }, 250);
            return () => {
                clearInterval(textUpdateTimer);
            };
        }, [participantSurvey]);

        if(participantSurvey == null) {
            return (
                <CircularProgress />
            );
        } else {
            return (
                <Stack direction="column" spacing={2.0}>
                    {participantSurvey.questions.map((userSurveyQuestion, questionIndex) => {
                        let questionHeader = <Typography variant="h4" component="h2">{userSurveyQuestion.question.prompt}</Typography>
                        let questionBody = null;
                        if(genderPreference == false && userSurveyQuestion.question.questionId == 2)
                            return;
                        else if(ethnicityPreference == false && userSurveyQuestion.question.questionId == 3)
                            return;
                        switch(userSurveyQuestion.question.questionType) {
                        case "MULTIPLE_CHOICE":
                            questionBody = 
                                <React.Fragment>
                                    <h4>Select {userSurveyQuestion.question.minChoiceCount} to {userSurveyQuestion.question.maxChoiceCount} items</h4>
                                    <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={0.5}>
                                    { userSurveyQuestion.answers.map((answer, answerIndex) => {
                                        if(answer.value == 1) {
                                            return(<Chip label={answer.answerText} onClick={() => {multipleChoiceSelection(answerIndex, questionIndex)}} color="primary" variant="filled" />);
                                        } else {
                                            return(<Chip label={answer.answerText} onClick={() => {multipleChoiceSelection(answerIndex, questionIndex)}} variant="outlined" />);
                                        }
                                    })}
                                    </Stack>
                                </React.Fragment>
                            break;
                        case "SINGLE_CHOICE":
                            questionBody = 
                                <FormControl>
                                    <RadioGroup row>
                                        { userSurveyQuestion.answers.map((answer, answerIndex) => {
                                            if(answer.value == 1) {
                                                return (<FormControlLabel checked={true} value={answerIndex} control={<Radio onChange={() => {singleChoiceSelection(answerIndex, questionIndex)}} />} label={answer.answerText} />);
                                            } else {
                                                return(<FormControlLabel checked={false} value={answerIndex} control={<Radio onChange={() => {singleChoiceSelection(answerIndex, questionIndex)}} />} label={answer.answerText} />);
                                            }
                                        })}
                                    </RadioGroup>
                                </FormControl>
                            break;
                        case "TEXT":
                            questionBody = 
                                <Box sx={{ minWidth: "80%"}} >
                                    <Typography>Max Characters: {textAnswerLengths[questionIndex]}/500</Typography>
                                    <Textarea defaultValue={coachMatchingTextAnswers[questionIndex]} minRows={3} size="lg" variant="outlined" onChange={(event) => { updateTextQuestionValue(questionIndex, event.target.value)}} />
                                </Box>
                            break;
                        default:
                            console.log("Coaching survey type unknown: ", userSurveyQuestion.question);
                            break;
                        }
                        return (
                        <React.Fragment>
                            {questionHeader}
                            { userSurveyQuestion.errors.map((errorText) => {
                                return (
                                    <Alert variant="filled" severity="error">
                                        {errorText}
                                    </Alert>
                                );
                            })}
                            {questionBody}
                        </React.Fragment>
                        );
                    })}
                </Stack>
            );
        }
    }

    return (
        <div className="PageBody">
            <Stack direction="column" fullwidth justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                <Typography variant="h3" component="h1">Coach Matching Survey</Typography>
                <Typography>Your responses to this survey will be used to match you to like-minded coaches</Typography>
                <ParticipantSurvey 
                    participantSurvey={coachMatchingQuestions} 
                    ethnicityPreference={userSelectsEthnicityPreference}
                    genderPreference={userSelectsGenderPreference} />
                <Box sx={{ maxWidth: "50%", justifyContent: "center"}}>
                    <Button component="label" variant="contained" maxWidth="200px" onClick={ () => { submitSurvey() }}>Submit</Button>
                </Box>
                <Box sx={{height: "50px"}} />
            </Stack>
        </div>
    );
}