import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect } from 'react';
import ParticipantRouter from './participant/ParticipantRouter';
import CoachRouter from './coach/CoachRouter';
import AdminRouter from './admin/AdminRouter';


export default function BasePage({sessionState}) {
    if(sessionState.accountType === "PARTICIPANT") {
        return (
            <ParticipantRouter />
        );
    } else if(sessionState.accountType === "COACH") {
        return (
            <CoachRouter />
        );
    } else if(sessionState.accountType === "ADMIN") {
        return (
            <AdminRouter />
        );
    } else if(sessionState.accountType === "SUPPORT") {
        return (
            <h1>Support Router Placeholder</h1>
        );
    } else {
        return (
            <h1>Error: Unknown Account Type</h1>
        );
    }
}