import React, { useState, useEffect, useContext } from 'react';
import { SessionContext } from 'Contexts';
import { Textarea } from '@mui/joy';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
  Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
  Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Skeleton, Tooltip, 
  ThemeProvider, IconButton } from '@mui/material';
  
import EditIcon from '@mui/icons-material/Edit';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


export default function CoachProfileBanner() {
    const sessionState = useContext(SessionContext);
    const [coachProfile, setCoachProfile] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [errors, setErrors] = useState([]);
    const [profilePictureURI, setProfilePictureURI] = useState(null);

    const [firstNameField, setFirstNameField] = useState("");
    const [lastNameField, setLastNameField] = useState("");
    const [personalStatementField, setPersonalStatementField] = useState("");


    useEffect(() => {
        setProfilePictureURI(sessionState.accountId);
        fetch("/api/v1/account/coach-profile/" + sessionState.accountId).then((res) => {
            return res.json();
        }).then((coachProfileResponse) => {
            setCoachProfile(coachProfileResponse);
        });
    }, []);

    function switchEditMode() {
        if(isEditing) {
            setIsEditing(false);
        } else {
            setErrors([]);
            setFirstNameField(coachProfile.firstName)
            setLastNameField(coachProfile.lastName);
            setPersonalStatementField(coachProfile.personalStatement);
            setIsEditing(true);
        }   
    }

    function uploadProfilePicture(event) {
        let fileUpload = document.getElementById("profilePictureFileElement");
        if(fileUpload.files[0] == null)
            window.location.href = "/";
        let formData = new FormData();
        formData.append("file", fileUpload.files[0]);
        setProfilePictureURI(0);
        fetch("/api/v1/account/profile-picture/" + sessionState.accountId, {
            method: "POST",
            cache: "no-cache",
            body: formData
        }).then((res) => {
            setProfilePictureURI(sessionState.accountId);
        });
    }

    function submitUpdateCoachProfile() {
        let coachProfileRq = {
            firstName: "",
            lastName: "",
            personalStatement: ""
        }

        let errorList = [];
        let fieldChanged = false;
        if(firstNameField == coachProfile.firstName) {

        } else if(firstNameField.length > 0) {
            fieldChanged = true;
            coachProfileRq.firstName = firstNameField;
        } else {
            errorList.push("First name cannot be empty.");
        }

        if(lastNameField == coachProfile.lastName) {

        } else if(lastNameField.length > 0) {
            fieldChanged = true;
            coachProfileRq.lastName = lastNameField;
        } else {
            errorList.push("Last name cannot be empty.");
        }

        if(personalStatementField == coachProfile.personalStatement) {

        } else if(personalStatementField.length > 0) {
            fieldChanged = true;
            coachProfileRq.personalStatement = personalStatementField;
        } else {
            errorList.push("Personal statement cannot be empty.");
        }

        if(errorList.length > 0) {
            setErrors(errorList);
            return;
        }

        if(!fieldChanged) {
            switchEditMode();
            return;
        }

        fetch("/api/v1/account/coach-profile", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(coachProfileRq)
        }).then((res) => {
            return res.json();
        }).then((coachProfileResponse) => {
            setCoachProfile(coachProfileResponse);
            switchEditMode();
        });
    }

    return(
        <Box sx={{ width: "100%", height: (errors.length > 0 ? "32vh" : "22vh" ), bgcolor: "#0A4B90", borderBottom: 1, borderColor: "#7b92ab", overflow: "hidden", overflowY: "scroll" }}>
            <Stack spacing={ 2 } direction="row" justifyContent="flex-start" alignItems="center" sx={{ height: "98%", width: "100%", ml: "2%" }}>
                { !isEditing && <Avatar alt={sessionState.name} src={"/api/v1/account/profile-picture/" + profilePictureURI} sx={{ height: "19vh", width: "19vh" }} /> }
                { isEditing && 
                    <Stack direction="column" spacing={0.25} alignItems="center" justifyContent="center" sx={{ mb: "5px" }}>
                        <Avatar alt={sessionState.name} src={"/api/v1/account/profile-picture/" + profilePictureURI} sx={{ height: "10vh", width: "10vh" }} />
                        <Button component="label" variant="contained" maxWidth="190px">
                            Upload Profile Picture
                            <VisuallyHiddenInput type="file" id="profilePictureFileElement" accept=".jpg,.jpeg,.png" onChange={ () => uploadProfilePicture() } />
                        </Button> 
                    </Stack> }
                    { coachProfile != null && (
                        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={ 5 } sx={{ width: "100%", height: "100%" }}>
                            <Stack direction="column" alignItems="flex-start" sx={{ width: "45%", height: "100%" }}>
                                { isEditing && errors.map((errorMessage) => {
                                    return <Alert variant="filled" severity={ "error" }>{ errorMessage }</Alert>
                                })}
                                <Stack direction="row">
                                    { !isEditing && 
                                        <React.Fragment>
                                            <Typography variant="h3" component="h1" sx={{ color: "white" }}>{coachProfile.firstName} {coachProfile.lastName}</Typography>
                                            <IconButton sx={{ borderRadius: 0 }} onClick={() => switchEditMode()}>
                                                <EditIcon />
                                            </IconButton> 
                                        </React.Fragment>
                                    } { isEditing &&
                                        <React.Fragment>
                                            <Stack direction="row" spacing={ 1 }>
                                                <TextField size="small" label="First Name" variant="standard" value={firstNameField} onChange={(e) => setFirstNameField(e.target.value)} />
                                                <TextField size="small" label="Last Name" variant="standard" value={lastNameField} onChange={(e) => setLastNameField(e.target.value)} />
                                                <Button variant="contained" color="success" onClick={() => { submitUpdateCoachProfile();}}>Save</Button>
                                                <Button color="primary" onClick={() => { switchEditMode(); }}>Cancel</Button>
                                            </Stack>
                                        </React.Fragment>
                                    }
                                </Stack>
                                <Typography sx={{ color: "white" }}>Coach</Typography>
                                <Typography sx={{ color: "white" }}>{coachProfile.email}</Typography>
                            </Stack>
                            <Stack direction="column" justifyContent="center" alignItems="center" sx={{ width: "40%", height: "100%" }} spacing={ 1 }>
                                { !isEditing && 
                                    <React.Fragment>
                                        <Typography sx={{ color: "white" }} variant="h6">What makes me unique:</Typography>
                                        <Typography sx={{ color: "white" }} variant="subtitle2">{coachProfile.personalStatement}</Typography>
                                    </React.Fragment>
                                } { isEditing &&
                                    <React.Fragment>
                                        <Typography sx={{ color: "white" }} variant="h6">What makes me unique:</Typography>
                                        <Textarea value={personalStatementField} minRows={3} size="lg" variant="outlined" onChange={(event) => { setPersonalStatementField(event.target.value)}} />
                                    </React.Fragment>
                                }
                            </Stack>
                        </Stack>
                    )}
            </Stack>
        </Box>
    );
}