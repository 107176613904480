import React, { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { SessionContext } from 'Contexts';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider, Checkbox,
    FormGroup, TableContainer, Table, TableRow, TableBody, TableHead, IconButton, ListItemText,
    OutlinedInput, InputAdornment, Input, Collapse, Skeleton, Grid, TablePagination } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import utc from 'dayjs/plugin/utc';
import DashboardElement from 'pageElements/DashboardElement';

dayjs.extend(utc);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CoachNotes() {
    const sessionState = useContext(SessionContext);
    let [coachNotes, setCoachNotes] = useState([]);
    let [rowsPerPage, setRowsPerPage] = useState(5);
    let [page, setPage] = useState(0);

    useEffect(() => {
        fetch("/api/v1/account/coach-notes/" + sessionState.accountId).then((res) => {
            return res.json();
        }).then((coachNotesResponse) => {
            setCoachNotes(coachNotesResponse.notes);
        });
    }, []);

    function loadNotes() {
        fetch("/api/v1/account/coach-notes/" + sessionState.accountId).then((res) => {
            return res.json();
        }).then((coachNotesResponse) => {
            setCoachNotes(coachNotesResponse.notes);
        });
    }

    return (
        <DashboardElement elevation={1}>
            <Stack direction="column" spacing={ 1 } sx={{ width: "100%" }}>
                <Box sx={{ width: "100%", maxHeight: "1000px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                    <Stack direction="column" spacing={1.0} sx={{  width: "100%", mt: "10px", mb: "10px"}}>
                        <Box sx={{ width: "100%" }}>
                            <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Your Coach's Notes</Typography>
                        </Box>
                        <TableContainer sx={{ overflowx: "initial" }} component={Paper}>
                            <Table sx={{ minWidth: "550px", height: "auto" }} stickyHeader size="small">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell>Note</StyledTableCell>
                                        <StyledTableCell>Timestamp</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    { coachNotes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((note) => {
                                        return(
                                            <StyledTableRow>
                                                <StyledTableCell><Typography variant="body1">{note.noteText}</Typography></StyledTableCell>
                                                <StyledTableCell>{dayjs(note.entryDate).local().format("h:mm A D/MM/YYYY")}</StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                    { sessionState.participant.coachId == -1 && 
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={2} align="center">You haven't been assigned a coach yet</StyledTableCell>
                                        </StyledTableRow>
                                    }
                                    { (sessionState.participant.coachId > -1 && coachNotes.length == 0) && 
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={2} align="center">You don't have any notes from your coach yet</StyledTableCell>
                                        </StyledTableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Stack>
                </Box>
                <TablePagination 
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={coachNotes.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, pageNum) => setPage(pageNum)} />
            </Stack>
        </DashboardElement>
    );
}