import React, { useEffect, useState } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia, Checkbox,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Input, InputAdornment, Pagination,
    Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Grid,
    Toolbar, IconButton, Switch, Skeleton, Collapse } from '@mui/material';

import DashboardElement from 'pageElements/DashboardElement';
import DashboardLayout from 'pageElements/DashboardLayout';
import DashboardBody from 'pageElements/DashboardBody';
import DashboardMenuBar from 'pageElements/DashboardMenuBar';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const ClickableStyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
        backgroundColor: theme.palette.primary.light,
    },
    "&:active": {
        backgroundColor: theme.palette.primary.dark,
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
}));

const MenuBarButton = styled(Button)(({ theme }) => ({
    color: "white",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#6c89b8"
    }
}));


export default function CoachHoursReport() {
    const [reportData, setReportData] = useState([]);

    const [coachRowsPerPage, setCoachRowsPerPage] = useState(8);
    const [coachPage, setCoachPage] = useState(0);

    
    useEffect(() => {
        const rq = {
            generateForAllCoaches: true,
            coachList: []
        };
        fetch("/api/v1/account/analytics/org-coach-hours-reports", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(rq)
        }).then((res) => {
            return res.json();
        }).then((CoachHoursReports) => {
            setReportData(CoachHoursReports.reports);
        });
    }, []);

    return (
        <DashboardLayout>
            <DashboardMenuBar>
                <MenuBarButton onClick={() => {window.location.href = "/"}}>Organization Dashboard</MenuBarButton>
                <MenuBarButton onClick={() => window.location.href="/analytics"} >Organization Analytics</MenuBarButton>
            </DashboardMenuBar>
            <DashboardBody>
                { reportData.length == 0 && <CircularProgress /> }
                { reportData.length > 0 && <DashboardElement elevation={1}>
                    <Box sx={{ width: "100%" }}>
                        <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Coaches</Typography>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 600 }}>
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell align="left">Coach</StyledTableCell>
                                    <StyledTableCell align="right">Participant Count</StyledTableCell>
                                    <StyledTableCell align="right">Hours Scheduled</StyledTableCell>
                                    <StyledTableCell align="right">Hours Completed (Actual)</StyledTableCell>
                                    <StyledTableCell align="right">Hours Completed (Scheduled)</StyledTableCell>
                                    <StyledTableCell align="right">Hours Remaining</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                { reportData.slice(coachPage * coachRowsPerPage, coachPage * coachRowsPerPage + coachRowsPerPage).map((coachReport, index) => {
                                    return (
                                        <StyledTableRow>
                                            <StyledTableCell align="left">{coachReport.coachFirstName} {coachReport.coachLastName}</StyledTableCell>
                                            <StyledTableCell align="right">{coachReport.participantCount}</StyledTableCell>
                                            <StyledTableCell align="right">{(coachReport.minutesScheduled / 60.0).toFixed(2)}</StyledTableCell>
                                            <StyledTableCell align="right">{(coachReport.minutesCoached / 60.0).toFixed(2)}</StyledTableCell>
                                            <StyledTableCell align="right">{(coachReport.minutesCompleted / 60.0).toFixed(2)}</StyledTableCell>
                                            <StyledTableCell align="right">{(coachReport.minutesRemaining / 60.0).toFixed(2)}</StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination 
                        rowsPerPageOptions={[8]}
                        component="div"
                        count={reportData.length}
                        rowsPerPage={coachRowsPerPage}
                        page={coachPage}
                        onPageChange={(event, pageNum) => setCoachPage(pageNum)} />
                </DashboardElement> }
            </DashboardBody>
        </DashboardLayout>
    );
}