import { React, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ThemeProvider } from 'styled-components';
import { MeetingProvider, lightTheme, GlobalStyles } from 'amazon-chime-sdk-component-library-react';

import { SessionContext } from 'Contexts';
import { CircularProgress } from '@mui/material';
import Layout from './pages/Layout';
import LoginPage from './pages/landing/LoginPage';
import BasePage from './pages/BasePage';
import CoachRegistration from 'pages/coach/CoachRegistration';
import ResetPassword from 'pages/landing/ResetPassword';
import Register from 'pages/landing/Register';
import OptOut from 'pages/landing/OptOut';

export default function App() {
	const [sessionState, setSessionState] = useState(null);
	const [awaitingSessionState, setAwaitingSessionState] = useState(true);

    useEffect(() => {
		//document.body.setAttribute("theme", "standard");
		if(awaitingSessionState) {
			fetch("/api/v1/account/verify", {
				method: "GET",
			})
			.then(res => {
				return res.json();
			})
			.then(sessionStateRes => {
				setSessionState(sessionStateRes);
				setAwaitingSessionState(false);
			});
		}
    }, [sessionState, setSessionState, awaitingSessionState,
		setAwaitingSessionState]);

	if(awaitingSessionState) {
		return (
			<CircularProgress />
		);
	}
	else if(sessionState === null || !sessionState.hasSession) {
		return (
			<BrowserRouter>
				<Routes>
					<Route path="/*" element={<Layout />} >
						<Route path="coach-registration" element={<CoachRegistration />} />
						<Route path="reset-password" element={<ResetPassword />} />
						<Route path="register" element={<Register />} />
						<Route path="opt-out" element={<OptOut />} />
						<Route path="*" element={<LoginPage />} />
					</Route>
				</Routes>
			</BrowserRouter>
		);
	}
	else {
		return (
			<SessionContext.Provider value={ sessionState }>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<Layout />}>
							<Route index element={<BasePage sessionState={sessionState} />} />
							<Route path="*" element={<BasePage sessionState={sessionState} />} />
						</Route>
					</Routes>
				</BrowserRouter>
			</SessionContext.Provider>
		); 
	}
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<ThemeProvider theme={lightTheme}>
		<GlobalStyles />
		<MeetingProvider>
			<App/>
		</MeetingProvider>
	</ThemeProvider>
);

reportWebVitals();
