import React, { useEffect, useState } from 'react';
import { SessionContext } from 'Contexts';
import dayjs from 'dayjs';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia, Checkbox,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Input, InputAdornment, Pagination,
    Table, TableBody, TableContainer, TableHead, TablePagination, TableRow,
    Toolbar, IconButton, Switch, Skeleton } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import DashboardElement from 'pageElements/DashboardElement';
import DashboardLayout from 'pageElements/DashboardLayout';
import DashboardBody from 'pageElements/DashboardBody';
import DashboardMenuBar from 'pageElements/DashboardMenuBar';


const MenuBarButton = styled(Button)(({ theme }) => ({
    color: "white",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#6c89b8"
    }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));


export default function ServicePage() {
    const [submittedReports, setSubmittedReports] = useState([]);

    const [reportText, setReportText] = useState("");
    const [reportSeverity, setReportSeverity] = useState(0);
    const [reportUserUpdates, setReportUserUpdates] = useState(true);

    useEffect(() => {
        fetch("/api/v1/account/service-reports").then((res) => {
            return res.json()
        }).then((serviceReportsResponse) => {
            setSubmittedReports(serviceReportsResponse.serviceReports);
        });
    }, []);

    function refreshReports() {
        fetch("/api/v1/account/service-reports").then((res) => {
            return res.json()
        }).then((serviceReportsResponse) => {
            setSubmittedReports(serviceReportsResponse.serviceReports);
        });
    }

    function submitReport() {
        let rq = {
            description: reportText,
            severity: reportSeverity,
            userUpdates: reportUserUpdates
        };

        fetch("/api/v1/account/service-report", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(rq)
        }).then((res) => {
            setReportText("");
            setReportSeverity(0);
            setReportUserUpdates(true);
            refreshReports();
        });
    }

    return (
        <DashboardLayout>
            <DashboardMenuBar>
                <MenuBarButton onClick={() => {window.location.href = "/"}}>Dashboard</MenuBarButton>
            </DashboardMenuBar>
            <DashboardBody>
                { submittedReports.length > 0 && <DashboardElement elevation={0}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack direction="column" spacing={1.0} sx={{  width: "100%", mt: "10px", mb: "10px" }}>
                            <Box sx={{ width: "100%", ml: "5px", mr: "5px" }}>
                                <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Your open sevice requests</Typography>
                            </Box>
                            <TableContainer sx={{ overflowx: "initial" }} component={Paper}>
                                <Table sx={{ minWidth: "550px", height: "auto" }} stickyHeader size="small">
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell>Report Id</StyledTableCell>
                                            <StyledTableCell>Description</StyledTableCell>
                                            <StyledTableCell>Severity</StyledTableCell>
                                            <StyledTableCell>Status</StyledTableCell>
                                            <StyledTableCell>Date</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        { submittedReports.map((reportData, index) => {
                                            return(
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell><Button variant="text" onClick={() => window.location.href="/service-requests/" + reportData.reportId}>{reportData.reportId}</Button></StyledTableCell>
                                                    <StyledTableCell>{reportData.description.length >= 100 ? reportData.description.substring(0, 100) + "..." : reportData.description}</StyledTableCell>
                                                    <StyledTableCell>{reportData.severity}</StyledTableCell>
                                                    <StyledTableCell>{reportData.status}</StyledTableCell>
                                                    <StyledTableCell>{dayjs(reportData.submissionTime).format("M/D/YYYY hh:mm A")}</StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </LocalizationProvider>
                </DashboardElement> }
                <DashboardElement elevation={0} paper={true}>
                    <Stack direction="column" spacing={3} sx={{ width: "95%", ml: "0px" }}>
                        <Box sx={{ width: "100%" }}>
                                <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Submit A Bug Report or Service Request</Typography>
                        </Box>
                        <Typography>Please describe your issue in a much detail as possible, including methods to replicate it if applicable.</Typography>
                        <TextField multiline label="Describe your problem here" rows={5} value={reportText} onChange={(event) => { setReportText(event.target.value)}} />
                        <FormControl>
                            <InputLabel id="severityLabel">To what extent does this issue prevent you from using our coaching service?</InputLabel>
                            <Select 
                              labelId="severityLabel" 
                              value={reportSeverity} 
                              onChange={(e) => setReportSeverity(e.target.value)}
                              label="To what extent does this issue prevent you from using our coaching service?">
                                <MenuItem value={0}>Not at all</MenuItem>
                                <MenuItem value={1}>Somewhat, but a workaround is possible</MenuItem>
                                <MenuItem value={2}>I am unable to leverage all provided features</MenuItem>
                                <MenuItem value={3}>I am unable to continue my coaching at all</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel id="userUpdateLabel">Would you like to be updated on status changes for this report?</InputLabel>
                            <Select 
                              labelId="userUpdateLabel"
                              label="Would you like to be updated on status changes for this report?"
                              value={reportUserUpdates} 
                              onChange={(e) => setReportUserUpdates(e.target.value)}>
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                        <Button disabled={reportText == ""} onClick={() => submitReport()}>Submit Report</Button>
                    </Stack>
                </DashboardElement>
            </DashboardBody>
        </DashboardLayout>
    );
}