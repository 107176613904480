import { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { SessionContext } from 'Contexts';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider, Checkbox,
    FormGroup, TableContainer, Table, TableRow, TableBody, TableHead, IconButton, ListItemText,
    OutlinedInput, InputAdornment, Input, Collapse, TablePagination } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import utc from 'dayjs/plugin/utc';
import DashboardElement from 'pageElements/DashboardElement';

dayjs.extend(utc);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CoachingSessionCalendar() {
    const sessionState = useContext(SessionContext);
    const [coachingSessions, setCoachingSessions] = useState(null);
    const [page, setPage] = useState(0);
    const [sessionsPerPage, setSessionsPerPage] = useState(5);

    useEffect(() => {
        fetch("/api/v1/account/schedule").then((res) => {
          return res.json();
        }).then((coachingSessionSchedule) => {
          let coachingSessionCalendarItems = [];
          for(let i = 0; i < coachingSessionSchedule.coachingSessions.length; i++) {
            coachingSessionCalendarItems.push(coachingSessionSchedule.coachingSessions[i]);
          }
          setCoachingSessions(coachingSessionCalendarItems);
        });
    }, []);

    function reloadSchedule() {
        fetch("/api/v1/account/schedule").then((res) => {
            return res.json();
          }).then((coachingSessionSchedule) => {
            let coachingSessionCalendarItems = [];
            for(let i = 0; i < coachingSessionSchedule.coachingSessions.length; i++) {
              coachingSessionCalendarItems.push(coachingSessionSchedule.coachingSessions[i]);
            }
            setCoachingSessions(coachingSessionCalendarItems);
          });
    }

    function cancelMeeting(meetingId) {
        let rq = {
            meetingId: meetingId,
            cancellationReason: "Cancelled by coach"
        };
        fetch("/api/v1/account/schedule-cancel", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(rq)
        }).then((res) => {
            reloadSchedule();
        });
    }

    function joinMeeting(meetingId) {
        window.location.href = "/coachingSession/" + meetingId;
    }

    if(coachingSessions == null) {
        <DashboardElement elevation={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ width: "100%", maxHeight: "1000px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                    <Typography variant="h5">Upcoming Coaching Sessions</Typography>
                    <CircularProgress />
                </Box>
            </LocalizationProvider>
        </DashboardElement>
    } else if(coachingSessions.length == 0) {
        <DashboardElement elevation={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ width: "100%", maxHeight: "1000px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                    <Typography variant="h5">You have no upcoming Coaching Sessions</Typography>
                </Box>
            </LocalizationProvider>
        </DashboardElement>
    } else {
        return (
            <DashboardElement elevation={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ width: "100%", maxHeight: "10000px", display: "flex", flexDirection: "column", overflow: "hidden" }}>
                        <Box sx={{ width: "100%" }}>
                            <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Upcoming Coaching Sessions</Typography>
                        </Box>
                        <TableContainer sx={{ overflowx: "initial" }} component={Paper}>
                            <Table sx={{ minWidth: "550px" }} stickyHeader size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell>Date</StyledTableCell>
                                        <StyledTableCell>Time</StyledTableCell>
                                        <StyledTableCell>Length</StyledTableCell>
                                        <StyledTableCell>Coachee</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { coachingSessions.slice(page * sessionsPerPage, page * sessionsPerPage + sessionsPerPage).map((sessionData, index) => {
                                        return(
                                            <StyledTableRow key={index}>
                                                <StyledTableCell><Avatar alt={sessionData.otherAttendee} src={"/api/v1/account/profile-picture/" + sessionData.participantId} /></StyledTableCell>
                                                <StyledTableCell>{dayjs(sessionData.scheduledTime).format("D MMM YYYY")}</StyledTableCell>
                                                <StyledTableCell>{dayjs(sessionData.scheduledTime).format("h:mm A")}</StyledTableCell>
                                                <StyledTableCell>{sessionData.meetingLength} Min.</StyledTableCell>
                                                <StyledTableCell>{sessionData.otherAttendee}</StyledTableCell>
                                                <StyledTableCell>
                                                    <Button onClick={() => cancelMeeting(sessionData.meetingId)} color="error">Cancel</Button>
                                                    <Button onClick={() => joinMeeting(sessionData.meetingId)} >Join</Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination 
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={coachingSessions.length}
                          rowsPerPage={sessionsPerPage}
                          page={page}
                          onPageChange={(event, pageNum) => setPage(pageNum)}
                          onRowsPerPageChange={(e) => {setSessionsPerPage(e.target.value); setPage(0);}} />
                    </Box>
                </LocalizationProvider>
            </DashboardElement>
        );
    }
}