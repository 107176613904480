import React, { useState, useContext, Fragment, useEffect } from 'react';
import { SessionContext } from 'Contexts';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import Input from '@mui/joy/Input';
import DialogContent from '@mui/joy/DialogContent';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import utc from 'dayjs/plugin/utc';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
  Chip, Stack, Paper, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
  Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Dialog, DialogActions,
  Button, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow,
  IconButton, Tooltip } from '@mui/material';
import papaparse from 'papaparse';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const ClickableStyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
}));

export default function InviteParticipantsButtons({ clientId }) {
  let [laborCategories, setLaborCategories] = useState(null);
  let [newParticipantData, setNewParticipantData] = useState([]);
  let [newParticipantEmail, setNewParticipantEmail] = useState('');
  let [newParticipantFirstName, setNewParticipantFirstName] = useState('');
  let [newParticipantLastName, setNewParticipantLastName] = useState('');
  let [newParticipantLCATId, setNewParticipantLCATId] = useState(null);
  let [participantInviteModelOpen, setParticipantInviteModelOpen] = useState(false);
  let [batchParticipantData, setBatchParticipantData] = useState([]);
  let [batchInviteModalOpen, setBatchInviteModalOpen] = useState(false);
  let [batchInviteLCAT, setBatchInviteLCAT] = useState(null);
  let [participantInviteRequestInProgress, setParticipantInviteRequestInProgress] = useState(false);

  useEffect(() => {
    fetch("/api/v1/account/labor-categories/" + clientId).then((res) => {
      return res.json();
    }).then((laborCategoriesResponse) => {
      if(laborCategoriesResponse.laborCategories != null && laborCategoriesResponse.laborCategories.length > 0) {
        setNewParticipantLCATId(laborCategoriesResponse.laborCategories[0].laborCategoryId);
        setBatchInviteLCAT(laborCategoriesResponse.laborCategories[0].laborCategoryId);
      }
      setLaborCategories(laborCategoriesResponse.laborCategories);

    });
  }, []);

  let handleInviteNewParticipants = () => {
    let participantsRq = {participants: []};
    for(let i = 0; i < newParticipantData.length; i++) {
      participantsRq.participants.push({
        firstName: newParticipantData[i].firstName, 
        lastName: newParticipantData[i].lastName, 
        email: newParticipantData[i].email, 
        laborCategoryId: newParticipantData[i].laborCategory,
        clientId: clientId});
    }
    fetch("/api/v1/account/invite-participants", {
      method: "POST",
      cache: "no-cache",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(participantsRq)
    }).then((res) => {
      if(res.status == 200){
        return true;
      } else {
        return false;
      }
    }).then((successful) => {
      if(successful) {
        console.log("Successful");
      } else {
        console.log("Unsuccessful");
      }
    });
    setNewParticipantData([]);
  }

  let handleAddNewParticipantToList = () => {
    if(newParticipantEmail == "" || newParticipantFirstName == "" || newParticipantLastName == "" || newParticipantLCATId == null)
      return;
    for(let i = 0; i < newParticipantData.length; i++) {
      if(newParticipantData[i].email == newParticipantEmail) {
        // TODO: Error message, email already exists in list
        return;
      }
    }
    newParticipantData.push({email: newParticipantEmail, firstName: newParticipantFirstName, lastName: newParticipantLastName, laborCategory: newParticipantLCATId});
    setNewParticipantEmail("");
    setNewParticipantFirstName("");
    setNewParticipantLastName("");
    setNewParticipantLCATId(laborCategories[0].laborCategoryId);
  }

  let handleRemoveParticipantFromList = (emailToRemove) => {
    for(let i = 0; i < newParticipantData.length; i++) {
      if(newParticipantData[i].email == emailToRemove) {
        let updatedParticipantData = [...newParticipantData];
        updatedParticipantData.splice(i, 1);
        setNewParticipantData(updatedParticipantData);
        return;
      }
    }
  }

  let handleSelectLCAT = (event) => {
    setNewParticipantLCATId(event.target.value);
  }

  function inviteNewBatchParticipants() {
    let participantsRq = {participants: []};
    for(let i = 0; i < batchParticipantData.length; i++) {
      participantsRq.participants.push({
        firstName: batchParticipantData[i].firstName, 
        lastName: batchParticipantData[i].lastName, 
        email: batchParticipantData[i].email, 
        laborCategoryId: batchInviteLCAT,
        clientId: clientId});
    }
    fetch("/api/v1/account/invite-participants", {
      method: "POST",
      cache: "no-cache",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(participantsRq)
    }).then((res) => {
      if(res.status == 200){
        return true;
      } else {
        return false;
      }
    }).then((successful) => {
      if(successful) {
        console.log("Successful");
      } else {
        console.log("Unsuccessful");
      }
    });
    setBatchParticipantData([]);
  }

  function processUploadedBatchFile(results, file) {
    let participantsData = [];
    for(let i = 0; i < results.data.length; i++) {
      // Should validate row here
      let participantData = {
        email: results.data[i][0],
        firstName: results.data[i][1],
        lastName: results.data[i][2]
      };
      participantsData.push(participantData);
    }
    setBatchParticipantData(participantsData);
  }

  function uploadBatchFile() {
    let batchFileUploadInput = document.getElementById("BatchFileInput");
    let batchFile = batchFileUploadInput.files[0];

    if(batchFile != null) {
      papaparse.parse(batchFile, { worker: true, complete: processUploadedBatchFile });
    }
  }

  if(laborCategories == null) {
    return(<CircularProgress />);
  }
  else {
    return (
      <React.Fragment>
        <Modal open={participantInviteModelOpen} onClose={() => { setParticipantInviteModelOpen(false) }} >
          <ModalDialog>
            <DialogTitle>Invite New Participants</DialogTitle>
            <Stack spacing={2}>
              <Box sx={{ width: "100%", maxHeight: "400px", display: "flex", flexDirection: "column", alignItems: "flex-start", overflow: "hidden" }}>
                <TableContainer sx={{ overflowx: "initial" }}>
                  <Table sx={{ minWidth: 725 }} stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">Email</TableCell>
                        <TableCell align="right">First Name</TableCell>
                        <TableCell align="right">Last Name</TableCell>
                        <TableCell align="right">Labor Category</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { newParticipantData.map((p) => (
                          <TableRow key={p.email}>
                            <TableCell align="left"><IconButton size="small" onClick={(event) => {handleRemoveParticipantFromList(p.email)}}><ClearIcon /></IconButton></TableCell>
                            <TableCell align="right">{p.email}</TableCell>
                            <TableCell align="right">{p.firstName}</TableCell>
                            <TableCell align="right">{p.lastName}</TableCell>
                            <TableCell align="right">{(laborCategories.find((lcat) => { return lcat.laborCategoryId == p.laborCategory})).laborCategoryName}</TableCell>
                          </TableRow>
                      ))}
                      <TableRow key="">
                        <TableCell align="left"><IconButton size="small" onClick={handleAddNewParticipantToList}><AddIcon /></IconButton></TableCell>
                        <TableCell align="right"><TextField label="Email Address" variant="standard" value={newParticipantEmail} onChange={(event) => {setNewParticipantEmail(event.target.value)}} /></TableCell>
                        <TableCell align="right"><TextField label="First Name" variant="standard" value={newParticipantFirstName} onChange={(event) => {setNewParticipantFirstName(event.target.value)}} /></TableCell>
                        <TableCell align="right"><TextField label="Last Name" variant="standard" value={newParticipantLastName} onChange={(event) => {setNewParticipantLastName(event.target.value)}} /></TableCell>
                        <TableCell align="right">
                          <FormControl sx={{ width: 175 }}>
                            <Select value={newParticipantLCATId} onChange={handleSelectLCAT}>
                              {laborCategories.map((lcat) => {
                                return(<MenuItem value={lcat.laborCategoryId}>{lcat.laborCategoryName} ({(lcat.billingRate / 100).toLocaleString("en-US", {style: "currency", "currency": "USD"})}/Hr)</MenuItem>);
                              })}
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Button disabled={newParticipantData.length == 0} loading={participantInviteRequestInProgress} loadingPosition="start" onClick={ handleInviteNewParticipants }>Send Invites</Button>
            </Stack>
          </ModalDialog>
        </Modal>
        <Modal open={batchInviteModalOpen} onClose={() => { setBatchInviteModalOpen(false) }}>
          <ModalDialog>
            <Stack direction="column" alignItems="center" justifyContent="center" sx={{ width: "100%", mt: "10px" }} spacing={ 1 }>
              <FormControl sx={{ maxWidth: "350px", minWidth: "200px" }}>
                <InputLabel>Labor Category</InputLabel>
                <Select value={batchInviteLCAT} onChange={(event) => {setBatchInviteLCAT(event.target.value)}}>
                { laborCategories.map((lcat) => {
                  return (
                    <MenuItem value={lcat.laborCategoryId}>{lcat.laborCategoryName}</MenuItem>
                  )
                })}
                </Select>
              </FormControl>
              <Stack direction="row">
                <Button component="label" variant="outlined">
                  Upload CSV File
                  <input id="BatchFileInput" type="file" accept=".csv" hidden onChange={uploadBatchFile} />
                </Button>
                <Tooltip title="CSV format='email,first-name,last-name'"><HelpOutlineIcon /></Tooltip>
              </Stack>
              <Box sx={{ width: "100%", maxHeight: "500px", display: "flex", flexDirection: "column", alignItems: "flex-start", overflow: "hidden" }}>
                <TableContainer sx={{ overflowx: "initial" }}>
                  <Table sx={{ minWidth: 725 }} stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="right">First Name</TableCell>
                        <TableCell align="right">Last Name</TableCell>
                        <TableCell align="right">Labour Category</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {batchParticipantData.map((participantData) => {
                        return (
                          <TableRow>
                            <TableCell align="left">{participantData.email}</TableCell>
                            <TableCell align="right">{participantData.firstName}</TableCell>
                            <TableCell align="right">{participantData.lastName}</TableCell>
                            <TableCell align="right">{(laborCategories.find((lcat) => { return lcat.laborCategoryId == batchInviteLCAT})).laborCategoryName}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Button onClick={ () => inviteNewBatchParticipants() }>Submit</Button>
            </Stack>
          </ModalDialog>
        </Modal>
        <Chip label="Invite Participants" onClick={() => { setParticipantInviteModelOpen(true) }} color="primary" icon={<PersonAddIcon />} />
        <Chip label="Batch Invite" onClick={() => { setBatchInviteModalOpen(true) }} color="primary" icon={<GroupAddIcon />} />
      </React.Fragment>
    );
  }
}