import { Routes, Route } from "react-router-dom";
import CoachDashboard from "./CoachDashboard";
import CoachingSession from "./CoachingSession";
import ServicePage from 'shared/ServicePage';
import ServiceRequest from 'shared/ServiceRequest';


export default function CoachRouter() {
  return (
    <Routes>
      <Route path="/" element={null}>
        <Route index element={<CoachDashboard />} />
        <Route path="account" element={<CoachDashboard/>} />
        <Route path="coachingSession/:meetingId" element={<CoachingSession />} />
        <Route path="service-requests" element={<ServicePage />} />
        <Route path="service-requests/:serviceRequestId" element={<ServiceRequest />} />
      </Route>
    </Routes>
  );
}