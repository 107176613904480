import React, { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { SessionContext } from 'Contexts';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider, Checkbox,
    FormGroup, TableContainer, Table, TableRow, TableBody, TableHead, IconButton, ListItemText,
    OutlinedInput, InputAdornment, Input, Collapse } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import utc from 'dayjs/plugin/utc';
import DashboardElement from 'pageElements/DashboardElement';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

dayjs.extend(utc);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MenuBarButton = styled(Button)(({ theme }) => ({
    color: "white",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#6c89b8"
    }
}));

export default function Availability() {
    const sessionState = useContext(SessionContext);
    let [currentAvailability, setCurrentAvailability] = useState([]);
    let [availabilityModalOpen, setAvailabilityModalOpen] = useState(false);
    let [availabilityTimeRanges, setAvailabilityTimeRanges] = useState([]);
    let [canAddNewAvailabilityTimeRange, setCanAddNewAvailabilityTimeRange] = useState(true);

    let [availabilityEffectiveDate, setAvailabilityEffectiveDate] = useState(dayjs().utc());
    let [availabilityTimeZone, setAvailabilityTimeZone] = useState('America/New_York');
    let [newAvailabilityTimeRangeDayOfWeek, setNewAvailabilityTimeRangeDayOfWeek] = useState('MONDAY');
    let [newAvailabilityTimeRangeFromTime, setNewAvailabilityTimeRangeFromTime] = useState(dayjs().utc());
    let [newAvailabilityTimeRangeToTime, setNewAvailabilityTimeRangeToTime] = useState(dayjs().utc());

    useEffect(() => {
        updateCurrentAvailability();
    }, []);

    function updateCurrentAvailability() {
        fetch("/api/v1/account/availability/" + sessionState.accountId).then((res) => {
            return res.json();
        }).then((coachAvailability) => {
            setCurrentAvailability(coachAvailability.availability);
        });
    }

    function createUpdateAvailabilityRequest() {
        let rq = {
            availability: []
        };

        for(let i = 0; i < availabilityTimeRanges.length; i++) {
            availabilityTimeRanges[i].start = availabilityTimeRanges[i].start.format();
            availabilityTimeRanges[i].end = availabilityTimeRanges[i].end.format();
            availabilityTimeRanges[i].timeZone = availabilityTimeZone;
            availabilityTimeRanges[i].effectiveDate = availabilityEffectiveDate.local().format();
            rq.availability.push(availabilityTimeRanges[i]);
        }
        return rq;
    }

    function postAvailability() {
        let rq = createUpdateAvailabilityRequest();
        fetch("/api/v1/account/availability", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(rq)
        }).then((res) => {
            //updateCurrentAvailability();
            window.location.reload();
        });
    }

    function deleteAvailabilityTimeRange(index) {
        let atrs = [...availabilityTimeRanges];
        atrs.splice(index, 1);
        setAvailabilityTimeRanges(atrs);
    }

    function addAvailabilityTimeRange() {
        let availabilityTimeRange = {
            dayOfWeek: newAvailabilityTimeRangeDayOfWeek,
            start: newAvailabilityTimeRangeFromTime,
            end: newAvailabilityTimeRangeToTime,
            timeZone: null,
            effectiveDate: null,
            accountId: sessionState.accountId
        };

        let updatedAvailabilityTimeRanges = [...availabilityTimeRanges];
        updatedAvailabilityTimeRanges.push(availabilityTimeRange);
        setAvailabilityTimeRanges(updatedAvailabilityTimeRanges);

        setNewAvailabilityTimeRangeDayOfWeek('MONDAY');
        setNewAvailabilityTimeRangeFromTime(dayjs().utc());
        setNewAvailabilityTimeRangeToTime(dayjs().utc());
        setCanAddNewAvailabilityTimeRange(false);
    }

    function deleteCurrentAvailability(index) {
        let rq = { 
            availability: [
                currentAvailability[index]
            ]
        };
        fetch("/api/v1/account/delete-availability/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(rq)
        }).then((res) => {
            updateCurrentAvailability();
        });
    }

    return (
        <React.Fragment>
            <MenuBarButton startIcon={<CalendarMonthIcon />} onClick={() => setAvailabilityModalOpen(true)}>Availability</MenuBarButton>
            <Modal open={availabilityModalOpen} onClose={() => setAvailabilityModalOpen(false)}>
                <ModalDialog>
                    <ModalClose />
                    <Box sx={{ width: "90dvw", alignItems: "center", justifyContent: "center", mt:"10px", mb:"10px", ml: "auto", mr: "auto" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack direction="column" spacing={2} sx={{ alignItems: "center", justifyContent: "center", mb: "10px" }}>
                                <Typography><Box sx={{ fontWeight: 'bold' }}>Current Availability</Box></Typography>
                                <TableContainer sx={{ overflowx: "initial", mt: "10px", mb: "10px" }}>
                                    <Table sx={{ minWidth: "550px", height: "auto" }} stickyHeader size="small">
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell>Day of Week</StyledTableCell>
                                                <StyledTableCell>Effective Date</StyledTableCell>
                                                <StyledTableCell>From</StyledTableCell>
                                                <StyledTableCell>To</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            { currentAvailability.map((availability, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell><Button size="small" onClick={(event) => {deleteCurrentAvailability(index)}}>Delete</Button></StyledTableCell>
                                                        <StyledTableCell>{availability.dayOfWeek}</StyledTableCell>
                                                        <StyledTableCell>{dayjs(availability.effectiveDate).local().format("D MMM YYYY")}</StyledTableCell>
                                                        <StyledTableCell>{dayjs(availability.start).local().format("h:mm A")}</StyledTableCell>
                                                        <StyledTableCell>{dayjs(availability.end).local().format("h:mm A")}</StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Divider orientation='horizontal' />
                                <Typography><Box sx={{ fontWeight: 'bold' }}>Add New Availability</Box></Typography>
                                <TableContainer sx={{ overflowx: "initial", mt: "10px", mb: "10px" }}>
                                    <Table sx={{ minWidth: "550px", height: "auto"}} stickyHeader size="small">
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell>Day of Week</StyledTableCell>
                                                <StyledTableCell>From</StyledTableCell>
                                                <StyledTableCell>To</StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            { availabilityTimeRanges.map((atr, index) => {
                                                return(
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell><IconButton size="small" onClick={(event) => {deleteAvailabilityTimeRange(index)}}><ClearIcon /></IconButton></StyledTableCell>
                                                        <StyledTableCell>{atr.dayOfWeek}</StyledTableCell>
                                                        <StyledTableCell><TimePicker value={atr.start} readOnly timezone="system"/></StyledTableCell>
                                                        <StyledTableCell><TimePicker value={atr.end} readOnly timezone="system" /></StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                            { canAddNewAvailabilityTimeRange &&
                                                <StyledTableRow key="creationRow">
                                                    <StyledTableCell><Button onClick={(event) => {addAvailabilityTimeRange()}}>Add</Button></StyledTableCell>
                                                    <StyledTableCell>
                                                        <FormControl sx={{ width: 180 }}>
                                                            <Select
                                                            value={newAvailabilityTimeRangeDayOfWeek} 
                                                            onChange={(event) => {setNewAvailabilityTimeRangeDayOfWeek(event.target.value)}}
                                                            input={<OutlinedInput label="Day of Week" />}
                                                            menuProps={MenuProps}>
                                                                <MenuItem key={'SUNDAY'} value={'SUNDAY'}>Sunday</MenuItem>
                                                                <MenuItem key={'MONDAY'} value={'MONDAY'}>Monday</MenuItem>
                                                                <MenuItem key={'TUESDAY'} value={'TUESDAY'}>Tuesday</MenuItem>
                                                                <MenuItem key={'WEDNESDAY'} value={'WEDNESDAY'}>Wednesday</MenuItem>
                                                                <MenuItem key={'THURSDAY'} value={'THURSDAY'}>Thursday</MenuItem>
                                                                <MenuItem key={'FRIDAY'} value={'FRIDAY'}>Friday</MenuItem>
                                                                <MenuItem key={'SATURDAY'} value={'SATURDAY'}>Saturday</MenuItem>
                                                            </Select>
                                                        </FormControl></StyledTableCell>
                                                    <StyledTableCell><TimePicker label="From" timezone="system" onChange={(value) => setNewAvailabilityTimeRangeFromTime(value)} /></StyledTableCell>
                                                    <StyledTableCell><TimePicker label="To" timezone="system" onChange={(value) => setNewAvailabilityTimeRangeToTime(value)} /></StyledTableCell>
                                                </StyledTableRow>
                                            }
                                            { !canAddNewAvailabilityTimeRange &&
                                                <StyledTableRow key="creationRow">
                                                    <StyledTableCell colSpan={4}>
                                                        <IconButton size="small" onClick={(event) => {setCanAddNewAvailabilityTimeRange(true)}}><AddIcon /> Add Another</IconButton>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            }
                                            <StyledTableRow key="controls">
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell>
                                                    <FormControl sx={{ mb: "10px", height: "auto" }}>
                                                        <InputLabel>Time Zone</InputLabel>
                                                        <Select
                                                            label="Time Zone"
                                                            value={availabilityTimeZone} 
                                                            onChange={(event) => {setAvailabilityTimeZone(event.target.value)}}
                                                            input={<OutlinedInput label="Time Zone" />}
                                                            menuProps={MenuProps}>
                                                            <MenuItem key={'America/New_York'} value={'America/New_York'}>America/New York</MenuItem>
                                                            <MenuItem key={'America/Chicago'} value={'America/Chicago'}>America/Chicago</MenuItem>
                                                            <MenuItem key={'America/Denver'} value={'America/Denver'}>America/Denver</MenuItem>
                                                            <MenuItem key={'America/Los_Angeles'} value={'America/Los_Angeles'}>America/Los Angeles</MenuItem>
                                                            <MenuItem key={'America/Phoenix'} value={'America/Phoenix'}>America/Phoenix</MenuItem>
                                                            <MenuItem key={'Pacific/Honolulu'} value={'Pacific/Honolulu'}>Pacific/Honolulu</MenuItem>
                                                            <MenuItem key={'America/Juneau'} value={'America/Juneau'}>America/Juneau</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </StyledTableCell>
                                                <StyledTableCell><DatePicker label="Effective Date" value={availabilityEffectiveDate} timezone={"system"} onChange={(value) => setAvailabilityEffectiveDate(value)} /></StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Button variant="contained" onClick={() => postAvailability()}>Submit</Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Stack>
                        </LocalizationProvider>
                    </Box>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
}