import React, { useState, useEffect } from 'react';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
  Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
  Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider,
  Tooltip, IconButton } from '@mui/material';
import { Textarea } from '@mui/joy';

import InfoIcon from '@mui/icons-material/Info';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const EMAIL_VALIDATION_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


function CoachRegistration() {
  const [firstName, setFirstName] = useState('');
  const [middleInitial, setMiddleInitial] = useState('');
  const [lastName, setLastName] = useState('');
  const [preferredName, setPreferredName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneType, setPhoneType] = useState('MOBILE');
  const [password, setPassword] = useState('');
  const [yearsOfExperience, setYearsOfExperience] = useState(0);
  const [gender, setGender] = useState(null);
  const [ethinicity, setEthnicity] = useState(null);
  const [hispanicOrLatino, setHispanicOrLatino] = useState(null);
  const [education, setEducation] = useState('NONE');
  const [securityClearance, setSecurityClearance] = useState('NONE');
  const [profilePicture, setProfilePicture] = useState("/avatar.svg");
  const [profilePictureFile, setProfilePictureFile] = useState(null);
  const [resume, setResume] = useState(null);
  const [personalStatement, setPersonalStatement] = useState('');
  const [personalStatementLength, setPersonalStatementLength] = useState(0);
  const [certifications, setCertifications] = useState([]);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarAlerts, setSnackbarAlerts] = useState([]);

  const [coachMatchingQuestions, setCoachMatchingQuestions] = useState(null);


  function updatePersonalStatement(value) {
    setPersonalStatement(value);
    setPersonalStatementLength(value.length);
  }

  useEffect(() => {
    fetch("/api/v1/account/coach-survey").then((res) => {
      return res.json();
    }).then((coachMatchingQuestionsResponse) => {
      for(let i = 0; i < coachMatchingQuestionsResponse.questions.length; i++) {
        if(coachMatchingQuestionsResponse.questions[i].question.questionType == "MULTIPLE_CHOICE") {
          coachMatchingQuestionsResponse.questions[i].question["currentChoiceCount"] = 0;
          for(let j = 0; j < coachMatchingQuestionsResponse.questions[i].answers.length; j++) {
            coachMatchingQuestionsResponse.questions[i].answers[j]["value"] = 0;
          }
        } else {
          console.log("Unhandled survey question type: ", coachMatchingQuestionsResponse.questions[i].question.questionType);
        }
      }
      setCoachMatchingQuestions(coachMatchingQuestionsResponse);
    });
  }, []);

  function multipleChoiceSelection(answerIndex, questionIndex) {
    if(coachMatchingQuestions.questions[questionIndex].answers[answerIndex].value == 0) {
      if(coachMatchingQuestions.questions[questionIndex].question.currentChoiceCount < coachMatchingQuestions.questions[questionIndex].question.maxChoiceCount) {
        let updatedCoachMatchingQuestions = JSON.parse(JSON.stringify(coachMatchingQuestions));
        updatedCoachMatchingQuestions.questions[questionIndex].answers[answerIndex].value = 1;
        updatedCoachMatchingQuestions.questions[questionIndex].question.currentChoiceCount += 1;
        setCoachMatchingQuestions(updatedCoachMatchingQuestions);
      }
    } else {
      let updatedCoachMatchingQuestions = JSON.parse(JSON.stringify(coachMatchingQuestions));
      updatedCoachMatchingQuestions.questions[questionIndex].answers[answerIndex].value = 0;
      updatedCoachMatchingQuestions.questions[questionIndex].question.currentChoiceCount -= 1;
      setCoachMatchingQuestions(updatedCoachMatchingQuestions);
    }
  }

  function createCoachMatchingSurveyRq() {
    let rq = {completedSurvey: {
        surveyId: coachMatchingQuestions.surveyId,
        completionTime: new Date().toUTCString()  
      },
      completedSurveyAnswers: []};
      for(let i = 0; i < coachMatchingQuestions.questions.length; i++) {
        if(coachMatchingQuestions.questions[i].question.questionType == "MULTIPLE_CHOICE") {
          for(let j = 0; j < coachMatchingQuestions.questions[i].answers.length; j++) {
            let completedSurveyAnswer = {
              questionId: coachMatchingQuestions.questions[i].question.questionId,
              answerId: coachMatchingQuestions.questions[i].answers[j].answerId,
              answerValue: coachMatchingQuestions.questions[i].answers[j].value
            };
            rq.completedSurveyAnswers.push(completedSurveyAnswer);
          }
        }
      }
      return rq;
  }

  function CoachMatchingSurvey({coachSurvey}) {
    if(coachSurvey == null) {
      return (
        <CircularProgress />
      );
    } else {
      return (
        <Stack direction="column" spacing={2.0}>
          <Typography variant="h5" component="h2">Coach Matching Survey</Typography>
          {coachSurvey.questions.map((userSurveyQuestion, questionIndex) => {
            let questionHeader = <h3>{userSurveyQuestion.question.prompt}</h3>
            let questionBody = null;
            switch(userSurveyQuestion.question.questionType) {
              case "MULTIPLE_CHOICE":
                questionBody = 
                  <React.Fragment>
                    <h4>Select {userSurveyQuestion.question.minChoiceCount} to {userSurveyQuestion.question.maxChoiceCount} items</h4>
                    <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={0.5}>
                      { userSurveyQuestion.answers.map((answer, answerIndex) => {
                        if(answer.value == 1) {
                          return(<Chip label={answer.answerText} onClick={() => {multipleChoiceSelection(answerIndex, questionIndex)}} color="primary" variant="filled" />);
                        } else {
                          return(<Chip label={answer.answerText} onClick={() => {multipleChoiceSelection(answerIndex, questionIndex)}} variant="outlined" />);
                        }
                      })}
                    </Stack>
                  </React.Fragment>
                break;
              default:
                console.log("Coaching survey type unknown: ", userSurveyQuestion.question);
                break;
            }
            return (
              <React.Fragment>
                {questionHeader}
                {questionBody}
              </React.Fragment>
            );
          })}
        </Stack>
      );
    }
  }

  const handleGenderSelection = (event) => {
    if(event.target.value == "PREFER_NOT_TO_ANSWER")
      setGender(null);
    else
      setGender(event.target.value);
  }

  const handleEthnicitySelection = (event) => {
    if(event.target.value == "PREFER_NOT_TO_ANSWER")
      setEthnicity(null);
    else
      setEthnicity(event.target.value);
  }

  const handleHispanicOrLatinoSelection = (event) => {
    switch(event.target.value) {
      case "PREFER_NOT_TO_ANSWER":
        setHispanicOrLatino(null);
        break;
      case "HISPANIC_OR_LATINO":
        setHispanicOrLatino(true);
        break;
      case "NOT_HISPANIC_OR_LATINO":
        setHispanicOrLatino(false);
        break;
      default:
        setHispanicOrLatino(null);
        break;
    }
    
  }

  const handleEducationSelection = (event) => {
    setEducation(event.target.value);
  }

  const handleSecurityClearanceSelection = (event) => {
    setSecurityClearance(event.target.value);
  }

  const handleSetYearsOfExperience = (val) => {
    val = parseInt(val);
    if(val > 0 && val < 60)
      setYearsOfExperience(val);
  }

  const handlePhoneTypeSelection = (event) => {
    setPhoneType(event.target.value);
  };

  const handleProfilePictureUpload = (event) => {
    let fileUpload = document.getElementById("profilePictureFileElement");
    let file = fileUpload.files[0];
    setProfilePictureFile(file);
    let reader = new FileReader();

    reader.onloadend = function () {
      setProfilePicture(reader.result);
    }
    if(file)
      reader.readAsDataURL(file);
  }

  function validInputData() {
    // First name validations
    var alerts = [];
    var validInput = true;
    if(firstName == "") {
      validInput = false;
      alerts.push({severity:"error", message:"Missing first name"});
    } else if(firstName.length > 20) {
      validInput = false;
      alerts.push({severity:"error", message:"First name must be 20 characters or less"});
    } else if(!/^[A-Za-z\-]+$/.test(firstName)) {
      validInput = false;
      alerts.push({severity:"error", message:"First name must contain only letters or '-'"});
    }

    if(middleInitial.length > 1) {
      validInput = false;
      alerts.push({severity:"error", message:"Middle initial cannot be more than one character"});
    } else if(middleInitial.length == 1 && !/^[a-zA-Z]$/.test(middleInitial)) {
      validInput = false;
      alerts.push({severity:"error", message:"Middle initial may only be a letter"});
    }

    if(lastName == "" || lastName == null) {
      validInput = false;
      alerts.push({severity:"error", message:"Missing last name"});
    } else if(lastName.length > 20) {
      validInput = false;
      alerts.push({severity:"error", message:"Last name must be 20 characters or less"});
    } else if(!/^[A-Za-z\-]+$/.test(lastName)) {
      validInput = false;
      alerts.push({severity:"error", message:"Last name must contain only letters or '-'"});
    }

    if(preferredName.length > 20) {
      validInput = false;
      alerts.push({severity:"error", message:"Preferred name must be 20 characters or less"});
    } else if(preferredName.length > 0 && !/^[A-Za-z\-]+$/.test(preferredName)) {
      validInput = false;
      alerts.push({severity:"error", message:"Preferred name must contain only letters or '-'"});
    }

    if(emailAddress == "" || emailAddress == null) {
      validInput = false;
      alerts.push({severity:"error", message:"Missing email address"});
    } else if(!EMAIL_VALIDATION_REGEX.test(emailAddress.toLowerCase())) {
      validInput = false;
      alerts.push({severity:"error", message:"Invalid email address"});
    } else if(emailAddress.length > 60) {
      validInput = false;
      alerts.push({severity:"error", message:"Email address length must be 60 characters or less"});
    }

    if(phoneNumber == "" || phoneNumber == null) {
      validInput = false;
      alerts.push({severity:"error", message:"Missing phone number"});
    } else if(phoneNumber.length < 10) {
      validInput = false;
      alerts.push({severity:"error", message:"Phone number is too short"});
    } else if(!/^[0-9]+$/.test(phoneNumber)) {
      validInput = false;
      alerts.push({severity:"error", message:"Phone number must only contain numbers"});
    }
    
    if(password == "" || password == null) {
      validInput = false;
      alerts.push({severity:"error", message:"Password cannot be empty"});
    } else if(password.length < 10) {
      validInput = false;
      alerts.push({severity:"error", message:"Password must be at least 10 characters long"});
    } else if(!/^.*[a-z].*$/.test(password) || !/^.*[A-Z].*$/.test(password) || !/^.*[0-9].*$/.test(password)) {
      validInput = false;
      alerts.push({severity:"error", message:"Password must contain at least one capital letter, one lowercase letter, one special character (!, $, @, %, &, or ^), and one number"});
    } else if(!["!", "$", "@", "%", "&", "^"].some(c => password.includes(c))) {
      validInput = false;
      alerts.push({severity:"error", message:"Password must contain at least one one special character (!, $, @, %, &, or ^), and one number"});
    } // Add a check for unallowed characters

    if(personalStatement == "") {
      validInput = false;
      alerts.push({severity:"error", message:"Personal statement may not be empty"});
    } else if(personalStatement.length > 500) {
      validInput = false;
      alerts.push({severity:"error", message:"Personal statement must be 500 characters or less"});
    }

    setSnackbarAlerts(alerts);
    if(alerts.length > 0) {
      setShowSnackbar(true);
      window.scroll({
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
       });
    }
    return validInput;
  }

  const handleCreateCoachAccount = (event) => {
    console.log("handleCreateCoachAccount");
    // Check and sanitize inputs, snackbar on error
    if(!validInputData()) {
      return;
    }
    // Make separate requests for profile picture, certifications, and resume after account creation.

    let createCoachAccountRq = {
      firstName: firstName,
      middleInitial: middleInitial,
      lastName: lastName,
      preferredName: preferredName,
      email: emailAddress,
      password: password,
      phoneNumber: phoneNumber,
      phoneType: phoneType,
      yearsOfExperience: yearsOfExperience,
      gender: gender,
      ethinicity: ethinicity,
      hispanicOrLatino: hispanicOrLatino,
      educationLevel: education,
      securityClearance: securityClearance,
      personalStatement: personalStatement,
      coachSurvey: createCoachMatchingSurveyRq()
    };

    console.log("accountCreationRq: " + JSON.stringify(createCoachAccountRq));
    setShowBackdrop(true);

    fetch("/api/v1/account/create-coach-account", {
      method: "POST",
      cache: "no-cache",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(createCoachAccountRq)
    }).then((res) => {
      if(res.status != 200) {
        // Stop backdrop and show error snackbar message
        setShowBackdrop(false);
        setSnackbarAlerts({severity:"error", message:"Error while creating account"});
        window.scroll({
          top: 0, 
          left: 0, 
          behavior: 'smooth' 
         });
        setShowSnackbar(true);
      } else {
        // Post profile picture, certifications, and resume
        //window.location.href = "/";
        return res.json();
      }
    }).then((res) => {
      localStorage["sessionData"] = JSON.stringify(res);

      let fileUpload = document.getElementById("profilePictureFileElement");
      if(fileUpload.files[0] == null)
        window.location.href = "/";
      let formData = new FormData();
      formData.append("file", fileUpload.files[0]);

      console.log("Uploaded file size: " + fileUpload.files[0].size);

      fetch("/api/v1/account/profile-picture/" + res.accountId, {
        method: "POST",
        cache: "no-cache",
        body: formData
      }).then((res) => {
        if(res.status == 200) {
          console.log("profile picture uploaded");
        } else {
          console.log("profile picture failed to upload with response: " + res.status);
        }
        window.location.href = "/";
      })
    });
  }

  return (
      <div className="PageBody">
        <Stack direction="column" spacing={2} sx={{ mb: "15px" }}>
          <Backdrop sx={{ color: "#fff"}} open={ showBackdrop }>
            <CircularProgress />
          </Backdrop>
          { snackbarAlerts.map(alert => <Alert severity={ alert.severity }>{ alert.message }</Alert>) }
          <Typography variant="h3" component="h1">Coach Registration</Typography>
          <Divider />
          <Stack justifyContent="center" alignItems="center" direction="column" spacing={0.5}>
            <Typography variant="h5" component="h2">Personal Information</Typography>
            <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
              <TextField required fullwidth id="firstNameInput" label="First Name" variant="standard" onChange={(event) => { setFirstName(event.target.value)}}/>
              <TextField fullwidth id="middleInitialInput" label="Middle Initial" variant="standard" onChange={(event) => { setMiddleInitial(event.target.value)}}/>
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
              <TextField required fullwidth id="lastNameInput" label="Last Name" variant="standard" onChange={(event) => { setLastName(event.target.value)}}/>
              <TextField fullwidth id="preferredNameInput" label="Preferred Name" variant="standard" onChange={(event) => { setPreferredName(event.target.value)}} />
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
              <TextField required fullwidth id="emailAddressInput" label="Email Address" variant="standard" onChange={(event) => { setEmailAddress(event.target.value)}}/>
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="flex-end" useFlexGap flexWrap="wrap" spacing={0.25}>
              <TextField required fullwidth id="password-input" label="Password" type="password" variant="standard" onChange={(event) => { setPassword(event.target.value)}}/>
              <Tooltip title="Password must be 10 characters, and contain at least 1 number, special character (!, $, @, %, &, or ^), capital, and lowercase letter">
                  <IconButton>
                      <InfoIcon />
                  </IconButton>
              </Tooltip>
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
              <TextField required id="phoneNumberInput" label="Phone Number" type="number" variant="standard" onChange={(event) => { setPhoneNumber(event.target.value)}}/>
              <FormControl required variant="standard" sx={{ m:0, minWidth: 120 }}>
                <Select labelId="phoneTypeLabel" id="phoneTypeInput" value={phoneType} label="Phone Type" onChange={handlePhoneTypeSelection}>
                  <MenuItem value={"MOBILE"}>Mobile</MenuItem>
                  <MenuItem value={"HOME"}>Home</MenuItem>
                  <MenuItem value={"OFFICE"}>Office</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
              <TextField id="yearsOfExperienceInput" label="Years of Experience" type="number" variant="standard" onChange={(event) => { handleSetYearsOfExperience(event.target.value)}} />
            </Stack>
          </Stack>
          <FormControl>
            <FormLabel>Gender</FormLabel>
            <RadioGroup row id="genderSelectionRadioGroup">
              <FormControlLabel value="MALE" control={<Radio onChange={handleGenderSelection} />} label="Male" />
              <FormControlLabel value="FEMALE" control={<Radio onChange={handleGenderSelection} />} label="Female" />
              <FormControlLabel value="NONBINARY" control={<Radio onChange={handleGenderSelection} />} label="Nonbinary" />
              <FormControlLabel value="OTHER" control={<Radio onChange={handleGenderSelection} />} label="Other" />
              <FormControlLabel value="PREFER_NOT_TO_ANSWER" control={<Radio onChange={handleGenderSelection} />} label="Prefer not to answer" />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Ethnicity</FormLabel>
            <RadioGroup row id="ethnicitySelectionRadioGroup">
              <FormControlLabel value="AMERICAN_INDIAN_OR_ALASKA_NATIVE" control={<Radio onChange={handleEthnicitySelection} />} label="American Indian or Alaskan Native" />
              <FormControlLabel value="ASIAN" control={<Radio onChange={handleEthnicitySelection} />} label="Asian" />
              <FormControlLabel value="BLACK_OR_AFRICAN_AMERICAN" control={<Radio onChange={handleEthnicitySelection} />} label="Black or African American" />
              <FormControlLabel value="NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER" control={<Radio onChange={handleEthnicitySelection} />} label="Native Hawaiian or Other Pacific Islander" />
              <FormControlLabel value="MIDDLE_EASTERN_OR_NORTH_AFRICAN" control={<Radio onChange={handleEthnicitySelection} />} label="Middle Eastern or North African" />
              <FormControlLabel value="WHITE" control={<Radio onChange={handleEthnicitySelection} />} label="White" />
              <FormControlLabel value="PREFER_NOT_TO_ANSWER" control={<Radio onChange={handleEthnicitySelection} />} label="Prefer not to answer" />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Hispanic or Latino</FormLabel>
            <RadioGroup row id="hispanicOrLatinoSelectionRadioGroup">
              <FormControlLabel value="HISPANIC_OR_LATINO" control={<Radio onChange={handleHispanicOrLatinoSelection} />} label="Hispanic or Latino" />
              <FormControlLabel value="NOT_HISPANIC_OR_LATINO" control={<Radio onChange={handleHispanicOrLatinoSelection} />} label="Not Hispanic or Latino" />
              <FormControlLabel value="PREFER_NOT_TO_ANSWER" control={<Radio onChange={handleHispanicOrLatinoSelection} />} label="Prefer not to asnwer" />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Highest Education Achieved</FormLabel>
            <RadioGroup row id="educationSelectionRadioGroup">
              <FormControlLabel value="NONE" control={<Radio onChange={handleEducationSelection} />} label="None" />
              <FormControlLabel value="HIGH_SCHOOL" control={<Radio onChange={handleEducationSelection} />} label="High school" />
              <FormControlLabel value="GED" control={<Radio onChange={handleEducationSelection} />} label="GED" />
              <FormControlLabel value="SOME_COLLEGE" control={<Radio onChange={handleEducationSelection} />} label="Some college" />
              <FormControlLabel value="ASSOCIATES" control={<Radio onChange={handleEducationSelection} />} label="Associate's degree" />
              <FormControlLabel value="BACHELORS" control={<Radio onChange={handleEducationSelection} />} label="Bachelor's degree" />
              <FormControlLabel value="MASTERS" control={<Radio onChange={handleEducationSelection} />} label="Master's degree" />
              <FormControlLabel value="DOCTORATE" control={<Radio onChange={handleEducationSelection} />} label="Doctorate degree" />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Current Active Security Clearance</FormLabel>
            <RadioGroup row id="clearanceSelectionRadioGroup">
              <FormControlLabel value="NONE" control={<Radio onChange={handleSecurityClearanceSelection} />} label="None" />
              <FormControlLabel value="CONFIDENTIAL" control={<Radio onChange={handleSecurityClearanceSelection} />} label="Confidential" />
              <FormControlLabel value="SECRET" control={<Radio onChange={handleSecurityClearanceSelection} />} label="Secret" />
              <FormControlLabel value="TOP_SECRET" control={<Radio onChange={handleSecurityClearanceSelection} />} label="Top secret" />
              <FormControlLabel value="SCI" control={<Radio onChange={handleSecurityClearanceSelection} />} label="Top secret SCI" />
            </RadioGroup>
          </FormControl>
          <Divider />
          <CoachMatchingSurvey coachSurvey={coachMatchingQuestions} />
          <Divider />
          <Stack direction="column" spacing={1.5}>
            <Typography variant="h5" component="h2">Your Coaching Profile</Typography>
            <Stack direction="row" justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={0.5}>
              <Card sx={{ display: "flex", alignItems: "center", justifyContent: "left", width: 120, height: 120 }}>
                <CardMedia component="img" sx={{ width: 120, height: 115, borderRadius: "50%", padding: "10px 10px 10px 10px" }} image={ profilePicture } />
              </Card>
            </Stack>
          </Stack>
          <Stack direction="column" fullwidth justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={2}>
            <Button component="label" variant="contained" maxWidth="200px">
              Upload profile picture
              <VisuallyHiddenInput type="file" id="profilePictureFileElement" accept=".jpg,.jpeg,.png" onChange={ handleProfilePictureUpload } />
            </Button>
            <Box sx={{ minWidth: "80%"}} >
              <Stack direction="column" sx={{ width: "100%"}}>
                <Textarea placeholder="What makes you unique?" minRows={3} size="lg" variant="outlined" onChange={(event) => { updatePersonalStatement(event.target.value)}} />
                <Typography>Character limit: {personalStatementLength}/500</Typography>
              </Stack>
            </Box>
            <Button component="label" variant="contained" maxWidth="200px" onClick={ handleCreateCoachAccount }>Create Account</Button>
          </Stack>
          {/*<h3>Certifications</h3>
          <Stack direction="column" fullwidth justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={0.5}>
            <p>Certifications placeholder</p>
          </Stack>*/}
        </Stack>
      </div>
  );
}

export default CoachRegistration;
