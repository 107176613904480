import React, { useState, useContext, Fragment, forwardRef } from 'react';
import { SessionContext } from 'Contexts';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import Input from '@mui/joy/Input';
import DialogContent from '@mui/joy/DialogContent';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
  Chip, Stack, Paper, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
  Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Dialog, DialogActions, Button } from '@mui/material';
import DashboardElement from 'pageElements/DashboardElement';


const TextMaskAdapter = forwardRef(function TextMaskAdapter(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
            '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        />
    );
});

TextMaskAdapter.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const MenuBarButton = styled(Button)(({ theme }) => ({
    color: "white",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#6c89b8"
    }
}));

export default function OrganizationalDashboardButtons() {
    const sessionState = useContext(SessionContext);
    const [createOrgAdminDialogOpen, setCreateOrgAdminDialogOpen] = useState(false);
    const [createOrgAdminValues, setCreateOrgAdminValues] = useState({"email": null, "firstName": null, "lastName": null, "adminType": "ORG", "clientName": null});
    const [creatingOrgAdminInProgress, setCreatingOrgAdminInProgress] = useState(false);

    
    const [createClientDialogOpen, setCreateClientDialogOpen] = useState(false);
    const [createClientValues, setCreateClientValues] = useState({"clientName": null, "shortName": null, "email": null, "phone": "", "website": null, "description": null});
    const [createClientPhone, setCreateClientPhone] = useState('');
    const [createClientInProgress, setCreateClientInProgress] = useState(false);

    const [sendEmailMessageModalOpen, setSendEmailMessageModalOpen] = useState(false);
    const [emailMessageRecipient, setEmailMessageRecipient] = useState("");
    const [emailMessageSubject, setEmailMessageSubject] = useState("");
    const [emailMessageBody, setEmailMessageBody] = useState("");
    const [emailMessageSending, setEmailMessageSending] = useState(false);

    const handleCreateAdmin = (event) => {
        setCreatingOrgAdminInProgress(true);
        fetch("/api/v1/account/create-admin-account", {
            method: "POST",
            cache: "no-cache",
            mode: "cors",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(createOrgAdminValues)
        }).then((res) => {
            if(res.status != 200) {
              console.log("Failed to submit admin account creation request");
            } else {
              console.log("Admin account creation successful")
            }
            setCreatingOrgAdminInProgress(false);
            setCreateOrgAdminDialogOpen(false);
            // Set all values `createOrgAdminValues` back to null
        });
    }

    const handleCreateClient = (event) => {
        createClientValues.phone = createClientPhone;
        console.log("Client creation request: ", JSON.stringify(createClientValues));
        setCreateClientInProgress(true);
        fetch("/api/v1/account/create-client", {
            method: "POST",
            cache: "no-cache",
            mode: "cors",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(createClientValues)
        }).then((res) => {
            if(res.status != 200) {
              console.log("Client creation request failed");
              return null;
            } else {
              console.log("Client creation successful");
              return res.json();
            }
            setCreatingOrgAdminInProgress(false);
        }).then((res) => {
            if(res == null)
                return;
            console.log(res);
            window.location.href = "/client/" + res.clientId;
        });
    }

    function validateCanSendEmailMessage() {
        return emailMessageRecipient.length > 0 && emailMessageSubject > 0 && emailMessageBody > 0;
    }

    function sendEmailMessage() {
        setEmailMessageSending(true);
        let rq = {
            recipientAddress: emailMessageRecipient,
            subject: emailMessageSubject,
            messageBody: emailMessageBody
        };

        fetch("/api/v1/account/send-email-message", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(rq)
        }).then((res) => {
            if(res.status == 200) {
                setSendEmailMessageModalOpen(false);
            } else {

            }
            setEmailMessageSending(false);
        });
    }

    return (
        <React.Fragment>
            <Modal open={sendEmailMessageModalOpen} onClose={() => setSendEmailMessageModalOpen(false)}>
                <ModalDialog>
                    <DialogTitle>Send An Email Message From NoReply@Est2ar.com</DialogTitle>
                    <Stack spacing={2}>
                        <FormControl>
                            <FormLabel>Recipient Email Address</FormLabel>
                            <Input autofocus required onChange={(e) => setEmailMessageRecipient(e.target.value)} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Email Subject</FormLabel>
                            <Input required onChange={(e) => setEmailMessageSubject(e.target.value)} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Email Body</FormLabel>
                            <Input required onChange={(e) => setEmailMessageBody(e.target.value)} />
                        </FormControl>
                        <Button onClick={() => sendEmailMessage()} disabled={!validateCanSendEmailMessage} loadingPosition="start" loading={emailMessageSending}>Send</Button>
                    </Stack>
                </ModalDialog>
            </Modal>
            <Modal open={createOrgAdminDialogOpen} onClose={() => { setCreateOrgAdminDialogOpen(false) }} >
                <ModalDialog>
                    <DialogTitle>Create {sessionState.organizationName} Administrator</DialogTitle>
                    <Stack spacing={2}>
                        <FormControl>
                            <FormLabel>Email Address</FormLabel>
                            <Input autofocus required onChange={(event) => {createOrgAdminValues.email = event.target.value}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>First Name</FormLabel>
                            <Input required onChange={(event) => {createOrgAdminValues.firstName = event.target.value}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Last Name</FormLabel>
                            <Input required onChange={(event) => {createOrgAdminValues.lastName = event.target.value}}/>
                        </FormControl>
                        <Button loading={creatingOrgAdminInProgress} loadingPosition="start" onClick={handleCreateAdmin}>Send Registration Invite</Button>
                    </Stack>
                </ModalDialog>
            </Modal>
            <Modal open={createClientDialogOpen} onClose={() => { setCreateClientDialogOpen(false) }} >
                <ModalDialog>
                    <DialogTitle>Create New Client</DialogTitle>
                    <Stack spacing={2}>
                        <FormControl>
                            <FormLabel>Client Name</FormLabel>
                            <Input autofocus required onChange={(event) => {createClientValues.clientName = event.target.value}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Short Name</FormLabel>
                            <Input required onChange={(event) => {createClientValues.shortName = event.target.value}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Primary Contact Email Address</FormLabel>
                            <Input required onChange={(event) => {createClientValues.email = event.target.value}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Primary Contact Phone Number</FormLabel>
                            <Input required value={createClientPhone} onChange={(event) => {setCreateClientPhone(event.target.value)}} slotProps={{ input: {component: TextMaskAdapter} }}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Website</FormLabel>
                            <Input required onChange={(event) => {createClientValues.website = event.target.value}}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <Input required onChange={(event) => {createClientValues.description = event.target.value}}/>
                        </FormControl>
                        <Button loading={creatingOrgAdminInProgress} loadingPosition="start" onClick={handleCreateClient}>Create Client</Button>
                    </Stack>
                </ModalDialog>
            </Modal>
            <MenuBarButton onClick={() => { setCreateOrgAdminDialogOpen(true) }}>Create {sessionState.organizationName} Administrator</MenuBarButton>
            <MenuBarButton onClick={() => { setCreateClientDialogOpen(true) }}>Create Client</MenuBarButton>
            <MenuBarButton onClick={() => window.location.href="/analytics"}>Organization Analytics</MenuBarButton>
            <MenuBarButton onClick={() => window.location.href="/service-requests"}>Service Requests</MenuBarButton>
            <MenuBarButton onClick={() => setSendEmailMessageModalOpen(true)}>Send Est2ar Email Message</MenuBarButton>
        </React.Fragment>
    );
}