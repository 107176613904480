import React, { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { SessionContext } from 'Contexts';
import { Textarea } from '@mui/joy';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider, Checkbox,
    FormGroup, TableContainer, Table, TableRow, TableBody, TableHead, IconButton, ListItemText,
    OutlinedInput, InputAdornment, Input, Collapse, TablePagination, Skeleton, Modal, Dialog,
    DialogContent, DialogContentText, DialogActions, DialogTitle, Tooltip } from '@mui/material';
import DashboardElement from 'pageElements/DashboardElement';
import { CreateLaborCategoriesButton, SetDefaultAllocatedHoursButton } from './ContractHoursButtons';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const ClickableStyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
        backgroundColor: theme.palette.primary.light,
    },
    "&:active": {
        backgroundColor: theme.palette.primary.dark,
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ContractHoursDisplay({ clientId }) {
    let [laborCategories, setLaborCategories] = useState(null);
    let [contractHoursByLCATId, setContractHoursByLCATId] = useState(null);
    let [addHoursDialogOpen, setAddHoursDialogOpen] = useState(false);
    let [addHoursValue, setAddHoursValue] = useState(0);
    let [selectedLCATId, setSelectedLCATId] = useState(null);
    
    useEffect(() => {
      fetch("/api/v1/account/contract-hours/" + clientId).then((res) => {
        return res.json();
      }).then((contractHoursResponse) => {
        let contractHoursByLCATMap = {};
        for(let i = 0; i < contractHoursResponse.contractHours.length; i++) {
          contractHoursByLCATMap[contractHoursResponse.contractHours[i].lcatId] = contractHoursResponse.contractHours[i];
        }
        setContractHoursByLCATId(contractHoursByLCATMap);
      });
  
      fetch("/api/v1/account/labor-categories/" + clientId).then((res) => {
        return res.json();
      }).then((laborCategoriesResponse) => {
        let laborCategoriesMap = {};
        for(let i = 0; i < laborCategoriesResponse.laborCategories.length; i++) {
          laborCategoriesMap[laborCategoriesResponse.laborCategories[i].laborCategoryId] = laborCategoriesResponse.laborCategories[i];
        }
        setLaborCategories(laborCategoriesMap);
      });
    }, []);

    function refreshContractHours() {
        fetch("/api/v1/account/contract-hours/" + clientId).then((res) => {
            return res.json();
        }).then((contractHoursResponse) => {
            let contractHoursByLCATMap = {};
            for(let i = 0; i < contractHoursResponse.contractHours.length; i++) {
                contractHoursByLCATMap[contractHoursResponse.contractHours[i].lcatId] = contractHoursResponse.contractHours[i];
            }
            setContractHoursByLCATId(contractHoursByLCATMap);
        });
    }

    function submitAddHoursRequest() {
        if(clientId == null || selectedLCATId == null || addHoursValue == null) {
            console.log("Invalid parameters for submitAddHoursRequest");
            return;
        }
        fetch("/api/v1/account/contract-hours/" + clientId + "/" + selectedLCATId + "/" + addHoursValue, {
            method: "POST"
        }).then((res) => {
            refreshContractHours();
            setAddHoursValue(null);
            setSelectedLCATId(null);
        });
    }
  
    if(laborCategories == null || contractHoursByLCATId == null) {
      return(null);
    } else {
      return (
        <DashboardElement elevation={1}>
            <Dialog open={addHoursDialogOpen} onClose={() => setAddHoursDialogOpen(false)}>
                <DialogTitle>Add Hours To Labor Category</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { selectedLCATId != null && ("Add hours to labor category " + laborCategories[selectedLCATId].laborCategoryName)}
                    </DialogContentText>
                    <TextField
                      autoFocus
                      required
                      type="number"
                      margin="dense"
                      label="Number of hours"
                      fullWidth
                      variant="standard"
                      value={addHoursValue}
                      onChange={(event) => setAddHoursValue(event.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={addHoursValue < 1} onClick={() => {submitAddHoursRequest(); setAddHoursDialogOpen(false);}}>Submit</Button>
                </DialogActions>
            </Dialog>
            <Box sx={{ width: "100%" }}>
              <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Contract Hours</Typography>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }}>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell align="left">Labor Category</StyledTableCell>
                            <StyledTableCell align="right">Availabile Hours</StyledTableCell>
                            <StyledTableCell align="right">Total Hours</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(contractHoursByLCATId).map((lcatId) => {
                            return (
                            <StyledTableRow key={lcatId}>
                                <StyledTableCell align="left">{laborCategories[lcatId].laborCategoryName}</StyledTableCell>
                                <StyledTableCell align="right">{contractHoursByLCATId[lcatId].hoursAvailable}</StyledTableCell>
                                <StyledTableCell align="right">{contractHoursByLCATId[lcatId].hoursTotal}</StyledTableCell>
                                <StyledTableCell align="right"><Button onClick={() =>  {setSelectedLCATId(lcatId); setAddHoursDialogOpen(true)}}>Add Hours</Button></StyledTableCell>
                            </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Stack direction="row" spacing={ 0.5 } alignItems="center" justifyContent="flex-start" sx={{ margin: 1 }}>
                <CreateLaborCategoriesButton clientId={clientId} />
                <SetDefaultAllocatedHoursButton clientId={clientId} />
            </Stack>
        </DashboardElement>
      );
    }
}