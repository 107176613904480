import { useState, useEffect, useContext } from 'react';
import { SessionContext } from 'Contexts';
import { Button, Stack, Avatar, Menu, MenuItem, Box } from '@mui/material';
import NotificationBell from 'pageElements/NotificationBell';

export default function Header() {
    const sessionState = useContext(SessionContext);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
    const profileMenuOpen = Boolean(profileMenuAnchor);
    const [profilePicture, setProfilePicture] = useState(null);

    function logout() {
        fetch("/api/v1/account/logout", {
            method: "POST"
        }).then(res => {
            window.location.replace('/');
        });
    }

    useEffect(() => {
        if(sessionState != null) {
            setIsLoggedIn(sessionState.hasSession);
            setProfilePicture(<Avatar alt={sessionState.name} src={"/api/v1/account/profile-picture/" + sessionState.accountId} />);
        }
        else {
            setIsLoggedIn(false);
        }
    }, [sessionState, setIsLoggedIn]);

    return (
        <Box sx={{ height: "8vh", width: "100%", bgcolor: "#00143b", borderBottom: 0, borderColor: "grey.100", zIndex: 1 }}>
            <Menu open={profileMenuOpen} anchorEl={profileMenuAnchor} onClose={() => setProfileMenuAnchor(null)}>
                <MenuItem onClick={() => { window.location.replace('/') }}>Dashboard</MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={0.25} sx={{ margin: "0 0 0 0", width: "100%" }}>
                { isLoggedIn && <NotificationBell /> }
                { isLoggedIn && <Button startIcon={ profilePicture } onClick={(event) => setProfileMenuAnchor(event.currentTarget)}></Button> }
            </Stack>
        </Box>
    );
}