import React, { useState, useEffect, useContext } from 'react';
import { SessionContext } from 'Contexts';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
  Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
  Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Skeleton, Tooltip,
  ButtonGroup } from '@mui/material';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ResumeViewer from 'pageElements/ResumeViewer';


const MenuBarButton = styled(Button)(({ theme }) => ({
    color: "white",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#6c89b8"
    }
}));

const DisabledButton = styled(Button)(({ theme }) => ({
  '&:disabled': {
    color: "#6b6b6b",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: "none",
  }
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function CoachAccount() {
    const sessionState = useContext(SessionContext);
    let [accountPanelOpen, setAccountPanelOpen] = useState(false);
    let [resumeOpen, setResumeOpen] = useState(false);
    let [reloadResume, setReloadResume] = useState(0);
    let [coachProfile, setCoachProfile] = useState(null);
    let [changesSaved, setChangesSaved] = useState(true);
    let [coachCertLevels, setCoachCertLevels] = useState([]);

    useEffect(() => {
        fetch("/api/v1/account/coach-profile/" + sessionState.accountId).then((res) => {
            return res.json();
        }).then((coachProfileRes) => {
            setCoachProfile(coachProfileRes);
        });

        fetch("/api/v1/account/coach-certification-levels").then((res) => {
            return res.json();
        }).then((coachCertificationLevelsResponse) => {
            setCoachCertLevels(coachCertificationLevelsResponse.certificationLevels);
        });
    }, []);

    function updateCoachProfile() {
        if(changesSaved == true)
            return;
        fetch("/api/v1/account/coach-profile", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(coachProfile)
        }).then((res) => {
            setChangesSaved(true);
        });
    }

    function updateCertification(value) {
        let updatedCoachProfile = {...coachProfile};
        updatedCoachProfile.certificationId = value;
        setCoachProfile(updatedCoachProfile);
        setChangesSaved(false);
    }

    function updateTimeZone(value) {
        let updatedCoachProfile = {...coachProfile};
        updatedCoachProfile.timeZone = value;
        setCoachProfile(updatedCoachProfile);
        setChangesSaved(false);
    }

    function updateYearsOfExperience(value) {
        if(value < 0)
            return;
        if(value > 60)
            return;
        let updatedCoachProfile = {...coachProfile};
        updatedCoachProfile.yearsOfExperience = value;
        setCoachProfile(updatedCoachProfile);
        setChangesSaved(false);
    }

    function uploadResume(event) {
        console.log("uploadResume");
        let fileUpload = document.getElementById("resumeFileElement");
        if(fileUpload.files[0] == null)
            window.location.href = "/";
        let formData = new FormData();
        formData.append("file", fileUpload.files[0]);
        fetch("/api/v1/account/resume/" + sessionState.accountId, {
            method: "POST",
            cache: "no-cache",
            body: formData
        }).then((res) => {
            setReloadResume(reloadResume + 1);
            if(!coachProfile.hasResume) {
                let updatedCoachProfile = {...coachProfile};
                updatedCoachProfile.hasResume = true;
                setCoachProfile(updatedCoachProfile);
            }
        });
    }

    if(coachProfile == null)
        return null;
    return (
        <React.Fragment>
            <MenuBarButton onClick={() => setAccountPanelOpen(true)} startIcon={<ManageAccountsIcon />}>Account</MenuBarButton>
            <Modal open={accountPanelOpen} onClose={() => setAccountPanelOpen(false)} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Sheet variant="outlined" sx={{ minWidth: 400, minHeight: 600, borderRadius: 'md', p: 3, boxShadow: 'lg' }}>
                    <ModalClose variant="outlined" />
                    <ResumeViewer reload={reloadResume} lazyLoad={true} title="Your Resume/Bio" accountId={sessionState.accountId} open={resumeOpen} onClose={() => setResumeOpen(false)} />
                    <Stack direction="column"  alignItems="center" justifyContent="center" spacing={1.5}>
                        <Typography variant="h4">Your Account</Typography>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            <Typography>Years of experience</Typography>
                            <TextField value={coachProfile.yearsOfExperience} onChange={(e) => updateYearsOfExperience(e.target.value)} type="number" InputLabelProps={{  shrink: true }}/>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            <Typography>Coaching Certification</Typography>
                            <Select value={coachProfile.certificationId} onChange={(e) => updateCertification(e.target.value)}>
                                {coachCertLevels.map((cert) => {
                                    return(
                                        <MenuItem value={cert.certificationId}>{cert.name} ({cert.shortName})</MenuItem>
                                    );
                                })}
                            </Select>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            <Typography>Time Zone</Typography>
                            <Select value={coachProfile.timeZone} onChange={(e) => updateTimeZone(e.target.value)}>
                                <MenuItem value={"US_EAST"}>US Eastern</MenuItem>
                                <MenuItem value={"US_CENTRAL"}>US Central</MenuItem>
                                <MenuItem value={"US_MOUNTAIN"}>US Mountain</MenuItem>
                                <MenuItem value={"US_ARIZONA"}>US Arizona</MenuItem>
                                <MenuItem value={"US_PACIFIC"}>US Pacific</MenuItem>
                                <MenuItem value={"US_ALASKA"}>US Alaska</MenuItem>
                                <MenuItem value={"US_HAWAII"}>US Hawaii</MenuItem>
                            </Select>
                        </Stack>
                        <Stack direction="column" spacing={0.5}>
                            <Typography>Bio</Typography>
                            <ButtonGroup variant="outlined" aria-label="Basic button group">
                                <Button component="label">
                                    Upload new bio
                                    <VisuallyHiddenInput type="file" id="resumeFileElement" accept=".pdf" onChange={() => uploadResume()} />
                                </Button> 
                                <Button disabled={!coachProfile.hasResume} onClick={() => setResumeOpen(true)}>View current bio</Button>
                            </ButtonGroup>
                        </Stack>
                        <Button variant="contained" onClick={() => updateCoachProfile()} disabled={changesSaved}>Save Changes</Button>
                    </Stack>
                </Sheet>
            </Modal>
        </React.Fragment>
    );
}