import React, { useState, useEffect, useContext } from 'react';
import { SessionContext } from 'Contexts';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider, Checkbox,
    FormGroup, TableContainer, Table, TableRow, TableBody, TableHead, IconButton, ListItemText,
    OutlinedInput, InputAdornment, Input, Collapse, Dialog, DialogContent, DialogContentText, 
    DialogActions, DialogTitle } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';

import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import utc from 'dayjs/plugin/utc';

import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SortIcon from '@mui/icons-material/Sort';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const MenuBarButton = styled(Button)(({ theme }) => ({
    color: "white",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#6c89b8"
    }
}));

dayjs.extend(utc);

export default function ScheduleManager() {
    const sessionState = useContext(SessionContext);
    let [scheduleItems, setScheduleItems] = useState([]);
    let [scheduleManagerModalOpen, setScheduleManagerModalOpen] = useState(false);
    let [scheduleSortsAscending, setScheduleSortsAscending] = useState(true);

    let [createNewScheduleEventDialogOpen, setCreateNewScheduleEventDialogOpen] = useState(false);
    let [createNewScheduleEventSnackbarOpen, setCreateNewScheduleEventSnackbarOpen] = useState(false);
    let [createNewScheduleEventSnackbarMessage, setCreateNewScheduleEventSnackbarMessage] = useState("");
    let [newScheduleEventTitle, setNewScheduleEventTitle] = useState("");
    let [newScheduleEventDescription, setNewScheduleEventDescription] = useState("");
    let [newScheduleEventTime, setNewScheduleEventTime] = useState(dayjs());
    let [newScheduleEventEndTime, setNewScheduleEventEndTime] = useState(dayjs());
    let [newScheduleEventLengthType, setNewScheduleEventLengthType] = useState('MINUTES');
    let [newScheduleEventLength, setNewScheduleEventLength] = useState(30);

    function resetNewScheduleEventFields() {
        setNewScheduleEventTitle("");
        setNewScheduleEventDescription("");
        setNewScheduleEventTime(dayjs());
        setNewScheduleEventLength(60);
    }

    function canSubmitScheduleEvent() {
        return newScheduleEventTitle != "" && newScheduleEventLength > 0;
    }

    function formatMinutes(minutesTotal) {
        let days = Math.floor(minutesTotal / 1440);
        let hours = Math.floor(minutesTotal % 1440 / 60);
        let minutes = minutesTotal % 60;

        let formattedTime = '';
        if(days > 0) {
            if(days == 1)
                formattedTime = formattedTime + "1 Day ";
            else
                formattedTime = formattedTime + days + " Days ";
        }
        if(hours > 0) {
            if(hours == 1)
                formattedTime = formattedTime + "1 Hr ";
            else
                formattedTime = formattedTime + hours + " Hrs ";
        }
        if(minutes > 0) {
            if(minutes == 1)
                formattedTime = formattedTime + "1 Min ";
            else
                formattedTime = formattedTime + minutes + " Min ";
        }
        return formattedTime;
    }

    useEffect(() => {
        loadCurrentSchedule();
        sortSchedule();
    }, []);

    useEffect(() => {
        sortSchedule();
    }, [scheduleSortsAscending]);

    function sortSchedule() {
        scheduleItems.sort(function(a,b) {
            if(scheduleSortsAscending) {
                return a.start.valueOf() - b.start.valueOf();
            } else {
                return b.start.valueOf() - a.start.valueOf();
            }
        });
        let sortedScheduleItems = [...scheduleItems];
        setScheduleItems(sortedScheduleItems);
    }

    function loadCurrentSchedule() {
        fetch("/api/v1/account/schedule-items").then((res) => {
            return res.json();
        }).then((scheduleList) => {
            for(let i = 0; i < scheduleList.scheduleItems.length; i++)
                scheduleList.scheduleItems[i].start = dayjs(scheduleList.scheduleItems[i].start);
            setScheduleItems(scheduleList.scheduleItems);
        });
    }

    function deleteScheduleItem(scheduleItemId) {
        fetch("/api/v1/account/schedule-item/" + scheduleItemId, {
            method: "DELETE"
        }).then((res) => {
            loadCurrentSchedule();
        });
    }

    function createScheduleItem() {
        let lengthMinutes = newScheduleEventLength;
        if(newScheduleEventLengthType == 'HOURS')
            lengthMinutes *= 60;
        else if(newScheduleEventLengthType == 'DAYS')
            lengthMinutes *= 1440;

        let scheduleItem = {
            start: newScheduleEventTime,
            lengthMinutes: lengthMinutes,
            title: newScheduleEventTitle,
            description: newScheduleEventDescription
        };
        fetch("/api/v1/account/schedule-item", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(scheduleItem)
        }).then((res) => {
            if(res.status == 200) {
                setCreateNewScheduleEventSnackbarMessage("Schedule event added.");
                setCreateNewScheduleEventSnackbarOpen(true);
            } else {
                setCreateNewScheduleEventSnackbarMessage("There was an error adding your schedule event.");
                setCreateNewScheduleEventSnackbarOpen(true);
            }
        }).then((res) => {
            loadCurrentSchedule();
            setCreateNewScheduleEventDialogOpen();
        });
    }


    return (
        <React.Fragment>
            <MenuBarButton onClick={() => setScheduleManagerModalOpen(true)} startIcon={<CalendarViewDayIcon />}>Schedule Manager</MenuBarButton>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Modal open={scheduleManagerModalOpen} onClose={() => setScheduleManagerModalOpen(false)} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Sheet variant="outlined" sx={{ minWidth: 400, minHeight: 600, borderRadius: 'md', p: 3, boxShadow: 'lg' }}>
                        <ModalClose variant="outlined" />
                        <Stack direction="column" alignItems="center" justifyContent="center" spacing={1.5} sx={{ mt:"20px" }}>
                            <Box sx={{ width: "100%", maxHeight: "500px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                                <TableContainer sx={{ overflowx: "initial", mt: "10px", mb: "10px" }} component={Paper}>
                                    <Table sx={{ minWidth: "550px", height: "auto" }} stickyHeader size="small">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="center" colSpan={5}><Box sx={{ fontWeight: 'bold' }}>Schedule</Box></StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell>Title</StyledTableCell>
                                                <StyledTableCell>Date<IconButton onClick={() => setScheduleSortsAscending(!scheduleSortsAscending)}><SortIcon /></IconButton></StyledTableCell>
                                                <StyledTableCell>Start Time</StyledTableCell>
                                                <StyledTableCell>Length</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            { scheduleItems.map((scheduleItem, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell>{scheduleItem.userCreated && <IconButton onClick={() => {deleteScheduleItem(scheduleItem.scheduleItemId)}}><DeleteForeverIcon/></IconButton>}</StyledTableCell>
                                                        <StyledTableCell>{scheduleItem.title}</StyledTableCell>
                                                        <StyledTableCell>{dayjs(scheduleItem.start).local().format("D MMM YYYY")}</StyledTableCell>
                                                        <StyledTableCell>{dayjs(scheduleItem.start).local().format("h:mm A")}</StyledTableCell>
                                                        <StyledTableCell>{formatMinutes(scheduleItem.lengthMinutes)}</StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Dialog open={createNewScheduleEventDialogOpen} onClose={() => setCreateNewScheduleEventDialogOpen(false)}>
                                <DialogTitle>Create Event</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        This time will be blocked from your coaching availability
                                    </DialogContentText>
                                    <Stack direction='column' sx={{ mt: '8px', width: '100%'}} alignItems='center' spacing={1.0}>
                                        <MobileDateTimePicker 
                                         label="Event Start"
                                         value={newScheduleEventTime}
                                         onChange={(newValue) => setNewScheduleEventTime(newValue)}
                                        />
                                        <TextField
                                         autoFocus
                                         required
                                         label="Title"
                                         fullWidth
                                         variant="standard"
                                         value={newScheduleEventTitle}
                                         onChange={(event) => setNewScheduleEventTitle(event.target.value)}
                                        />
                                        <TextField
                                         autoFocus
                                         label="Description"
                                         fullWidth
                                         variant="standard"
                                         value={newScheduleEventDescription}
                                         onChange={(event) => setNewScheduleEventDescription(event.target.value)}
                                        />
                                        <TextField
                                         autoFocus
                                         required
                                         type="number"
                                         margin="dense"
                                         label="Event Length"
                                         fullWidth
                                         variant="standard"
                                         value={newScheduleEventLength}
                                         onChange={(event) => setNewScheduleEventLength(event.target.value)}
                                        />
                                        <Select sx={{ width: '100%' }} variant="standard" value={newScheduleEventLengthType} onChange={(e) => setNewScheduleEventLengthType(e.target.value)}>
                                            <MenuItem value='MINUTES'>Minutes</MenuItem>
                                            <MenuItem value='HOURS'>Hours</MenuItem>
                                            <MenuItem value='DAYS'>Days</MenuItem>
                                        </Select>
                                    </Stack>
                                </DialogContent>
                                <DialogActions>
                                    <Button disabled={!canSubmitScheduleEvent()} onClick={() => {createScheduleItem()}}>Submit</Button>
                                </DialogActions>
                            </Dialog>
                            <Snackbar open={createNewScheduleEventSnackbarOpen} autoHideDuration={6000} onClose={() => setCreateNewScheduleEventSnackbarOpen(false)} message={createNewScheduleEventSnackbarMessage} />
                            <Button onClick={() => {resetNewScheduleEventFields(); setCreateNewScheduleEventDialogOpen(true);}}>Create Event</Button>
                        </Stack>
                    </Sheet>
                </Modal>
            </LocalizationProvider>
        </React.Fragment>
    );
}