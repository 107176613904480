import React, { useState, useContext, useEffect } from 'react';
import { SessionContext } from 'Contexts';
import Badge from '@mui/material/Badge';
import { Modal, ModalDialog, ModalClose } from '@mui/joy';

import NotificationsIcon from '@mui/icons-material/Notifications';
import { Box, Typography, Stack, Paper, Button, IconButton, Divider } from '@mui/material';

export default function NotificationBell() {
    const sessionState = useContext(SessionContext);
    const [unviewedNotifications, setUnviewedNotifications] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const [notificationsOpen, setNotificationsOpen] = useState(false);

    useEffect(() => {
        refreshNotifications();
        setInterval(refreshNotifications, 60000);
    }, []);

    function refreshNotifications() {
        fetch("/api/v1/account/notifications").then((res) => {
            if(res.status == 200)
                return res.json();
            else
                return null;
        }).then((notificationsRes) => {
            if(notificationsRes == null)
                return;
            setNotifications(notificationsRes.notifications);
        });
    }

    useEffect(() => {
        let unviewedCount = 0;
        for(let i = 0; i < notifications.length; i++) {
            if(!notifications[i].viewed)
                unviewedCount += 1;
        }
        setUnviewedNotifications(unviewedCount);
    }, [notifications]);

    function markNotificationAsViewed(notificationId) {
        fetch("/api/v1/account/notification-viewed/" + notificationId, {
            method: "POST"
        }).then((res) => {
            let updatedNotifications = [...notifications];
            for(let i = 0; i < updatedNotifications.length; i++) {
                if(updatedNotifications[i].notificationId == notificationId) {
                    updatedNotifications[i].viewed = true;
                    break;
                }
            }
            setNotifications(updatedNotifications);
        });
    }

    function openNotificationPanel() {
        let isOpening = !notificationsOpen;
        setNotificationsOpen(isOpening);
        if(isOpening)
            notifications.map((notification) => {
                if(!notification.viewed) {
                    markNotificationAsViewed(notification.notificationId);
                }
            });
    }

    return (
        <React.Fragment>
            <Modal open={notificationsOpen} onClose={() => setNotificationsOpen(false)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ModalDialog>
                    <ModalClose />
                    <Typography variant="h4">Notifications</Typography>
                    <Box sx={{ maxWidth: "400", maxHeight: "450px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                        <Stack direction="column" spacing={ 3 } divider={<Divider orientation="horizontal" flexItem />}>
                            {notifications.map((notification) => {
                                return (
                                    <Stack direction="column">
                                        <Typography variant="h6">{notification.title}</Typography>
                                        <Typography variant="subtitle2">{notification.message}</Typography>
                                        {notification.link != null && <Button onClick={() => window.location.href=(notification.link)}>Open</Button>}
                                    </Stack>
                                );
                            })}
                            { notifications.length == 0 && <Typography variant="subtitle1">You currently have no notifications.</Typography>}
                        </Stack>
                    </Box>
                </ModalDialog>
            </Modal>
            <IconButton onClick={() => openNotificationPanel()}>
                <Badge badgeContent={unviewedNotifications} invisible={unviewedNotifications == 0} color="primary">
                    <NotificationsIcon color="primary" />
                </Badge>
            </IconButton>
        </React.Fragment>
    );
}