import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { SessionContext } from 'Contexts';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia, Checkbox,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Input, InputAdornment, Pagination,
    Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
    Toolbar, IconButton, Switch, Divider, Slider, ButtonGroup } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import DashboardElement from 'pageElements/DashboardElement';
import DashboardLayout from 'pageElements/DashboardLayout';
import DashboardBody from 'pageElements/DashboardBody';
import DashboardMenuBar from 'pageElements/DashboardMenuBar';


function TextEntryQuestion({ question, updateQuestion, deleteQuestion, questionIndex }) {
    const [isSaved, setIsSaved] = useState(true);
    const [prompt, setPrompt] = useState(question.question.prompt);
    const [required, setRequired] = useState(question.question.required);

    function updatePrompt(newPrompt) {
        setPrompt(newPrompt);
        setIsSaved(false);
    }

    function updateRequired(isRequired) {
        setRequired(isRequired);
        setIsSaved(false);
    }

    function save() {
        let q = {
            question: {
                questionType: 'TEXT',
                required: required,
                prompt: prompt,
                minChoiceCount: 0,
                maxChoiceCount: 0
            },
            answers: []
        };
        updateQuestion(q, questionIndex);
        setIsSaved(true);
    }

    return (
        <Stack>
            <Stack direction='row' spacing={3.0}>
                <Button startIcon={<DeleteIcon />} onClick={() => deleteQuestion(questionIndex)}>Delete</Button>
                <Typography variant='h6'>Text Question</Typography>
            </Stack>
            <TextField fullwidth label='Prompt' value={prompt} onChange={(e) => updatePrompt(e.target.value)} />
            <Switch label='Required' checked={required} onChange={(e) => updateRequired(e.target.checked)} />
            { !isSaved && <Button onClick={() => save()}>Confirm</Button> }
        </Stack>
    );
}

function SingleChoiceQuestion({ question, updateQuestion, deleteQuestion, questionIndex }) {
    const [isSaved, setIsSaved] = useState(true);
    const [prompt, setPrompt] = useState(question.question.prompt);
    const [answers, setAnswers] = useState(question.answers);
    const [newAnswerText, setNewAnswerText] = useState('');
    const [required, setRequired] = useState(question.question.required);

    function updatePrompt(newPrompt) {
        setPrompt(newPrompt);
        setIsSaved(false);
    }

    function updateRequired(isRequired) {
        setRequired(isRequired);
        setIsSaved(false);
    }

    function addAnswer() {
        const newAnswer = {
            answerText: newAnswerText
        };
        setNewAnswerText('');
        answers.push(newAnswer);
        setAnswers([...answers]);
        setIsSaved(false);
    }

    function deleteAnswer(answerIndex) {
        answers.splice(answerIndex, 1);
        setAnswers([...answers]);
        setIsSaved(false);
    }

    function save() {
        const q = {
            question: {
                questionType: 'SINGLE_CHOICE',
                required: required,
                prompt: prompt,
                minChoiceCount: 0,
                maxChoiceCount: 0
            },
            answers: answers
        };
        updateQuestion(q, questionIndex);
        setIsSaved(true);
    }

    return (
        <Stack>
           <Stack direction='row' spacing={3.0}>
                <Button startIcon={<DeleteIcon />} onClick={() => deleteQuestion(questionIndex)}>Delete</Button>
                <Typography variant='h6'>Single-Choice Question</Typography>
            </Stack>
            <TextField fullwidth label='Prompt' value={prompt} onChange={(e) => updatePrompt(e.target.value)} />
            <Switch label='Required' checked={required} onChange={(e) => updateRequired(e.target.checked)} />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Answer Text</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { answers.map((answer, answerIndex) => {
                            return (
                                <TableRow>
                                    <TableCell>
                                        <IconButton onClick={() => deleteAnswer(answerIndex)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        { answer.answerText }
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow>
                            <TableCell>
                                <IconButton disabled={newAnswerText == ''} onClick={() => addAnswer()}>
                                    <AddIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                <TextField value={newAnswerText} onChange={(e) => setNewAnswerText(e.target.value)}></TextField>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            { !isSaved && <Button onClick={() => save()}>Confirm</Button> }
        </Stack>
    );
}

function MultipleChoiceQuestion({ question, updateQuestion, deleteQuestion, questionIndex }) {
    const [isSaved, setIsSaved] = useState(true);
    const [prompt, setPrompt] = useState(question.question.prompt);
    const [answers, setAnswers] = useState(question.answers);
    const [newAnswerText, setNewAnswerText] = useState('');
    const [required, setRequired] = useState(question.question.required);
    const [answerCountRange, setAnswerCountRange] = useState([question.question.minChoiceCount, question.question.maxChoiceCount]);

    function updatePrompt(newPrompt) {
        setPrompt(newPrompt);
        setIsSaved(false);
    }

    function updateRequired(isRequired) {
        setRequired(isRequired);
        setIsSaved(false);
    }

    function addAnswer() {
        const newAnswer = {
            answerText: newAnswerText
        };
        setNewAnswerText('');
        answers.push(newAnswer);
        setAnswers([...answers]);
        setIsSaved(false);
    }

    function deleteAnswer(answerIndex) {
        answers.splice(answerIndex, 1);
        setAnswers([...answers]);
        setIsSaved(false);
    }

    function save() {
        const q = {
            question: {
                questionType: 'MULTIPLE_CHOICE',
                required: required,
                prompt: prompt,
                minChoiceCount: answerCountRange[0],
                maxChoiceCount: answerCountRange[1]
            },
            answers: answers
        };
        console.log('Saving multichoice: ', q);
        updateQuestion(q, questionIndex);
        setIsSaved(true);
    }

    return (
        <Stack>
            <Stack direction='row' spacing={3.0}>
                <Button startIcon={<DeleteIcon />} onClick={() => deleteQuestion(questionIndex)}>Delete</Button>
                <Typography variant='h6'>Multi-Choice Question</Typography>
            </Stack>
            <TextField fullwidth label='Prompt' value={prompt} onChange={(e) => updatePrompt(e.target.value)} />
            <Switch label='Required' checked={required} onChange={(e) => updateRequired(e.target.checked)} />
            <Stack direction='column' spacing={.5}>
                <Typography>Min. & Max. Answer Count</Typography>
                <Slider marks step={1} min={0} max={answers.length} value={answerCountRange} onChange={(e, v) => setAnswerCountRange(v)} valueLabelDisplay='on' />
            </Stack>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Answer Text</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { answers.map((answer, answerIndex) => {
                            return (
                                <TableRow>
                                    <TableCell>
                                        <IconButton onClick={() => deleteAnswer(answerIndex)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        { answer.answerText }
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow>
                            <TableCell>
                                <IconButton disabled={newAnswerText == ''} onClick={() => addAnswer()}>
                                    <AddIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                <TextField value={newAnswerText} onChange={(e) => setNewAnswerText(e.target.value)}></TextField>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            { !isSaved && <Button onClick={() => save()}>Confirm</Button> }
        </Stack>
    );
}

function BooleanQuestion({ question, updateQuestion, deleteQuestion, questionIndex }) {
    const [isSaved, setIsSaved] = useState(true);
    const [prompt, setPrompt] = useState(question.question.prompt);
    const [required, setRequired] = useState(question.question.required);

    function updatePrompt(newPrompt) {
        setPrompt(newPrompt);
        setIsSaved(false);
    }

    function updateRequired(isRequired) {
        setRequired(isRequired);
        setIsSaved(false);
    }

    function save() {
        const q = {
            question: {
                questionType: 'BOOLEAN',
                required: required,
                prompt: prompt,
                minChoiceCount: 0,
                maxChoiceCount: 0
            },
            answers: []
        };
        updateQuestion(q, questionIndex);
        setIsSaved(true);
    }

    return (
        <Stack>
            <Stack direction='row' spacing={3.0}>
                <Button startIcon={<DeleteIcon />} onClick={() => deleteQuestion(questionIndex)}>Delete</Button>
                <Typography variant='h6'>Boolean Question</Typography>
            </Stack>
            <TextField fullwidth label='Prompt' value={prompt} onChange={(e) => updatePrompt(e.target.value)} />
            <Switch label='Required' checked={required} onChange={(e) => updateRequired(e.target.checked)} />
            { !isSaved && <Button onClick={() => save()}>Confirm</Button> }
        </Stack>
    );
}


export default function RegistrationSurveyEditor() {
    let { clientId } = useParams();
    const sessionState = useContext(SessionContext);
    const [surveyId, setSurveyId] = useState(null);
    const [surveyName, setSurveyName] = useState('');
    const [surveyQuestions, setSurveyQuestions] = useState([]);
    const [saved, setSaved] = useState(true);
    const [responseSnackbarMessage, setResponseSnackbarMessage] = useState('');
    const [responseSnackbarOpen, setResponseSnackbarOpen] = useState(false);

    useEffect(() => {
        console.log('useEffect');
        fetch("/api/v1/account/registration-survey/" + clientId).then((res) => {
            return res.json();
        }).then((userSurvey) => {
            for(let qIndex = 0; qIndex < userSurvey.questions.length; qIndex++) {
                userSurvey.questions[qIndex].saved = true;
            }

            setSurveyId(userSurvey.surveyId);
            setSurveyName(userSurvey.surveyName);
            setSurveyQuestions(userSurvey.questions);
        });
    }, []);

    function createQuestion(question) {
        surveyQuestions.push(question);
        setSurveyQuestions([...surveyQuestions]);
        setSaved(false);
    }

    function updateQuestion(question, index) {
        console.log('updateQuestion: index=', index);
        console.log('updateQuestion: ', question);
        surveyQuestions[index] = question;
        setSurveyQuestions([...surveyQuestions]);
        setSaved(false);
        console.log('updateQuestion: ', surveyQuestions);
    }

    function deleteQuestion(index) {
        surveyQuestions.splice(index, 1);
        setSurveyQuestions([...surveyQuestions]);
    }

    function saveSurvey() {
        console.log('saveSurvey: ', surveyQuestions);
        let rq = {
            surveyId: surveyId,
            surveyName: surveyName,
            questions: surveyQuestions
        };

        fetch("/api/v1/account/registration-survey", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rq)
        }).then((res) => {
            if(res.status == 200) {
                setResponseSnackbarMessage('Survey update successfully');
                setSaved(true);
            } else {
                setResponseSnackbarMessage('Error while updating survey with code: ' + res.status);
            }
            setResponseSnackbarOpen(true);
        });

    }

    function createBooleanQuestion() {
        const q = {
            question: {
                questionType: 'BOOLEAN',
                required: false,
                prompt: '',
                minChoiceCount: 1,
                maxChoiceCount: 1
            },
            answers: []
        };
        createQuestion(q);
    }

    function createSingleChoiceQuestion() {
        const q = {
            question: {
                questionType: 'SINGLE_CHOICE',
                required: false,
                prompt: '',
                minChoiceCount: 1,
                maxChoiceCount: 1
            },
            answers: []
        };
        createQuestion(q);
    }

    function createMultiChoiceQuestion() {
        const q = {
            question: {
                questionType: 'MULTIPLE_CHOICE',
                required: false,
                prompt: '',
                minChoiceCount: 0,
                maxChoiceCount: 1
            },
            answers: []
        };
        createQuestion(q);
    }

    function createTextEntryQuestion() {
        const q = {
            question: {
                questionType: 'TEXT',
                required: false,
                prompt: '',
                minChoiceCount: 0,
                maxChoiceCount: 0
            },
            answers: []
        };
        createQuestion(q);
    }

    return (
        <DashboardLayout>
            <DashboardMenuBar>
            </DashboardMenuBar>
            <DashboardBody>
                <Stack direction='column' sx={{ width: '90%' }} spacing={ 5.0 }>
                    <Typography>{surveyName}</Typography>
                    <Stack direction='column' spacing={ 2.0 } divider={<Divider orientation="horizontal" flexItem />} sx={{ width: '100%' }}>
                        { surveyQuestions.map((question, index) => {
                            if(question.question.questionType == 'MULTIPLE_CHOICE') {
                                return <MultipleChoiceQuestion question={question} deleteQuestion={(i) => deleteQuestion(i)} updateQuestion={(updatedQuestion, i) => updateQuestion(updatedQuestion, i)} questionIndex={index} />
                            } else if(question.question.questionType == 'SINGLE_CHOICE') {
                                return <SingleChoiceQuestion question={question} deleteQuestion={(i) => deleteQuestion(i)} updateQuestion={(updatedQuestion, i) => updateQuestion(updatedQuestion, i)} questionIndex={index} />
                            } else if(question.question.questionType == 'BOOLEAN') {
                                return <BooleanQuestion question={question} deleteQuestion={(i) => deleteQuestion(i)} updateQuestion={(updatedQuestion, i) => updateQuestion(updatedQuestion, i)} questionIndex={index} />
                            } else if(question.question.questionType == 'TEXT') {
                                return <TextEntryQuestion question={question} deleteQuestion={(i) => deleteQuestion(i)} updateQuestion={(updatedQuestion, i) => updateQuestion(updatedQuestion, i)} questionIndex={index} />
                            }
                        })}
                    </Stack>
                    <Stack direction='column' spacing={.5}>
                        <Typography>New Question</Typography>
                        <ButtonGroup variant='outlined'>
                            <Button onClick={() => createTextEntryQuestion()}>Text entry</Button>
                            <Button onClick={() => createSingleChoiceQuestion()}>Single-choice</Button>
                            <Button onClick={() => createMultiChoiceQuestion()}>Multi-choice</Button>
                            <Button onClick={() => createBooleanQuestion()}>Boolean</Button>
                        </ButtonGroup>
                    </Stack>
                    <Snackbar
                      open={responseSnackbarOpen}
                      autoHideDuration={6000}
                      onClose={() => setResponseSnackbarOpen(false)}
                      message={responseSnackbarMessage} />
                    <Button variant='contained' onClick={() => saveSurvey()}>Submit</Button>
                </Stack>
            </DashboardBody>
        </DashboardLayout>
    )
}