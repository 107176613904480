import { useState, useEffect } from 'react';
import { Stack, Typography} from '@mui/material';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';


export default function ResumeViewer({ open, onClose, accountId, title, lazyLoad=false, reload=0 }) {
    const [resume, setResume] = useState(null);
    const [resumeURI, setResumeURI] = useState("");

    function loadResume() {
        fetch("/api/v1/account/resume/" + accountId).then((res) => {
            return res.arrayBuffer();
        }).then((file) => {
            let resumeBlob = new Blob([file], {type: 'application/pdf'});
            setResume(resumeBlob);
            setResumeURI(URL.createObjectURL(resumeBlob) + "#toolbar=0");
        });
    }

    useEffect(() => {
        loadResume();
    }, [reload]);

    useEffect(() => {
        if(lazyLoad == false) {
            loadResume();
        }
    }, []);

    useEffect(() => {
        if(lazyLoad == true && resume == null) {
            loadResume();
        }
    }, [open]);

    return (
        <Modal open={open} onClose={() => onClose()} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Sheet variant="outlined" sx={{ minWidth: 900, minHeight: 600, borderRadius: 'md', p: 3, boxShadow: 'lg' }}>
            <ModalClose variant="outlined" />
            <Stack direction="column" sx={{ width: "100%", minHeight: "580px" }}>
              <Typography>{title}</Typography>
              <iframe title="resume" src={resumeURI} style={{ width: "100%", minHeight: "580px" }}/>
            </Stack>
          </Sheet>
        </Modal>
    );
}