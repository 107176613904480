import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
  Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia, Checkbox,
  Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Input, InputAdornment, Pagination,
  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  Toolbar, IconButton, Switch } from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import DashboardElement from 'pageElements/DashboardElement';
import DashboardLayout from 'pageElements/DashboardLayout';
import DashboardBody from 'pageElements/DashboardBody';
import DashboardMenuBar from 'pageElements/DashboardMenuBar';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import ParticipantList from './DashboardElements/ParticipantList'
import ClientCoachList from './DashboardElements/CoachList';
import ContractHoursDisplay from './DashboardElements/ContractHoursDisplay';
import CoachingSessionTimeTracking from './DashboardElements/CoachingSessionTimeTracking';
import CoachMatchApprovalRequestList from './DashboardElements/CoachMatchApprovalRequestList';

import AnalyticsIcon from '@mui/icons-material/Analytics';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DescriptionIcon from '@mui/icons-material/Description';
import QuizIcon from '@mui/icons-material/Quiz';


function CreateContract({ clientId }) {
  return (
    <DashboardElement elevation={1}>
      <Button onClick={() => { window.location.href = ("/contract-setup/" + clientId.toString())}}>Setup Coaching Program</Button>
    </DashboardElement>
  );
}

function ContractDisplay({ contract, clientId }) {
  return (
    <React.Fragment>
      <ContractHoursDisplay clientId={clientId} />
      { contract.contractInfo != null && <DashboardElement elevation={0}>
        <Box sx={{ width: "100%" }}>
          <Typography align="left" variant="h6" sx={{ color: "black", fontWeight: 650 }}>Contract Details</Typography>
          <Typography>{contract.contractInfo}</Typography>
        </Box>
      </DashboardElement> }
    </React.Fragment>
  )
}

function ContractPanel({ clientId }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasViewPermission, setHasViewPermission] = useState(true);
  const [clientHasContract, setClientHasContract] = useState(true);
  const [contract, setContract] = useState(null);

  useEffect(() => {
    fetch("/api/v1/account/contract/" + clientId).then((res) => {
      if(res.status == 200){
        setClientHasContract(true);
        return res.json();
      } else if(res.status == 404) {
        setClientHasContract(false);
      } else if(res.status == 401 || res.status == 403) {
        setHasViewPermission(false);
      }
      return null;
    }).then((res) => {
      if(res != null) {
        setContract(res);
      }
      setIsLoaded(true);
    });
  }, []);

  if(!isLoaded) {
    return(<CircularProgress />);
  } else if(!hasViewPermission) {
    return(null);
  } else if(!clientHasContract) {
    return(<CreateContract clientId={clientId}/>);
  } else {
    return(<ContractDisplay contract={contract} clientId={clientId} />);
  }
  
}

function UpdateMetadataButton({ clientId }) {
  const [client, setClient] = useState(null);
  const [contract, setContract] = useState(null);

  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [updateMetadataOpen, setUpdateMetadataOpen] = useState(false);
  const [programName, setProgramName] = useState(false);
  const [contractMetadata, setContractMetadata] = useState(false);

  useEffect(() => {
    fetch("/api/v1/account/client/" + clientId, {
      method: "GET",
      cache: "no-cache",
      mode: "cors"
    }).then((res) => {
      return res.json()
    }).then((res) => {
      setClient(res);
      setProgramName(res.programName);
    }).then(() => {
      return fetch("/api/v1/account/contract/" + clientId);
    }).then((res) => {
      return res.json();
    }).then((res) => {
      setContract(res);
      setContractMetadata(res.contractInfo);
    });
  }, []);

  function inputValid() {
    return (programName != null && programName != "" && programName != client.programName) ||
      (contractMetadata != null && contractMetadata != "" && contractMetadata != contract.contractInfo);
  }

  function submitRequest() {
    setUpdateInProgress(true);
    let rq = {
      clientId: clientId,
      contractInfo: contractMetadata,
      programName: programName
    };
    fetch("/api/v1/account/client-metadata", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(rq)
    }).then((res) => {
      window.location.reload();
    });
  }

  if(contract != null && client != null) {
    return (
      <React.Fragment>
        <Modal open={updateMetadataOpen} onClose={() => { setUpdateMetadataOpen(false) }} >
          <ModalDialog>
            <DialogTitle>Update Client & Program Metadata</DialogTitle>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Program Name</FormLabel>
                <Input required value={programName} onChange={(event) => {setProgramName(event.target.value)}}/>
              </FormControl>
              <FormControl>
                <FormLabel>Contract Metadata</FormLabel>
                <Input required value={contractMetadata} onChange={(event) => {setContractMetadata(event.target.value)}}/>
              </FormControl>
              <Button disabled={!inputValid()} loading={updateInProgress} loadingPosition="start" onClick={() => submitRequest()}>Update</Button>
            </Stack>
          </ModalDialog>
        </Modal>
        <MenuBarButton startIcon={<DescriptionIcon />} onClick={() => setUpdateMetadataOpen(true)}>Update Metadata</MenuBarButton>
      </React.Fragment>
    );
  }
}

function CreateAdminButton({ clientId }) {
  const [createAdminDialogOpen, setCreateAdminDialogOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [adminType, setAdminType] = useState('CLIENT');
  const [creatingAdminInProgress, setCreatingAdminInProgress] = useState(false);

  const handleCreateAdmin = (event) => {
    setCreatingAdminInProgress(true);
    fetch("/api/v1/account/create-admin-account", {
        method: "POST",
        cache: "no-cache",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({email: email, firstName: firstName, lastName: lastName, adminType: adminType, clientId: clientId})
    }).then((res) => {
        if(res.status != 200) {
          console.log("Failed to submit admin account creation request");
          return false;
        } else {
          console.log("Admin account creation successful")
          return true;
        }
    }).then((successful) => {
      if(successful)
        setCreateAdminDialogOpen(false);
      setCreatingAdminInProgress(false);
    });
  }

  function inputIsValid() {
    return email != '' &&
      firstName != '' &&
      lastName != '';
  }

  return (
    <React.Fragment>
      <Modal open={createAdminDialogOpen} onClose={() => { setCreateAdminDialogOpen(false) }} >
        <ModalDialog>
          <DialogTitle>Create New Administrator</DialogTitle>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Email Address</FormLabel>
              <Input autofocus required onChange={(event) => {setEmail(event.target.value)}}/>
            </FormControl>
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Input required onChange={(event) => {setFirstName(event.target.value)}}/>
            </FormControl>
            <FormControl>
              <FormLabel>Last Name</FormLabel>
              <Input required onChange={(event) => {setLastName(event.target.value)}}/>
            </FormControl>
            <Button disabled={!inputIsValid()} loading={creatingAdminInProgress} loadingPosition="start" onClick={ handleCreateAdmin }>Create Administrator</Button>
          </Stack>
        </ModalDialog>
      </Modal>
      <MenuBarButton startIcon={<PersonAddIcon />} onClick={ () => { setCreateAdminDialogOpen(true) } }>Create Admin</MenuBarButton>
    </React.Fragment>
  );
}

function ClientDisplay({ clientId }) {
  const [clientDataIsLoaded, setClientDataIsLoaded] = useState(false);
  const [client, setClient] = useState(null);

  useEffect(() => {
    if(!clientDataIsLoaded) {
      fetch("/api/v1/account/client/" + clientId, {
          method: "GET",
          cache: "no-cache",
          mode: "cors"
      }).then((res) => {
          if(res.status == 200)
              return res.json()
      }).then((res) => {
          setClient(res);
          setClientDataIsLoaded(true);
      });
    }
  }, [clientDataIsLoaded, setClientDataIsLoaded,
    client, setClient, clientId]);

  if(!clientDataIsLoaded) {
    return (
      <CircularProgress />
    );
  } else {
    return (
      <Typography variant="h2" component="h1" sx={{ fontWeight: 500 }}>{ client.clientName }</Typography>
    );
  }
}

const MenuBarButton = styled(Button)(({ theme }) => ({
  color: "white",
  borderRadius: "0px",
  width: "100%",
  fontWeight: 600,
  textTransform: "none",
  '&:hover': {
    backgroundColor: "#6c89b8"
  }
}));

export default function Client() {
  let { clientId } = useParams();

  return (
    <DashboardLayout>
      <DashboardMenuBar>
        <CreateAdminButton clientId={ clientId } />
        <MenuBarButton startIcon={<AnalyticsIcon />} onClick={() => {window.location.href = "/client/" + clientId + "/analytics"}}>Analytics</MenuBarButton>
        <MenuBarButton startIcon={<QuizIcon />} onClick={() => {window.location.href = "/client/" + clientId + "/registration-survey"}}>Registration Survey</MenuBarButton>
        <UpdateMetadataButton clientId={ clientId } />
      </DashboardMenuBar>
      <DashboardBody>
        <ClientDisplay clientId={ clientId } />
        <ContractPanel clientId={ clientId } />
        <ParticipantList clientId={ clientId } />
        <ClientCoachList clientId={ clientId } />
        <CoachMatchApprovalRequestList clientId={ clientId } />
        <CoachingSessionTimeTracking clientId={ clientId } />
      </DashboardBody>
    </DashboardLayout>
  );
}

