import { useState, useEffect, useContext } from 'react';
import { SessionContext } from 'Contexts';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
  Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
  Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Skeleton, Tooltip } from '@mui/material';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import DashboardLayout from 'pageElements/DashboardLayout';
import DashboardBody from 'pageElements/DashboardBody';
import DashboardMenuBar from 'pageElements/DashboardMenuBar';
import CoachingSessionCalendar from './DashboardElements/CoachingSessionCalendar';
import Availability from './DashboardElements/Availability';
import ParticipantList from './DashboardElements/ParticipantList';
import CoachProfileBanner from './DashboardElements/CoachProfileBanner';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import CoachAccount from './DashboardElements/CoachAccount';
import ScheduleManager from './DashboardElements/ScheduleManager';
import UserDocumentationButton from 'shared/UserDocumentationButton';


const MenuBarButton = styled(Button)(({ theme }) => ({
    color: "white",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#6c89b8"
    }
}));

const DisabledButton = styled(Button)(({ theme }) => ({
  '&:disabled': {
    color: "#6b6b6b",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: "none",
  }
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function CoachDashboard() {
  const sessionState = useContext(SessionContext);

  return (
    <DashboardLayout>
      <DashboardMenuBar>
        <Availability />
        <CoachAccount />
        <ScheduleManager />
        <MenuBarButton startIcon={<HelpCenterIcon />} onClick={() => window.location.href = "/service-requests"}>Support</MenuBarButton>
        <UserDocumentationButton />
      </DashboardMenuBar>
      <DashboardBody>
        <CoachProfileBanner />
        <CoachingSessionCalendar />
        <ParticipantList />
      </DashboardBody>
    </DashboardLayout>
  );
}