import { useState, useEffect, useContext } from 'react';
import { SessionContext } from 'Contexts';
import { CircularProgress } from '@mui/material';
import OrganizationalDashboardButtons from './DashboardElements/OrganizationDashboardButtons';
import DashboardLayout from 'pageElements/DashboardLayout';
import DashboardBody from 'pageElements/DashboardBody';
import DashboardMenuBar from 'pageElements/DashboardMenuBar';
import ClientList from './DashboardElements/ClientList';
import { OrgCoachApprovalList, OrgCoachList } from './DashboardElements/CoachList';
import UserDocumentationButton from 'shared/UserDocumentationButton';


export default function AdminDashboard() {
    const sessionState = useContext(SessionContext);

    return (
        <DashboardLayout>
            <DashboardMenuBar>
                { sessionState.adminType == "ORG" && <OrganizationalDashboardButtons /> }
                <UserDocumentationButton />
            </DashboardMenuBar>
            <DashboardBody>
                <ClientList />
                <OrgCoachList />
                <OrgCoachApprovalList />
            </DashboardBody>
        </DashboardLayout>
    );
        
}