import { Children } from 'react';
import { Box, Stack, Button, styled } from '@mui/material';

import LogoutIcon from '@mui/icons-material/Logout';

const MenuBarButton = styled(Button)(({ theme }) => ({
    color: "white",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: "#6c89b8"
    }
}));

export default function DashboardMenuBar({ children }) {

    function logout() {
        fetch("/api/v1/account/logout", {
            method: "POST"
        }).then(res => {
            window.location.replace('/');
        });
    }

    return (
        <Box sx={{ width: "15%", height: "100%", ml: "0%", bgcolor: "#0A4B90", borderRight: 1, borderColor: "#7b92ab" }}>
            <Stack 
              direction="column" 
              justifyContent="space-between" 
              alignItems="flex-start" 
              spacing={ 0 } 
              sx={{ mt: "0%", ml: "0px", height: "100%" }}>
                <Stack direction="column" alignItems="center" spacing={ 1.0 } sx={{ width: "100%", mt: "1.5%", ml: "0px" }}>
                    { children }
                </Stack>
                <Stack direction="column" alignItems="center" spacing={ 1.0 } sx={{ width: "100%", mb: "1.5%", ml: "0px" }}>
                    <MenuBarButton startIcon={<LogoutIcon />} onClick={logout}>Logout</MenuBarButton>
                </Stack>
            </Stack>
        </Box>
    );
}