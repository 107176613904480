import { useContext } from 'react';
import { Routes, Route } from "react-router-dom";
import { SessionContext } from 'Contexts';
import ParticipantDashboard from "./ParticipantDashboard";
import ParticipantAccount from "./ParticipantAccount";
import CoachingSession from './pages/CoachingSession';
import ServicePage from 'shared/ServicePage';
import ServiceRequest from 'shared/ServiceRequest';


export default function ActiveParticipantRouter() {
    const sessionState = useContext(SessionContext);
    console.log("ActiveParticipantRouter");
    return (
        <Routes>
            <Route path="/" element={null}>
                <Route index element={<ParticipantDashboard />} />
                <Route path="account" element={<ParticipantAccount />} />
                <Route path="coachingSession/:meetingId" element={<CoachingSession />} />
                <Route path="service-requests" element={<ServicePage />} />
                <Route path="service-requests/:serviceRequestId" element={<ServiceRequest />} />
            </Route>
        </Routes>
    );
}