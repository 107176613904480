import { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
  Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
  Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Input, InputAdornment } from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import Table from '@mui/joy/Table';


export default function ResetPassword() {
    let [searchParams, setSearchParams] = useSearchParams();
    let [passwordResetKey, setPasswordResetKey] = useState(null);
    let [emailEntry, setEmailEntry] = useState('');
    let [passwordEntry, setPasswordEntry] = useState('');
    let [passwordConfirmEntry, setPasswordConfirmEntry] = useState('');
    let [resetPasswordInProgress, setResetPasswordInProgress] = useState(false);

    let handleResetPassword = () => {
        if(passwordEntry != passwordConfirmEntry) {
            console.log("Password do not match");
            return;
        }
        setResetPasswordInProgress(true);
        let passwordResetRequest = {
            accountEmail: emailEntry,
            newPassword: passwordEntry,
            resetKey: passwordResetKey
        };
        fetch("/api/v1/account/reset-password", {
            method: "POST",
            cache: "no-cache",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
              },
            body: JSON.stringify(passwordResetRequest)
        }).then((res) => {
            console.log(res);
            if(res.status == 200){
                window.location.href = "/";
            }
            else if(res.status == 400)
                document.getElementById("loginErrorMessageBox").innerText = "Account not found."
            else if(res.status == 404)
                document.getElementById("loginErrorMessageBox").innerText = "Password reset request not found."
            else if(res.status == 500)
                document.getElementById("loginErrorMessageBox").innerText = "An error occurred."
            setResetPasswordInProgress(false);
        });
    }

    useEffect(() => {
        setPasswordResetKey(searchParams.get("key"));
    }, [searchParams, setSearchParams,
        passwordResetKey, setPasswordResetKey]);

    if(passwordResetKey != null) {
        return (
            <div className="PageBody">
                <Box sx={{ width: "50%", alignItems: "center", justifyContent: "center", mt:"10px" }}>
                    <Paper elevation={1}>
                        <Typography variant="h5" component="h5">Reset Password</Typography>
                        <div id="ErrorMessageBox"></div>
                        <Stack spacing={0.5} direction="column" justifyContent="center" alignItems="center">
                            <Input placeholder="Email Address" onChange={(event) => { setEmailEntry(event.target.value) }} />
                            <Input type="password" placeholder="Password" onChange={(event) => { setPasswordEntry(event.target.value) }} />
                            <Input type="password" placeholder="Confirm Password" onChange={(event) => { setPasswordConfirmEntry(event.target.value) }} />
                            <Button loading={resetPasswordInProgress} loadingPosition="start" size="lg" color="primary" onClick={ handleResetPassword }>Submit</Button>
                        </Stack>
                    </Paper>
                </Box>
            </div>
        );
    }
    else {
        return (
            <div className="PageBody">
                <CircularProgress />
            </div>
        );
    }
}