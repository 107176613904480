import { useContext } from 'react';
import { Routes, Route } from "react-router-dom";
import { SessionContext } from 'Contexts';
import ParticipantDashboard from "./ParticipantDashboard";
import ParticipantAccount from "./ParticipantAccount";
import CoachMatching from "./pages/CoachMatching";
import CoachSelection from "./pages/CoachSelection";
import ActiveParticipantRouter from './ActiveParticipantRouter';
import AwaitingCoachMatchReview from './pages/AwaitingCoachMatchReview';


export default function ParticipantRouter() {
  const sessionState = useContext(SessionContext);

  function activeParticipantRouter() {
    return (
      <Routes>
        <Route path="/" element={null}>
          <Route index element={<ParticipantDashboard />} />
          <Route path="account" element={<ParticipantAccount />} />
        </Route>
      </Routes>
    );
  }

  function inactiveParticipant() {
    return (
      <p>Inactive</p>
    );
  }

  function completedParticipant() {
    return (
      <p>Completed</p>
    );
  }

  console.log("Participant state: ", sessionState.participant.state, "; ", typeof sessionState.participant.state);
  switch(sessionState.participant.state) {
    case "REGISTRATION": // Error here, should not be reachable
      break;
    case "COACH_MATCHING":
      return <CoachMatching />
    case "WAITING_FOR_COACH_MATCHING_REVIEW":
      return <AwaitingCoachMatchReview />
    case "NEEDS_REASSIGNMENT":
    case "COACH_SELECTION":
      return (<CoachSelection />);
    case "COACH_ASSIGNMENT":
      return (<p>Coach assignment</p>);
    case "COACHING":
      console.log("User state COACHING, returning active participant pages");
      return <ActiveParticipantRouter />
    case "COMPLETED":
      return <completedParticipant />;
    case "INACTIVE":
    case "DROPPED":
    case "DELETED":
      return <inactiveParticipant />;
    default:
      console.log("Default match");
      return null;
  }  
}