import React, { useState, useEffect, useContext } from 'react';
import { SessionContext } from 'Contexts';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import utc from 'dayjs/plugin/utc';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
  Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia, Checkbox,
  Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Input, InputAdornment, Pagination,
  Table, TableBody, TableContainer, TableHead, TablePagination, TableRow,
  Toolbar, IconButton, Switch, Skeleton } from '@mui/material';

import DashboardLayout from 'pageElements/DashboardLayout';
import DashboardBody from 'pageElements/DashboardBody';
import DashboardMenuBar from 'pageElements/DashboardMenuBar';
import DashboardElement from 'pageElements/DashboardElement';

import ScheduleCoachingSessionButton from './DashboardElements/ScheduleCoachingSessionButton';
import ParticipantProfileBanner from './DashboardElements/ParticipantProfileBanner';
import CoachingSessionCalendar from './DashboardElements/CoachingSessionCalendar';
import CoachNotes from './DashboardElements/CoachNotes';
import CoachProfile from './DashboardElements/CoachProfile';
import Chat from 'shared/Chat';

import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import UserDocumentationButton from 'shared/UserDocumentationButton';

dayjs.extend(utc);

const MenuBarButton = styled(Button)(({ theme }) => ({
    color: "white",
    borderRadius: "0px",
    width: "100%",
    fontWeight: 600,
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#6c89b8"
    }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function ParticipantDashboard() {
    const sessionState = useContext(SessionContext);

    return (
        <DashboardLayout>
            <DashboardMenuBar>
                <ScheduleCoachingSessionButton />
                <MenuBarButton startIcon={<HelpCenterIcon />} onClick={() => window.location.href = "/service-requests"}>Support</MenuBarButton>
                <UserDocumentationButton />
            </DashboardMenuBar>
            <DashboardBody>
                <ParticipantProfileBanner />
                <CoachingSessionCalendar />
                <CoachProfile coachId={sessionState.participant.coachId} />
                <CoachNotes />
                <DashboardElement elevation={1}>
                  <Box sx={{ width: "100%", maxHeight: "1000px", display: "flex", flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
                    <Chat otherUserId={sessionState.participant.coachId} widgetHeight='600px' />
                  </Box>
                </DashboardElement>
            </DashboardBody>
        </DashboardLayout>
    );
}