import React, { useEffect, useState, useContext } from 'react';
import { TextField, FormLabel, FormControl, FormControlLabel, InputLabel, Select, MenuItem, 
    Chip, Stack, Paper, Button, RadioGroup, Radio, styled, Avatar, Typography, CardMedia,
    Card, CardContent, Box, Backdrop, CircularProgress, Snackbar, Alert, Divider, LinearProgress } from '@mui/material';
import { Textarea } from '@mui/joy';
import { SessionContext } from 'Contexts';

export default function AwaitingCoachMatchReview() {
    const sessionState = useContext(SessionContext);
    const [regConfig, setRegConfig] = useState(null);

    useEffect(() => {
        fetch("/api/v1/account/registration-configuration/" + sessionState.participant.clientParent).then((res) => {
            return res.json();
        }).then((res) => {
            setRegConfig(res);
        });
    }, []);
    
    if(regConfig == null || regConfig.participantSelectsCoach == null || regConfig.participantSelectsCoach == true) {
        return (
            <div className="PageBody">
                <Stack direction="column" fullwidth justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                    <Typography variant="h3" component="h1">Coach Match Review</Typography>
                    <Typography>EST2AR staff are currently reviewing your coach matches for accuracy.</Typography>
                    <Typography>You will be notified via email within 1-2 business days when your matches are available.</Typography>
                    <LinearProgress />
                </Stack>
            </div>
        );
    } else {
        return (
            <div className="PageBody">
                <Stack direction="column" fullwidth justifyContent="center" alignItems="center" useFlexGap flexWrap="wrap" spacing={1}>
                    <Typography variant="h3" component="h1">Coach Match Review</Typography>
                    <Typography>EST2AR staff are currently reviewing your coach matches for accuracy.</Typography>
                    <Typography>You will be notified via email within 1-2 business days when you are assigned your coach.</Typography>
                    <LinearProgress />
                </Stack>
            </div>
        );
    }
}